import React, {Component} from 'react';
import PropTypes from "prop-types";
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col, Form, Input, Label,
  Row
} from 'reactstrap';
import {map} from "lodash";
import {FormattedMessage} from "react-intl";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import 'react-datepicker/dist/react-datepicker.css';
import FormGroup from "reactstrap/es/FormGroup";
import InputGroup from "reactstrap/es/InputGroup";
import InputGroupAddon from "reactstrap/es/InputGroupAddon";
import InputGroupText from "reactstrap/es/InputGroupText";
import InputNumber from "components/InputNumber";
import Switch from "components/Switch";
import Select from "react-select";
import {connect} from "react-redux";
import SurveysSelectModal from "components/Survey/SurveysSelectModal";
import ExcludedUsersSelectModal from "components/User/ExcludedUsersSelectModal";
import CitiesSelectModal from "components/CitiesSelectModal";
import DatePicker from "react-datepicker";
import InputMask from "react-input-mask";
import CityDistrictsSelect from "../../../../../components/CityDistrictsSelect";
import HelpModeIconTooltip from "../../../../../components/HelpModeIconTooltip";


export const cityProvinces = [
  'DOLNOŚLĄSKIE',
  'KUJAWSKO-POMORSKIE',
  'LUBELSKIE',
  'LUBUSKIE',
  'MAZOWIECKIE',
  'MAŁOPOLSKIE',
  'OPOLSKIE',
  'PODKARPACKIE',
  'PODLASKIE',
  'POMORSKIE',
  'ŚLĄSKIE',
  'ŚWIĘTOKRZYSKIE',
  'WARMIŃSKO-MAZURSKIE',
  'WIELKOPOLSKIE',
  'ZACHODNIOPOMORSKIE',
  'ŁÓDZKIE',
];

function LastActivityInput(props) {
  const [value, setValue] = React.useState(props.value);

  return <Input
    placeholder="yyyy-mm"
    value={value}
    onChange={e => {
      setValue(e.target.value)
    }}
    onBlur={() => {
      const datePattern = /(\d{4})-(\d{2})/;
      if(value.match(datePattern)){
        props.onChange(value)
      }else{
        props.onChange(null);
        setValue('');
      }

    }}
  />
}

class CollectorPanelForm extends Component {

  constructor(props) {
    super(props);

    this.selectExcludedUsers = React.createRef();
    this.selectCityProvinces = React.createRef();
    this.selectNotEndedSurveys = React.createRef();
    this.selectEndedSurveys = React.createRef();
    this.selectCities = React.createRef();

    this.state = {
      advanced: false,
      typingKey: false,
      typingValue: '',
      data: props.data,
    };

    this.inputIdsUpdate = this.inputIdsUpdate.bind(this);
    this.onChangeProperty = this.onChangeProperty.bind(this);
  }

  onChangeProperty(property, value){
    this.setState({
      data: {
        ...this.state.data,
        [property]: value,
      }
    }, () => {
      this.props.handleUpdate({[property]: this.state.data[property]})
    });
  }

  inputIdsUpdate(property, e){
    const value = e.target.value;

    let ids = value.split(',').map(v => parseInt(v.replace(/\s/g, ''), 10)).filter(v => /^\d+$/.test(v));

    this.setState({
      typingKey: false,
      typingValue: '',
      data: {
        ...this.state.data,
        [property]: ids,
      }
    }, () => {
      this.props.handleUpdate({[property]: this.state.data[property]})
    });
  }

  postalUpdate(property, e){
    const value = e.target.value;

    let ids = value.split(',');
    ids = ids.map((id) => {
      return id.replace(/[\n\r\t]/gm, "");
    })

    this.setState({
      typingKey: false,
      typingValue: '',
      data: {
        ...this.state.data,
        [property]: ids,
      }
    }, () => {
      this.props.handleUpdate({[property]: this.state.data[property]})
    });
  }

  render() {
    const {data, typingKey, typingValue, advanced} = this.state;
    const {errors, probablyRange, profileQuestions, survey, bulkEdit} = this.props;

    return (
      <React.Fragment>
        <Row className="mt-3">
          <Col>
            <h1 className="text-center">Przybliżony zasięg: {probablyRange === false ? <i className="fas fa-spin fa-spinner" /> : probablyRange }</h1>
          </Col>
        </Row>
        <Form>
          <Row>
            <Col md={6}>
              <FormGroup row className="mb-2">
                <Label md={4} className="text-right">Maksymalna wielkość bazy respondentów</Label>
                <Col md={8} className="d-flex">
                  <InputNumber
                    onChange={(value) => this.onChangeProperty('respondentsMax', parseInt(value) === 0 ? 0 : parseInt(value))}
                    step={1000}
                    min={0}
                    max={500000}
                    value={data.respondentsMax || 0}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={4} className="text-right"><FormattedMessage tagName="h2" id="collectorPanel.label.age" /></Label>
                <Col md={8} className="d-flex">
                  <InputGroup className="mr-3">
                    <InputGroupAddon addonType={"prepend"}><InputGroupText>Od</InputGroupText></InputGroupAddon>
                    <InputNumber onChange={(value) => this.onChangeProperty('minAge', parseInt(value))} min={0} max={99} value={data.minAge} />
                  </InputGroup>
                  <InputGroup>
                    <InputGroupAddon addonType={"prepend"}><InputGroupText>Do</InputGroupText></InputGroupAddon>
                    <InputNumber onChange={(value) => this.onChangeProperty('maxAge', parseInt(value))} min={0} max={99} value={data.maxAge} />
                  </InputGroup>
                </Col>
              </FormGroup>
              <FormGroup row className="mb-2">
                <Label md={4} className="d-flex justify-content-end align-items-center"><FormattedMessage tagName="h2" id="collectorPanel.label.sex" /></Label>
                <Col md={8} className="d-flex justify-content-center">
                  <Button
                    className="mr-2"
                    color={data.sexM ? 'primary' : 'secondary'}
                    onClick={() => this.setState({data: {...data, sexM: !data.sexM}}, () => this.props.handleUpdate({sexM: this.state.data.sexM, sexF: !!this.state.data.sexF}))}
                  ><i className="fas fa-male fa-3x" /></Button>
                  <Button
                    color={data.sexF ? 'primary' : 'secondary'}
                    onClick={() => this.setState({data: {...data, sexF: !data.sexF}}, () => this.props.handleUpdate({sexF: this.state.data.sexF, sexM: !!this.state.data.sexM}))}
                  ><i className="fas fa-female fa-3x" /></Button>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={4} className="text-right"><h2>Ostatnia aktywność</h2></Label>
                <Col md={8} className="d-flex flex-row flex-nowrap">
                  <InputGroup size="sm" className="mr-2">
                    <InputGroupAddon addonType={'prepend'}><InputGroupText>Od</InputGroupText></InputGroupAddon>
                    <LastActivityInput value={data.lastActivityFrom} onChange={lastActivityFrom => this.props.handleUpdate({lastActivityFrom})} />
                  </InputGroup>

                  <InputGroup size="sm">
                    <InputGroupAddon addonType={'prepend'}><InputGroupText>Do</InputGroupText></InputGroupAddon>
                    <LastActivityInput value={data.lastActivityTo} onChange={lastActivityTo => this.props.handleUpdate({lastActivityTo})} />
                  </InputGroup>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={4} className="text-right"><FormattedMessage tagName="h2" id="collectorPanel.label.excludedUsers">{msg => <h2>{msg} {data.excludedUsers.length > 0 && <span>({data.excludedUsers.length})</span>}</h2>}</FormattedMessage></Label>
                <Col md={8} className="d-flex">
                  <InputGroup>
                    <Input
                      invalid={!!errors.excludedUsers}
                      onBlur={e => this.inputIdsUpdate('excludedUsers', e)}
                      onChange={e => this.setState({typingValue: e.target.value})}
                      onFocus={() => this.setState({
                        typingKey: 'excludedUsers',
                        typingValue: data.excludedUsers.join(', '),
                      })}
                      value={typingKey === 'excludedUsers' ? typingValue : data.excludedUsers.join(', ')}
                    />
                    <InputGroupAddon
                      className="pointer"
                      addonType="append"
                      onClick={() => this.selectExcludedUsers.current.toggleModal()}
                    ><InputGroupText><i className="fas fa-search" /></InputGroupText></InputGroupAddon>
                  </InputGroup>
                  <ExcludedUsersSelectModal
                    userInGroup={this.props.userInGroup}
                    ref={this.selectExcludedUsers}
                    onAccept={values => this.onChangeProperty('excludedUsers', values)}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={4} className="text-right"><FormattedMessage tagName="h2" id="collectorPanel.label.onlyUsers" /></Label>
                <Col md={8} className="d-flex">
                  <Input
                    invalid={!!errors.onlyUsers}
                    onBlur={e => this.inputIdsUpdate('onlyUsers', e)}
                    onChange={e => this.setState({typingValue: e.target.value})}
                    onFocus={() => this.setState({
                      typingKey: 'onlyUsers',
                      typingValue: data.onlyUsers.join(', '),
                    })}
                    value={typingKey === 'onlyUsers' ? typingValue : data.onlyUsers.join(', ')}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={4} className="text-right"><FormattedMessage tagName="h2" id="collectorPanel.label.endedSurveys" /></Label>
                <Col md={8} className="d-flex">
                  <InputGroup>
                    <Input
                      invalid={!!errors.endedSurveys}
                      onBlur={e => this.inputIdsUpdate('endedSurveys', e)}
                      onChange={e => this.setState({typingValue: e.target.value})}
                      onFocus={() => this.setState({
                        typingKey: 'endedSurveys',
                        typingValue: data.endedSurveys.join(', '),
                      })}
                      value={typingKey === 'endedSurveys' ? typingValue : data.endedSurveys.join(', ')}
                    />
                    <InputGroupAddon
                      className="pointer"
                      addonType="append"
                      onClick={() => this.selectEndedSurveys.current.toggleModal()}
                    ><InputGroupText><i className="fas fa-search" /></InputGroupText></InputGroupAddon>
                  </InputGroup>
                  <SurveysSelectModal
                    ref={this.selectEndedSurveys}
                    values={data.endedSurveys}
                    onAccept={values => this.onChangeProperty('endedSurveys', values)}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={4} className="text-right"><FormattedMessage tagName="h2" id="collectorPanel.label.notEndedSurveys" /></Label>
                <Col md={8} className="d-flex">
                  <InputGroup>
                    <Input
                      invalid={!!errors.notEndedSurveys}
                      onBlur={e => this.inputIdsUpdate('notEndedSurveys', e)}
                      onChange={e => this.setState({typingValue: e.target.value})}
                      onFocus={() => this.setState({
                        typingKey: 'notEndedSurveys',
                        typingValue: data.notEndedSurveys.join(', '),
                      })}
                      value={typingKey === 'notEndedSurveys' ? typingValue : data.notEndedSurveys.join(', ')}
                    />
                    <InputGroupAddon
                      className="pointer"
                      addonType="append"
                      onClick={() => this.selectNotEndedSurveys.current.toggleModal()}
                    ><InputGroupText><i className="fas fa-search" /></InputGroupText></InputGroupAddon>
                  </InputGroup>
                  <SurveysSelectModal
                    ref={this.selectNotEndedSurveys}
                    values={data.notEndedSurveys}
                    onAccept={values => this.onChangeProperty('notEndedSurveys', values)}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={4} className="text-right"><FormattedMessage tagName="h2" id="collectorPanel.label.userIdRange" /></Label>
                <Col md={8} className="d-flex">
                  <InputGroup className="mr-3">
                    <InputGroupAddon addonType={"prepend"}><InputGroupText>Od</InputGroupText></InputGroupAddon>
                    <InputNumber onChange={(value) => this.onChangeProperty('userIdFrom', parseInt(value) === 0 ? null : parseInt(value))} step={100} min={0} value={data.userIdFrom || 0} />
                  </InputGroup>
                  <InputGroup>
                    <InputGroupAddon addonType={"prepend"}><InputGroupText>Do</InputGroupText></InputGroupAddon>
                    <InputNumber onChange={(value) => this.onChangeProperty('userIdTo', parseInt(value) === 0 ? null : parseInt(value))} step={100} min={0} value={data.userIdTo || 0} />
                  </InputGroup>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={4} className="text-right"><FormattedMessage tagName="h2" id="collectorPanel.label.cashPointsRange" /></Label>
                <Col md={8} className="d-flex">
                  <InputGroup className="mr-3">
                    <InputGroupAddon addonType={"prepend"}><InputGroupText>Od</InputGroupText></InputGroupAddon>
                    <InputNumber onChange={(value) => this.onChangeProperty('cashPointsFrom', parseInt(value) === 0 ? null : parseInt(value))} min={0} value={data.cashPointsFrom || 0} />
                  </InputGroup>
                  <InputGroup>
                    <InputGroupAddon addonType={"prepend"}><InputGroupText>Do</InputGroupText></InputGroupAddon>
                    <InputNumber onChange={(value) => this.onChangeProperty('cashPointsTo', parseInt(value) === 0 ? null : parseInt(value))} min={0} value={data.cashPointsTo || 0} />
                  </InputGroup>
                </Col>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup row>
                <Label md={4} className="text-right"><FormattedMessage tagName="h2" id="collectorPanel.label.cities" /></Label>
                <Col md={8}>
                  <InputGroup>
                    <Input
                      type="textarea"
                      invalid={!!errors.cities}
                      onBlur={e => this.inputIdsUpdate('cities', e)}
                      onChange={e => this.setState({typingValue: e.target.value})}
                      onFocus={() => this.setState({
                        typingKey: 'cities',
                        typingValue: data.cities.join(', '),
                      })}
                      value={typingKey === 'cities' ? typingValue : map(data.cities, c => c).join(', ')}
                      style={{border: 'none'}}
                    />
                    <InputGroupAddon
                      addonType="append"
                      className="pointer"
                      onClick={() => this.selectCities.current.toggleModal()}
                    ><InputGroupText><i className="fas fa-search" /></InputGroupText></InputGroupAddon>
                  </InputGroup>
                  <CitiesSelectModal
                    ref={this.selectCities}
                    values={map(data.cities, c => c)}
                    onAccept={values => this.onChangeProperty('cities', values)}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={4} className="text-right"><FormattedMessage tagName="h2" id="collectorPanel.label.postalCodes" /></Label>
                <Col md={8}>
                  <InputGroup>
                    <Input
                        type="textarea"
                        invalid={!!errors.postalCodes}
                        onBlur={e => this.postalUpdate('postalCodes', e)}
                        onChange={e => this.setState({typingValue: e.target.value})}
                        onFocus={() => this.setState({
                          typingKey: 'postalCodes',
                          typingValue: data.postalCodes.join(', '),
                        })}
                        value={typingKey === 'postalCodes' ? typingValue : map(data.postalCodes, c => c).join(', ')}
                        style={{border: 'none'}}
                    />
                  </InputGroup>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={4} className="text-right"><FormattedMessage tagName="h2" id="collectorPanel.label.excludedPostalCodes" /></Label>
                <Col md={8}>
                  <InputGroup>
                    <Input
                        type="textarea"
                        invalid={!!errors.excludedPostalCodes}
                        onBlur={e => this.postalUpdate('excludedPostalCodes', e)}
                        onChange={e => this.setState({typingValue: e.target.value})}
                        onFocus={() => this.setState({
                          typingKey: 'excludedPostalCodes',
                          typingValue: data.excludedPostalCodes.join(', '),
                        })}
                        value={typingKey === 'excludedPostalCodes' ? typingValue : map(data.excludedPostalCodes, c => c).join(', ')}
                        style={{border: 'none'}}
                    />
                  </InputGroup>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={4} className="text-right"><FormattedMessage tagName="h2" id="collectorPanel.label.cityVillage" /></Label>
                <Col md={8}>
                  <Switch onChange={value => this.onChangeProperty('cityVillage', value)} checked={!!data.cityVillage} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={4} className="text-right"><FormattedMessage tagName="h2" id="collectorPanel.label.cityTown" /></Label>
                <Col md={8}>
                  <Switch onChange={value => this.onChangeProperty('cityTown', value)} checked={!!data.cityTown} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={4} className="text-right"><FormattedMessage tagName="h2" id="collectorPanel.label.cityLmRange" /></Label>
                <Col md={8} className="d-flex">
                  <InputGroup className="mr-3">
                    <InputGroupAddon addonType={"prepend"}><InputGroupText>Od</InputGroupText></InputGroupAddon>
                    <InputNumber onChange={(value) => this.onChangeProperty('cityLmFrom', parseInt(value) === 0 ? null : parseInt(value))} step={1000} min={0} max={10000000} value={data.cityLmFrom || 0} />
                  </InputGroup>
                  <InputGroup>
                    <InputGroupAddon addonType={"prepend"}><InputGroupText>Do</InputGroupText></InputGroupAddon>
                    <InputNumber onChange={(value) => this.onChangeProperty('cityLmTo', parseInt(value) === 0 ? null : parseInt(value))} step={1000} min={0} max={10000000} value={data.cityLmTo || 0} />
                  </InputGroup>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={4} className="text-right"><FormattedMessage tagName="h2" id="collectorPanel.label.cityProvinces" /></Label>
                <Col md={8}>
                  <Select
                    ref={this.selectCityProvinces}
                    isMulti
                    isOpen={false}
                    placeholder="Wybierz województwa"
                    options={cityProvinces.map(province => {
                      return {
                        label: province,
                        value: province,
                      }
                    })}
                    onChange={provinces => {
                      this.onChangeProperty('cityProvinces', provinces.map(province => province.value));
                      this.selectCityProvinces.current.setState({isOpen: true, menuIsOpen: true});
                    }}
                    value={_.map(data.cityProvinces, province => {
                      return {
                        label: province,
                        value: province,
                      }
                    })}
                  />
                </Col>
              </FormGroup>
              <FormGroup row className="mt-2">
                <Label md={4} className="text-right"><FormattedMessage tagName="h2" id="collectorPanel.label.cityDistricts" /></Label>
                <Col md={8}>
                  <CityDistrictsSelect
                    onChange={districts => this.onChangeProperty('cityDistricts', districts.map(district => district.value))}
                    values={data.cityDistricts}
                  />
                </Col>
              </FormGroup>
              <div className="pt-4">
                <FormGroup row className="mt-5">
                  <Label lg={4} className="text-right"><h2 className="text-primary">CashPoints</h2></Label>
                  <Col lg={8}>
                    <Input type={'number'} size={'sm'} placeholder={bulkEdit ? 'Wpisz wartość CashPoints' : 'Wartość pobrana z ustawień ankiety'} onChange={(e) => this.onChangeProperty('endCashPoints', Math.min(parseInt(e.target.value), 20) || null)} value={data.endCashPoints ? data.endCashPoints : bulkEdit ? null : survey.endCashPoints} />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label lg={4} className="text-right"><h2 className="text-primary">CashPoints e-mail</h2></Label>
                  <Col lg={8} className="pt-1">
                    <Input type={'number'} size={'sm'} placeholder={bulkEdit ? 'Wpisz wartość CashPointsEmail' : 'Wartość pobrana z ustawień ankiety'} onChange={(e) => this.onChangeProperty('endCashPointsEmail', Math.min(parseInt(e.target.value), 20) || null)} value={data.endCashPointsEmail ? data.endCashPointsEmail : bulkEdit ? null : survey.endSocialPointsEmail} />
                  </Col>
                </FormGroup>
              </div>
            </Col>
          </Row>

          <Row>
            <h1 className="mx-auto pointer" onClick={() => this.setState({advanced: !advanced})}>Zaawansowane {advanced ? <i className="fas fa-caret-down" /> : <i className="fas fa-caret-up" />}</h1>
          </Row>
          <Row className={!advanced && 'd-none'}>
            {_.map(profileQuestions, (answers, key) => {
              const property = 'profile' + key.charAt(0).toUpperCase() + key.slice(1);

              return <Col md={6} key={key}>
                <FormGroup row>
                  <Label md={4} className="text-right"><FormattedMessage tagName="h2" id={'collectorPanel.label.respondentPanel.' + key} /></Label>
                  <Col md={8}>
                    <select
                      multiple
                      value={data[property]}
                      onChange={e => {
                        let options = e.target.options;
                        let value = [];
                        for (let i = 0, l = options.length; i < l; i++) {
                          if (options[i].selected) {
                            value.push(options[i].value);
                          }
                        }

                        this.onChangeProperty(property, value);
                      }}
                    >
                      {_.map(answers, (text, key) => <option value={key} key={key}>{text}</option>)}
                    </select>
                  </Col>
                </FormGroup>
              </Col>
            })}

          </Row>

          <Row className="mt-3">
            <Col>
              <Alert color="info">
                <div>Targety i zmiany w targetach są widoczne dla respondentów do 5 minut od zapisania i aktywowania kolektora.</div>
              </Alert>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
}

CollectorPanelForm.defaultProps = {
  data: {
    excludedUsers: [],
    onlyUsers: [],
    endedSurveys: [],
    notEndedSurveys: [],
    cityProvinces: [],
    cityDistricts: [],
    cities: [],
    profileQuestionDevices: [],
    profileQuestionEducation: [],
    profileQuestionProfessionalStatus: [],
    profileQuestionIsPeopleManager: [],
    profileQuestionIsDecisionMakerBuyingAtWork: [],
    profileQuestionHouseholdComposition: [],
    profileQuestionIsCarUser: [],
    profileQuestionIsPetKeeper: [],
    profileQuestionCurrentWork: [],
    profileQuestionHouseholdContainUnder18Years: [],
    profileQuestionIsDecisionMakerBuying: [],
    profileQuestionRespondentIncome: [],
    profileQuestionMainIndustry: [],
    profileQuestionHouseholdIncomePerPerson: [],
    profileQuestionIsOwnerRtvAgd: [],
    postalCodes: [],
    excludedPostalCodes: []
  }
};

CollectorPanelForm.propTypes = {
  probablyRange: PropTypes.oneOfType(PropTypes.number, PropTypes.bool),
  errors: PropTypes.object,
  bulkEdit: PropTypes.bool,
  data: PropTypes.shape({
    minAge: PropTypes.number,
    maxAge: PropTypes.number,
    sexM: PropTypes.bool,
    sexF: PropTypes.bool,
    excludedUsers: PropTypes.arrayOf(PropTypes.number),
    onlyUsers: PropTypes.arrayOf(PropTypes.number),
    endedSurveys: PropTypes.arrayOf(PropTypes.number),
    notEndedSurveys: PropTypes.arrayOf(PropTypes.number),
    userIdFrom: PropTypes.number,
    userIdTo: PropTypes.number,
    cashPointsFrom: PropTypes.number,
    cashPointsTo: PropTypes.number,
    cities: PropTypes.arrayOf(PropTypes.number),
    cityVillage: PropTypes.bool,
    cityTown: PropTypes.bool,
    cityProvinces: PropTypes.array,
    cityDistricts: PropTypes.array,
    cityLmFrom: PropTypes.number,
    cityLmTo: PropTypes.number,
    lastActivityFrom: PropTypes.string,
    profileQuestionDevices: PropTypes.arrayOf(PropTypes.string),
    profileQuestionEducation: PropTypes.arrayOf(PropTypes.string),
    profileQuestionProfessionalStatus: PropTypes.arrayOf(PropTypes.string),
    profileQuestionIsPeopleManager: PropTypes.arrayOf(PropTypes.string),
    profileQuestionIsDecisionMakerBuyingAtWork: PropTypes.arrayOf(PropTypes.string),
    profileQuestionHouseholdComposition: PropTypes.arrayOf(PropTypes.string),
    profileQuestionIsCarUser: PropTypes.arrayOf(PropTypes.string),
    profileQuestionIsPetKeeper: PropTypes.arrayOf(PropTypes.string),
    profileQuestionCurrentWork: PropTypes.arrayOf(PropTypes.string),
    profileQuestionHouseholdContainUnder18Years: PropTypes.arrayOf(PropTypes.string),
    profileQuestionIsDecisionMakerBuying: PropTypes.arrayOf(PropTypes.string),
    profileQuestionRespondentIncome: PropTypes.arrayOf(PropTypes.string),
    profileQuestionMainIndustry: PropTypes.arrayOf(PropTypes.string),
    profileQuestionHouseholdIncomePerPerson: PropTypes.arrayOf(PropTypes.string),
    profileQuestionIsOwnerRtvAgd: PropTypes.arrayOf(PropTypes.string),
  }),
  handleUpdate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    profileQuestions: state.config.data.userRespondentProfileQuestions,
    userInGroup: state.user.groups.length > 0,
    survey: state.survey.structure.data.survey,
  }
}

export default connect(mapStateToProps)(CollectorPanelForm);
