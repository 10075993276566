import * as React from 'react';
import PropTypes from "prop-types";
import Tooltip from "../../../../components/Tooltip";
import {Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import {FormattedMessage} from "react-intl";
import Button from "../../../../components/Button";
import surveyStructureToDocxConverter from "../../../../utils/converters/surveyStructureToDocxConverter";
import {connect} from "react-redux";
import SurveyScriptButton from "./SurveyScriptButton";
import SurveyScriptRenderer from "./SurveyScriptRenderer";

function PrintStructure(props) {
	const [modalOpen, setModalOpen] = React.useState(false);
	const [docLoading, setDocLoading] = React.useState(false);
	const [script, setScript] = React.useState("");

	const toggleModalOpen = () => {
		if (props.toggleModal) {
			props.toggleModal();
		} else {
			setModalOpen(!modalOpen);
		}
	}

	const getDocx = () => {
	  setDocLoading(true);
	  surveyStructureToDocxConverter(props.survey)
	  setDocLoading(false)
	}

	const print = () => {
	  toggleModalOpen();

	  setTimeout(() => {
		window.print();
	  }, 350)
	}

	return (
		<React.Fragment>
			{!props.toggleModal && <React.Fragment>
				<i id={'structure-print'} onClick={toggleModalOpen} className="fas fa-download pointer py-2 px-1"/>
				<Tooltip id={'structure-print'} msg={"Pobierz strukturę ankiety"}/>
			</React.Fragment>}
			{(modalOpen || props.isOpen) && <Modal isOpen={true} toggle={toggleModalOpen} size="lg">
				<ModalHeader tag="h2" toggle={toggleModalOpen}>Pobierz strukturę ankiety</ModalHeader>
				<hr className="my-0"/>
				<ModalBody>
					Po kliknięciu poniższego przycisku "Drukuj", na oknie drukowania w opcji "Urządzenie docelowe"
					wybierz opcję
					"Zapisz jako PDF" w celu pobrania struktury ankiety do PDF.

					<SurveyScriptRenderer survey={props.survey.structure.data.survey} script={script}/>
				</ModalBody>
				<ModalFooter>
					<FormattedMessage id="_.button.cancel">{
						(message) =>
							<Button color="secondary" className="mb-0" onClick={toggleModalOpen}>{message}</Button>
					}</FormattedMessage>
					<SurveyScriptButton onScriptLoaded={setScript}/>
					{props.survey && <Button
						color={"primary"}
						loading={docLoading}
						onClick={getDocx}
					>Pobierz jako doc</Button>}
					<Button
						color={"primary"}
						loading={false}
						onClick={print}
					>Drukuj</Button>
				</ModalFooter>
			</Modal>}
		</React.Fragment>
	);

}

PrintStructure.propTypes = {};

function mapStateToProps(state) {
	return {
		plan: state.user.userPlan.plan,
		structure: state.survey.structure
	}
}

export default connect(mapStateToProps, {})(PrintStructure);