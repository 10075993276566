import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from "reactstrap";
import classnames from "classnames";
import Select from "react-select";
import {map} from "lodash";

export const KEY = '$survey-fontFamily';

class SurveyFontFamily extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectMenuOpen: false,
      value: props.ownStyles[KEY] ? props.ownStyles[KEY] : false,
    };

    this.getDefaultValue = this.getDefaultValue.bind(this);
  }

  getDefaultValue(){
    return this.props.ownStylesDefault[KEY];
  }

  updateValue(value){
    this.setState({
      value
    });

    this.props.handlerUpdate({[KEY]: value});
  }

  render() {
    const {value, selectMenuOpen} = this.state;
    const inactive = [false, ''].indexOf(value) >= 0;



    const options = [
      "Lato",
      "Roboto-Regular",
      "Source-Sans-Pro",
      "Arial, sans-serif",
      "Helvetica, sans-serif",
      "Helvetica Narrow, sans-serif",
      "sans-serif",
      "Times, serif",
      "Times New Roman, serif",
      "serif",
      "Courier New, monospace",
      "Courier, monospace",
      "monospace",
      "cursive",
      "calibri light, verdana, tahoma",
      "Century Gothic,arial,sans-serif",
      "Poppins"
    ];

    return (
      <Row className={classnames({
        'py-1 px-3 align-items-center': true,
        'inactive': inactive && !selectMenuOpen,
      })}>
        <label className="col col-4">Czcionka {!inactive && <i className="icon-clear-value fas fa-trash float-right" onClick={() => this.updateValue('')} />}</label>
        <Col className="col-8">
          <Select
            onMenuOpen={() => this.setState({selectMenuOpen: true})}
            onMenuClose={() => this.setState({selectMenuOpen: false})}
            classNames="w-100"
            value={{
              label: value || this.getDefaultValue(),
              value: value || this.getDefaultValue(),
            }}
            onChange={(e) => this.updateValue(e.value)}

            options={options.map(value => {
              return {
                label: value,
                value: value
              }
            })}
          />
        </Col>
      </Row>
    );
  }
}

SurveyFontFamily.propTypes = {
  handlerUpdate: PropTypes.func.isRequired,
  ownStyles: PropTypes.shape({
    [KEY]: PropTypes.string,
  }),
  ownStylesDefault: PropTypes.shape({
    [KEY]: PropTypes.string,
  }),
};

export default SurveyFontFamily;
