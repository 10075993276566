import React from 'react';
import PropTypes from 'prop-types';
import Button from "../../../../../components/Button";
import FilterFromTextareaModal from "./FilterFromTextareaModal";
import {connect} from "react-redux";
import FiltersToTextConverter from "../../../../../utils/converters/filtersToTextConverter";

FiltersEditTextarea.propTypes = {
  filters: PropTypes.array.isRequired,
  btn: PropTypes.bool,
  onCancel: PropTypes.func,
};

FiltersEditTextarea.defaultProps = {
  btn: true,
};

function FiltersEditTextarea(props) {
  const [filtersText, setFiltersText] = React.useState('');
  const [modalOpen, setModalOpen] = React.useState(false);

  React.useEffect(() => {
    if(!props.btn){
      edit();
    }
  }, []);

  const edit = () => {
    const converter = new FiltersToTextConverter(props.structure, props.filters);
    setFiltersText(converter.convert());

    setModalOpen(true);
  }

  return (
    <React.Fragment>
      {props.btn && <Button className="ml-2" onClick={edit}><i className="fas fa-edit"/> Edytuj z pola tekstowego</Button>}
      {modalOpen && <FilterFromTextareaModal
        filtersText={filtersText}
        wizardChange={!props.btn}
        onClose={filters => {
          setModalOpen(false);
          props.onClose(filters);
        }}
      />}
    </React.Fragment>
  );
}


function mapStateToProps(state) {
  return {
    structure: state.survey.structure
  }
}

export default connect(mapStateToProps)(FiltersEditTextarea);