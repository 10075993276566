const elementsToStructureConverter = (elements) => {
	let structure = {
		survey: {
			setBlocks: []
		},
		answers: {},
		blocks: {},
		pages: {},
		questions: {},
		sets: {},
		rows: {},
		columns: {},
		excludings: {},
		baskets: {},
		scalePoints: {}
	};
	let setCount = 0;
	let blockCount = 0;
	let pageCount = 0;
	let questionCount = 0;
	let answersCount = 0;
	let rowCount = 0;
	let columnCount = 0;
	let excludingCount = 0;
	let basketCount = 0;
	let scalePointCount = 0;
	let setPosition = 0;
	let blockPosition = 0;
	let pagePosition = 0;
	let questionPosition = 0;
	let answerPosition = 0;
	let columnPosition = 0;
	let rowPosition = 0;
	let excludingPosition = 0;
	let basketPosition = 0;
	let scalePointPosition = 0;
	let currentSet = {};
	let currentBlock = {};
	let currentPage = {};
	let currentQuestion = {};
	let currentAnswer = {};
	let currentRow = {};
	let currentColumn = {};
	let currentExcludings = {};
	let currentBasket = {};
	let currentScalePoint = {};


	elements.forEach((entry) => {
		if (entry._type === 'set') {
			setCount++;
			currentSet = {
				blockPages: [],
				id: setCount,
				position: setPosition
			};
			structure.survey.setBlocks.push(setCount)
			structure.sets[setCount] = currentSet;
			setPosition++;
			blockPosition = 0;
		}

		if (entry._type === 'block') {
			blockCount++;
			currentBlock = {
				pages: [],
				id: blockCount,
				position: blockPosition
			};
			currentSet.blockPages.push(blockCount);
			structure.blocks[blockCount] = currentBlock;
			blockPosition++;
			pagePosition = 0;
		}

		if (entry._type === 'page') {
			pageCount++;
			currentPage = {
				questions: [],
				id: pageCount,
				position: pagePosition
			};
			currentBlock.pages.push(pageCount);
			structure.pages[pageCount] = currentPage;
			pagePosition++;
			questionPosition = 0;
		}

		if (entry._type === 'question') {
			questionCount++;
			currentQuestion = {
				answers: [],
				rows: [],
				columns: [],
				excludings: [],
				baskets: [],
				scalePoints: [],
				id: questionCount,
				position: questionPosition
			};
			currentPage.questions.push(questionCount);
			structure.questions[questionCount] = currentQuestion;
			if (entry && entry.answers) {
				entry.answers.forEach((answer) => {
					answersCount++;
					currentAnswer = {
						id: answersCount,
						position: answerPosition
					};
					currentQuestion.answers.push(answersCount);
					structure.answers[answersCount] = currentAnswer;
					answerPosition++;
				})
			}
			if (entry && entry.rows) {
				entry.rows.forEach((answer) => {
					rowCount++;
					currentRow = {
						id: rowCount,
						position: rowPosition
					};
					currentQuestion.rows.push(rowCount);
					structure.rows[rowCount] = currentRow;
					rowPosition++;
				})
			}
			if (entry && entry.columns) {
				entry.columns.forEach((answer) => {
					columnCount++;
					currentColumn = {
						id: columnCount,
						position: columnPosition
					};
					currentQuestion.columns.push(columnCount);
					structure.columns[columnCount] = currentColumn;
					columnPosition++;
				})
			}
			if (entry && entry.excludings) {
				entry.excludings.forEach((answer) => {
					excludingCount++;
					currentExcludings = {
						id: excludingCount,
						position: excludingPosition
					};
					currentQuestion.excludings.push(excludingCount);
					structure.excludings[excludingCount] = currentExcludings;
					excludingPosition++;
				})
			}

			if (entry && entry.baskets) {
				entry.baskets.forEach((answer) => {
					basketCount++;
					currentBasket = {
						id: basketCount,
						position: basketPosition
					};
					currentQuestion.baskets.push(basketCount);
					structure.baskets[basketCount] = currentBasket;
					basketPosition++;
				})
			}

			if (entry && entry.scalePoints) {
				entry.scalePoints.forEach((answer) => {
					scalePointCount++;
					currentScalePoint = {
						id: scalePointCount,
						position: scalePointPosition
					};
					currentQuestion.scalePoints.push(scalePointCount);
					structure.scalePoints[scalePointCount] = currentScalePoint;
					scalePointPosition++;
				})
			}
			answerPosition = 0;
			rowPosition = 0;
			columnPosition = 0;
			excludingPosition = 0;
			basketPosition = 0;
			scalePointPosition = 0;
			questionPosition++;
		}
	})

	return {
		data: structure
	};
}

export default elementsToStructureConverter;