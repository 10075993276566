import {structureToElementsConverter} from "./structureToElementsConverter";

export default class PipingToTextConverter{
	constructor(structure, pipings) {
		this.pipings = pipings;
		const elements = structureToElementsConverter(structure);
		this.sets = elements.sets;
		this.pages = elements.pages;
		this.blocks = elements.blocks;
		this.questions = elements.questions;
	}

	convert = () => {
		return this.pipings.map(filter => {
			try {
				switch (filter.type) {
					case 'question': {
						return this.parsePipingQuestion(filter);
					}
					case 'token': {
						return this.parsePipingToken(filter);
					}
				}
			}catch(e){
				console.log('error', e);
				return false;
			}
		}).filter(f => f).join("\n");
	}

	parsePipingQuestion = (piping) => {
		let pipingText = "";
		const questionFrom = piping.questionFrom;
		if (!questionFrom) {
			return "";
		}

		pipingText += `[${piping.id}]{Q}${piping.replacableText};`;
		pipingText += `Q${this.getQuestionPositionInSurvey(questionFrom.question)}:${questionFrom.type}=`;
		pipingText += questionFrom.fetchType;

		const questionFromEntry = this.questions.find((entry) => entry.id === questionFrom.question);

		if (questionFrom.answers && questionFrom.answers.length) {
			pipingText += ":";
			questionFrom.answers.forEach((entry, idx) => {
				pipingText += this.getQuestionCafeteryPosition(questionFromEntry, 'answers', entry)
				if (idx !== questionFrom.answers.length - 1) {
					pipingText += ',';
				}
			});
		}

		if (questionFrom.places && questionFrom.places.length > 0) {
			pipingText += ":places=";
			pipingText += questionFrom.places.toString();
		}

		return pipingText;
	}

	getQuestionCafeteryPosition = (question, cafeteryType, cId) => {
		let p = question[cafeteryType].indexOf(cId)

		return p >= 0 ? p+1 : false;
	}

	getQuestionPositionInSurvey = (qId) => {
		const q = this.questions.find(q => q.id == qId);
		if(q){
			return q.positionInSurvey;
		}

		return false;
	}

	parsePipingToken = (piping) => {
		let pipingText = "";
		pipingText += `[${piping.id}]{T}${piping.replacableText}=${piping.tokenField}`;

		return pipingText;
	}
}