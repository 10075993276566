import * as React from 'react';
import {Button, Col, Input, Row, Tooltip} from "reactstrap";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import InvitationScreen from "./invitationScreen";

function InvitationScreensList(props) {
	const {survey, plan} = props;
	const [invitationScreens, setInvitationScreens] = React.useState(survey.structure.data.survey.invitationScreens);
	const allowToCreate = plan.survey.invitationScreenNumber > survey.structure.data.survey.invitationScreens.length;

	const addNew = () => {
		const newScreen = {
			isDefault: false,
			name: "Nazwa ekranu zaproszenia",
			content: "Domyślna treść zaproszenia",
			id: null
		};

		setInvitationScreens([...invitationScreens, newScreen])
	}

	return <React.Fragment>
		{invitationScreens.map((screen) => {
			return <InvitationScreen invitationScreen={screen} />
		})}
		{(allowToCreate) && <Row className="mt-3">
			<Col>
				<FormattedMessage id="_.button.save">{
					(message) => <LaddaButton
						className="btn btn-primary btn-ladda mr-2"
						data-style={ZOOM_OUT}
						onClick={() => addNew()}
					>Stwórz nowy ekran zaproszenia</LaddaButton>
				}</FormattedMessage>
			</Col>
		</Row>}
	</React.Fragment>
}

InvitationScreensList.propTypes = {

};

function mapStateToProps(state) {
	return {
		survey: state.survey,
		plan: state.user.userPlan.plan
	}
}

export default connect(mapStateToProps)(InvitationScreensList);