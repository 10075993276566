import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Select from "react-select";
import {FormattedMessage} from "react-intl";

class StatusesSelect extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(options){
    let value;
    if(this.props.isMulti){
      value = options ? options.map(v => v.value) : null;
    }else{
      value = options ? options.value : null;
    }

    this.setState({
      value
    });

    this.props.onChange(value);
  }

  render() {
    const {className} = this.props;
    const {value} = this.state;

    let options = _.map(this.props.statuses, status => {
      return {
        label: <FormattedMessage id={"_.surveyResponse.status." + status + ".label"}/>,
        value: status
      };
    });

    return (
      <Select
        className={className}
        isMulti={this.props.isMulti}
        isClearable={this.props.isClearable}
        isLoading={this.props.isLoading}
        isDisabled={this.props.isDisabled}
        placeholder={this.props.placeholder}
        value={value ? (this.props.isMulti ? value.map(status => {
          return {
            label: <FormattedMessage id={"_.surveyResponse.status." + status + ".label"}/>,
            value: Number(status),
          }
        }) : {
          label: <FormattedMessage id={"_.surveyResponse.status." + value + ".label"}/>,
          value: Number(value),
        }) : false}
        options={options}
        onChange={this.onChange}
      />
    );
  }
}

StatusesSelect.defaultProps = {
  isMulti: true,
  isClearable: false,
  isLoading: false,
  isDisabled: false,
  className: '',
  placeholder: 'Wybierz statusy',
  statuses: [1, 2, 3, 4, 5],
};

StatusesSelect.propTypes = {
  value: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func.isRequired,
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  statuses: PropTypes.array,
};

export default StatusesSelect;