import * as React from 'react';
import Button from "../../../../components/Button";
import {connect} from "react-redux";

function SurveyScriptRenderer(props) {

	const getScriptTxt = () => {
		const element = document.createElement("a");
		const file = new Blob([props.script], {type: 'text/plain'});
		element.href = URL.createObjectURL(file);
		element.download = props.survey ? props.survey.name + ".txt" : "script.txt";
		document.body.appendChild(element); // Required for this to work in FireFox
		element.click();
	}

	return (
		<React.Fragment>
			{props.script && <React.Fragment>
				<h1 className={"mt-4 mb-2"}>SKRYPT</h1>
				<Button className={"mb-4"}
					color={"primary"}
					onClick={getScriptTxt}
				>Pobierz jako txt</Button>
				<p style={{whiteSpace: "break-spaces"}}>{props.script}</p>
			</React.Fragment>}
		</React.Fragment>
	);
}

SurveyScriptRenderer.propTypes = {};

function mapStateToProps(state) {
}

export default connect(mapStateToProps, {})(SurveyScriptRenderer);