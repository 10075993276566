import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Button, Card, CardHeader, CardBody, CardFooter, ButtonGroup, Dropdown, Tooltip} from "reactstrap";
import CondQuestion from "./CondQuestion";
import DropdownMenu from "reactstrap/es/DropdownMenu";
import DropdownToggle from "reactstrap/es/DropdownToggle";
import DropdownItem from "reactstrap/es/DropdownItem";
import ListGroup from "reactstrap/es/ListGroup";
import {injectIntl, FormattedMessage} from "react-intl";
import classnames from "classnames";
import BlockBadge from "./BlockBadge";
import uniqid from "uniqid";
import {connect} from "react-redux";


class Block extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      tooltipId: uniqid(),
      isOpenBlockDropdown: false,
      isOpenAddCondQuestion: false,
      isOpenTooltipRequired: false,
    };

    this.handleCondQuestionChanged = this.handleCondQuestionChanged.bind(this);
    this.addChild = this.addChild.bind(this);
    this.toggleIsOpenAddCondQuestion = this.toggleIsOpenAddCondQuestion.bind(this);
    this.toggleIsOpenBlockDropdown = this.toggleIsOpenBlockDropdown.bind(this);
    this.toggleIsOpenTooltipRequired = this.toggleIsOpenTooltipRequired.bind(this);
  }

  componentWillMount() {
    const {block} = this.props;



    if(_.isUndefined(block)){
      this.props.handleBlockChanged({
        children: [],
        condsQuestion: [],
        minCondsToMet: 1,
        maxCondsToMet: 0,
        type: 'OR',
      });
    }else{
      let blockChanged = block;

      if(_.isUndefined(block.children)){ blockChanged.children = [] }
      if(_.isUndefined(block.condsQuestion)){ blockChanged.condsQuestion = [] }
      if(_.isUndefined(block.minCondsToMet)){ blockChanged.minCondsToMet = 1 }
      if(_.isUndefined(block.maxCondsToMet)){ blockChanged.maxCondsToMet = 0 }
      if(_.isUndefined(block.type)){ blockChanged.type = 'OR' }

      if(blockChanged !== block){
        this.props.handleBlockChanged(blockChanged)
      }
    }
  }

  toggleIsOpenAddCondQuestion(){
    this.setState({
      isOpenAddCondQuestion: !this.state.isOpenAddCondQuestion,
    });
  }

  toggleIsOpenBlockDropdown(){
    this.setState({
      isOpenBlockDropdown: !this.state.isOpenBlockDropdown,
    });
  }

  toggleIsOpenTooltipRequired(){
    this.setState({
      isOpenTooltipRequired: !this.state.isOpenTooltipRequired,
    });
  }

  deleteChild(key){
    let {block} = this.props;

    block.children = block.children.filter((child, k) => k !== key);

    this.props.handleBlockChanged(block);
  }

  deleteCondQuestion(key){
    let {block} = this.props;

    block.condsQuestion = block.condsQuestion.filter((cond, k) => k !== key);

    this.props.handleBlockChanged(block);
  }

  addChild(){
    let {block} = this.props;

    block.children.push({});

    this.props.handleBlockChanged(block);
  }

  addCondQuestion(Obj){
    let {block} = this.props;

    block.condsQuestion.push(Obj);

    this.props.handleBlockChanged(block);
  }

  handleOnChildUpdated(key, child){
    let {block} = this.props;

    block.children[key] = child;

    this.props.handleBlockChanged(block);
  }

  handleCondQuestionChanged(key, condQuestion){
    let {block} = this.props;

    block.condsQuestion[key] = condQuestion;

    this.props.handleBlockChanged(block);
  }

  render() {
    const {block, headerVisible, requiredVisible, deleteable, changeable} = this.props;
    const {isOpenAddCondQuestion, isOpenBlockDropdown, isOpenTooltipRequired, tooltipId} = this.state;

    if(_.isUndefined(block)){
      return null;
    }

    if (!changeable) {
      return <React.Fragment>
        <div>
          {block.condsQuestion.length > 1 && <React.Fragment>
            <span className="mr-2">Blok warunków</span>
            <small>
              <span className="pr-2"><FormattedMessage id={`filter.block.type-${block.type}`} /></span>
              {block.type === 'OR' && <React.Fragment>
                {(block.minCondsToMet > 1 || block.maxCondsToMet > 0) && <span className="pr-2">Min: {block.minCondsToMet}</span>}
                {block.maxCondsToMet > 0 && <span className="pr-2">Max: {block.maxCondsToMet}</span>}
              </React.Fragment>}
            </small>
          </React.Fragment>}
        </div>
        <ul className="pl-3">
          {_.map(block.condsQuestion, condition => <li key={condition.id} style={{listStyleType: 'lower-latin'}}><CondQuestion condQuestion={condition} changeable={false}/></li>)}
        </ul>
        <ol className="pl-3">
          {_.map(block.children, childBlock => <li key={childBlock.id} style={{listStyleType: 'decimal'}}><Block block={childBlock} changeable={false}/></li>)}
        </ol>
      </React.Fragment>
    }

    return (
      <Card className="border-l blue">
        <CardHeader className={classnames({
          'd-none': headerVisible,
          'd-flex align-items-center': true,
        })}>
          <h2 className="mr-auto">Blok warunków</h2>

          {(block.children.length + block.condsQuestion.length) > 1 && <BlockBadge block={block} index={tooltipId} handleBlockChanged={block => this.props.handleBlockChanged(block)} />}

          {requiredVisible && <React.Fragment>
            <Button
              id={tooltipId}
              className="mb-0 mt-0 mr-2 d-none d-md-block"
              color={block.required ? 'warning' : 'secondary'}
              size="xs"
              onClick={() => this.props.handleBlockChanged({...block, required: !block.required})}
            ><i className="fas fa-exclamation" /></Button>
            <Tooltip
              delay={0}
              placement="bottom"
              isOpen={isOpenTooltipRequired}
              target={tooltipId}
              toggle={this.toggleIsOpenTooltipRequired}
            >
              Oznacz jako obowiązkowy
            </Tooltip>
          </React.Fragment>}

          {(requiredVisible || deleteable) && <Dropdown className="mt-0 mr-2" isOpen={isOpenBlockDropdown} toggle={this.toggleIsOpenBlockDropdown}>
            <DropdownToggle color="" className="m-0 p-0"><i className="fas fa-ellipsis-v" /></DropdownToggle>
            <DropdownMenu>
              {requiredVisible && <DropdownItem tag="a" onClick={() => this.props.handleBlockChanged({...block, required: !block.required})}><p><span className="fas fa-exclamation" /></p> <h5>Oznacz jako obowiązkowy</h5></DropdownItem>}
              {deleteable && <DropdownItem tag="a" onClick={() => this.props.handleBlockDelete()}><p><span className="fas fa-trash" /></p> <h5>Usuń blok warunków</h5></DropdownItem>}
            </DropdownMenu>
          </Dropdown>}
        </CardHeader>
        <hr className="my-0" />
        <CardBody className={classnames({
          'p-0': headerVisible
        })}>
          {block.children.map((child, key) =>
            <React.Fragment key={'block-' + key}>
              <Block
                tokenFilter={this.props.tokenFilter}
                collectorPanelAccess={this.props.collectorPanelAccess}
                headerVisible={(block.children.length + block.condsQuestion.length) <= 1}
                requiredVisible={block.type === 'OR' && (block.condsQuestion.length + block.children.length) > 1}
                block={!_.isEmpty(child) ? child : undefined}
                handleBlockChanged={child => this.handleOnChildUpdated(key, child)}
                handleBlockDelete={() => this.deleteChild(key)}
              />
              {((key+1) < block.children.length || block.condsQuestion.length > 0) && <p className="text-center p-3 mb-0" style={{fontSize: '1.5em'}}><FormattedMessage id={'filter.block.type-' + block.type} /></p>}
            </React.Fragment>
          )}

          <div className="d-flex flex-column p-2">
            {block.condsQuestion.map((condQuestion, key) => <React.Fragment key={'cond-' + key}>
                <CondQuestion
                  requiredVisible={block.type === 'OR' && (block.condsQuestion.length + block.children.length) > 1}
                  condQuestion={!_.isEmpty(condQuestion) ? condQuestion : undefined}
                  handleCondQuestionChanged={condQuestion => this.handleCondQuestionChanged(key, condQuestion)}
                  handleCondQuestionDelete={() => this.deleteCondQuestion(key)}
                />
                {(key+1) < block.condsQuestion.length && <p className="text-center p-3 mb-0" style={{fontSize: '1.5em'}}><FormattedMessage id={'filter.block.type-' + block.type} /></p>}
              </React.Fragment>
            )}
          </div>
        </CardBody>
        <CardFooter>
          <ButtonGroup className="mr-1 mb-2 mb-sm-0">
            <Button className="mb-0" onClick={() => this.addCondQuestion({
              check: 'detail'
            })}>Dodaj warunek</Button>
            <Dropdown isOpen={isOpenAddCondQuestion} toggle={this.toggleIsOpenAddCondQuestion}>
              <DropdownToggle className="mb-0" caret />
              <DropdownMenu>
                <DropdownItem
                  tag="a"
                  onClick={() => this.addCondQuestion({
                    check: 'detail',
                  })}
                ><h5>Dodaj warunek</h5></DropdownItem>
                <DropdownItem
                  tag="a"
                  onClick={() => this.addCondQuestion({
                    check: 'visibility',
                    visibility: true,
                  })}
                ><h5>Widoczność pytania</h5></DropdownItem>
                <DropdownItem
                  tag="a"
                  onClick={() => this.addCondQuestion({
                    check: 'excluding',
                    excluding: {
                      excludingType: 'selected',
                      excludings: []
                    }
                  })}
                ><h5>Zaznaczenie odpowiedzi wykluczającej</h5></DropdownItem>
                {this.props.collectorPanelAccess && <DropdownItem
                  tag="a"
                  onClick={() => this.addCondQuestion({
                    type: 'userProfile',
                    sexM: false,
                    sexF: false,
                    ageFrom: null,
                    ageTo: null,
                    cityTown: false,
                    cityVillage: false,
                    cityLmFrom: null,
                    cityLmTo: null,
                    cityProvinces: [],
                  })}
                ><h5>Profil respondenta</h5></DropdownItem>}
                {this.props.tokenFilter && <DropdownItem
                    tag="a"
                    onClick={() => this.addCondQuestion({
                      type: 'token'
                    })}
                ><h5>Token respondenta</h5></DropdownItem>}
                {this.props.tokenFilter && <DropdownItem
                    tag="a"
                    onClick={() => this.addCondQuestion({
                      type: 'collector',
                      collector: null
                    })}
                ><h5>Obecność kolektora</h5></DropdownItem>}
              </DropdownMenu>
            </Dropdown>
          </ButtonGroup>

          <Button className="mb-0" onClick={() => this.addChild()}>Dodaj blok warunków</Button>
        </CardFooter>
      </Card>
    );
  }
}

Block.defaultProps = {
  deleteable: true,
  changeable: true,
};

Block.propTypes = {
  block: PropTypes.shape({
    children: PropTypes.array,
    condsQuestion: PropTypes.array,
    minCondsToMet: PropTypes.number,
    maxCondsToMet: PropTypes.number,
    type: PropTypes.oneOf(['OR', 'AND']),
    required: PropTypes.bool,
  }),
  headerVisible: PropTypes.bool,
  requiredVisible: PropTypes.bool,
  deleteable: PropTypes.bool.isRequired,
  handleBlockChanged: PropTypes.func,
  handleBlockDelete: PropTypes.func,
  changeable: PropTypes.bool.isRequired,
  collectorPanelAccess: PropTypes.bool.isRequired,
  tokenFilter: PropTypes.bool
};

export default injectIntl(Block);
