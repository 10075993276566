import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import PropTypes from 'prop-types';
import api from 'api';
import {toast} from "react-toastify";
import appRoutes from 'appRoutes';
import CollectorPanelForm from "../../../../SurveyCollector/components/CollectorPanel/Tabs/CollectorPanelForm";
import StartEndDates from "../../../../SurveyCollector/components/FormDefaultProperty/StartEndDates";
import CollectorResponses from "../../../../SurveyCollector/components/FormDefaultProperty/CollectorResponses";
import {Col, Label, Row} from "reactstrap";
import Paused from "../../../../SurveyCollector/components/FormDefaultProperty/Paused";
import FormGroup from "reactstrap/es/FormGroup";
import Switch from "components/Switch";
import LoadingSpinner from "../../../../../components/LoadingSpinner";
import CollectorPanel from "../../../../SurveyCollector/components/CollectorPanel/CollectorPanel";
import ModalSimple from "../../../../../componentsReusable/ModalSimple";
import ChooseInvitationScreen from "../../../../SurveyCollector/components/chooseInvitationScreen";

class BulkEditForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      onlyPanel: false,
      saving: false,
      modalOpen: false,
      ids: props.collectorsIds,
      data: {
        ids: props.collectorsIds
      },
      errors: {},
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    api.survey.get.collectors({
      id: this.props.surveyId
    }).then(data => {
      let onlyPanel = true;
      this.props.collectorsIds.forEach(cId => {
        let c = data.find(col => col.id === parseInt(cId));
        if(c.type !== 'Panel'){
          onlyPanel = false;
        }
      })

      this.setState({
        loading: false,
        onlyPanel,
      })
    })
  }

  onSubmit(data, redirect) {
    this.setState({saving: true});

    api.surveyCollector.bulk(this.state.ids, data)
      .then(() => {
        this.setState({saving: false});
        toast.success(<FormattedMessage id="collectors.bulkEditForm.toast.success" />);
        if (redirect) {
          this.props.history.push(appRoutes.survey.collectors.list(this.props.surveyId));
        }
      })
      .catch(e => {
        this.setState({
          saving: false,
          errors: _.has(e, 'response.data.form.errors') ? e.response.data.form.errors : {},
        })
      })
      .finally(() => {
        clearTimeout(timeoutToast)
      });
  }

  render() {
    const {data, errors, loading, onlyPanel, modalOpen} = this.state;

    if(loading){
      return <LoadingSpinner />
    }

    let props = {
      errors: {},
      handleUpdate: d => {
        let dataMerged = _.merge(data, d);

        this.setState({
          data: dataMerged,
        })
      },
    };

    if(!_.isEmpty(this.state.data)){
      props.data = this.state.data;
    }

    if (onlyPanel) {
      data.type = 'Panel';
    }

    return (
      <React.Fragment>
        <Row>
          <StartEndDates
            lg={4}
            data={_.pick(data, ['startAt', 'endAt'])}
            onChange={(property, value) => this.setState({data: {...data, [property]: value}})}
            errors={errors}
          />
          <CollectorResponses
            lg={4}
            data={_.pick(data, ['collectorResponses'])}
            onChange={(property, value) => this.setState({data: {...data, [property]: value}})}
            errors={errors}
          />
          <Paused
            lg={4}
            data={_.pick(data, ['paused'])}
            onChange={(property, value) => this.setState({data: {...data, [property]: value}})}
            errors={errors}
          />
        </Row>
        
        <Row>
          <Col>
            <FormGroup row>
              <Label md={4}><FormattedMessage tagName="h2" id="collectorPanel.label.countScreenouts" /></Label>
              <Col md={8}>
                <Switch onChange={value => this.setState({data: {...this.state.data, countScreenouts: value}})} checked={data.countScreenouts} />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <ChooseInvitationScreen collector={data.invitationScreen ? data : {invitationScreen: null}} onChange={(property, value) => this.setState({data: {...this.state.data, invitationScreen: value}})} />

        <div className="direction-group">
          <div className="direction-right d-md-flex justify-content-md-end">
            <FormattedMessage id="_.button.save">{msg => <LaddaButton
                className="btn btn-primary btn-ladda mt-3 mb-0 float-right"
                loading={this.state.saving}
                onClick={() => {this.setState({modalOpen: true})}}
                data-style={ZOOM_OUT}
            >
              {msg}
            </LaddaButton>}</FormattedMessage>
          </div>
        </div>

        {onlyPanel && <CollectorPanel {...props}
            collector={data}
            match={{
              params: {
                survey: this.props.surveyId,
                collector: this.props.collectorsIds.join(',')
              }
            }}
        />}

        <ModalSimple onAccept={() => this.onSubmit(data, onlyPanel === false)}
            onCancel={() => {this.setState({modalOpen: false})}}
            modalOpen={modalOpen} headerText={"Masowa edycja"}
            bodyText={"Czy na pewno chcesz dokonać grupowej zmiany kolektorów?"}/>

      </React.Fragment>
    )
  }
}

BulkEditForm.propTypes = {
  surveyId: PropTypes.string.isRequired,
  collectorsIds: PropTypes.array.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default BulkEditForm;
