import _ from "lodash";

export const structureToElementsConverter = (structure) =>  {
	let sets = [];
	let blocks = [];
	let pages = [];
	let questions = [];
	let sPositionInSurvey = 0;
	let bPositionInSurvey = 0;
	let pPositionInSurvey = 0;
	let qPositionInSurvey = 0;

	_.each(structure.data.survey.setBlocks, setId => {
		sPositionInSurvey++;
		_.each(structure.data.sets[setId].blockPages, blockId => {
			bPositionInSurvey++;

			_.each(structure.data.blocks[blockId].pages, pageId => {
				pPositionInSurvey++;

				_.each(structure.data.pages[pageId].questions, questionId => {
					qPositionInSurvey++;

					let q = structure.data.questions[questionId];
					q.positionInSurvey = qPositionInSurvey;
					questions.push(q)
				})

				let p = structure.data.pages[pageId];
				p.positionInSurvey = pPositionInSurvey;
				pages.push(p)
			});

			let b = structure.data.blocks[blockId];
			b.positionInSurvey = bPositionInSurvey;
			blocks.push(b);
		});

		let s = structure.data.sets[setId];
		s.positionInSurvey = sPositionInSurvey;
		sets.push(s);
	});

	console.log(structure)

	return {
		sets,
		blocks,
		pages,
		questions,
	}
}