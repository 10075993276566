import * as React from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {Button} from "reactstrap";
import {connect} from "react-redux";
import api from "../../../../../api";

function SurveyAIRateModal(props) {
	const [open, setOpen] = React.useState(false);
	const [showScript, setShowScript] = React.useState(false);
	const [showInput, setShowInput] = React.useState(false);
	const [rated, setRated] = React.useState(0);
	const [isRating, setIsRating] = React.useState(false);
	const {survey, user, surveyAI} = props;
	const canRateSurvey = () => {
		if (rated) {
			return true;
		}

		return surveyAI.surveyAIRates.find((entry) => {
			return user.id === entry.userID;
		})
	}

	const countAVG = () => {
		let sum = 0;
		let count = surveyAI.surveyAIRates.length;
		surveyAI.surveyAIRates.forEach((entry) => {
			sum += entry.rate;
		})

		if (rated) {
			count++;
			sum += rated;
		}

		let number = sum / count

		return Math.round(number * 100) / 100;
	}

	const isAbleToRate = canRateSurvey() ? false : true;
	const avg = countAVG();

	const rateSurvey = (rate) => {
		if (isRating) {
			return;
		}

		setIsRating(true)
		api.ai.rateSurvey(survey.id, {rate: rate}).then(() => {
			setRated(rate);
			setIsRating(false);
			setOpen(false)
		}).catch(() => {
			setIsRating(false)
		})
	}

	return (
		<React.Fragment>
			{isAbleToRate && <span onClick={() => setOpen(true)} className="text-primary pointer">Oceń AI</span>}
			{isAbleToRate === false && <span onClick={() => setOpen(true)} className="text-primary pointer">Ocena AI ({avg})</span>}
			<Modal isOpen={open} toggle={() => setOpen(false)} size="lg">
				<ModalHeader toggle={() => setOpen(false)}>Oceń ankietę</ModalHeader>
				<ModalBody>
					<div class={"mb-3"}>
						<Button color={showInput ? "primary" : "secondary"} className={"mr-4"} onClick={() => {
							setShowInput(!showInput)
							setShowScript(false)
						}}>Zobacz input</Button>
						<Button color={showScript ? "primary" : "secondary"} onClick={() => {
							setShowScript(!showScript)
							setShowInput(false)
						}}>Zobacz skrypt</Button>
					</div>
					{showScript && <div>
						<h2>Skrypt</h2>
						<p style={{whiteSpace: "break-spaces"}}>{surveyAI.script}</p>
					</div>}
					{showInput && <div>
						<h2>Input</h2>
						<p style={{whiteSpace: "break-spaces"}}>{surveyAI.input}</p>
					</div>}
				</ModalBody>
				{isAbleToRate && <ModalFooter>
					<div class={"w-100 row"}>
						{Array.from({ length: 5 }, (_, index) => <Button color={"primary"} className={"mr-3"} onClick={() => rateSurvey(index+1)}>{index+1}</Button>)}
					</div>
				</ModalFooter>}
				{isAbleToRate === false && <ModalFooter>
					<span>Dziękujemy oceniłeś już ankietę</span>
				</ModalFooter>}
			</Modal>
		</React.Fragment>
	);
}

SurveyAIRateModal.defaultProps = {}

function mapStateToProps(state) {
	return {
		survey: state.survey.structure.data.survey,
		surveyAI: state.survey.structure.data.survey.surveyAI,
		user: state.user,
	}
}

export default connect(mapStateToProps, {})(SurveyAIRateModal);