import 'core-js/es6/map';
import 'core-js/es6/set';
import 'core-js/fn/array/find';
import 'core-js/fn/array/includes';
import 'core-js/fn/number/is-nan';
import 'element-remove';

import React from 'react';
import ReactDOM from "react-dom/client";
import {Route, Router} from 'react-router-dom';
import {createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import {IntlProvider} from 'react-intl';
import history from "services/history";
import qs from "query-string";

import "utils/axiosFetch";
import rootReducer from './rootReducer'
import i18Config from "./i18Config";

import smoothscroll from 'smoothscroll-polyfill';

import App from "./App";

import 'bootstrap/scss/bootstrap.scss';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css'
import 'ladda/dist/ladda-themeless.min.css';
import 'react-toastify/scss/main.scss'
import 'bootstrap/dist/js/bootstrap'
import "dashboard/styles/style.scss"
import 'react-table/react-table.css';
import 'dashboard/stylestmp/style.scss';

import setAuthorizationHeader from "../../dashboard/src/utils/setAuthorizationHeader";
import {userLoggedIn} from "reducers/user/reducer";

import api from "api";
import {normalize} from "normalizr";
import schemaUser from "./entities/user/schema";
import {setWidgets} from "./scenes/Dashboard/scenes/Widgets/data/widgets/actions";
import {setClients} from "./scenes/Clients/actions";
import {setSmtps} from "./scenes/Smtps/actions";
import {fetchConfig, setConfig} from "./reducers/config/actions";

import moment from "moment";
import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/lib/scss/react-widgets.scss';
moment.locale('pl');
momentLocalizer();

const consoleWarn = console.warn;
const consoleError = console.error;
const SUPPRESSED_WARNINGS = ['Warning:'];

if (process.env.ENV === 'production') {
  console.warn = function filterWarnings(msg, ...args) {
    if (!SUPPRESSED_WARNINGS.some((entry) => msg.includes(entry))) {
      consoleWarn(msg, ...args);
    }
  };

  console.error = function filterWarnings(msg, ...args) {
    if (!SUPPRESSED_WARNINGS.some((entry) => msg.includes(entry))) {
      consoleError(msg, ...args);
    }
  };
}

smoothscroll.polyfill();
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

const params = qs.parse(location.search);
if(params.token){
  localStorage.jwt = params.token;
}

fetchConfig().then(config => {
  store.dispatch(setConfig(config));
});

if(localStorage.jwt){
  setAuthorizationHeader(localStorage.jwt);

  api.user.get.user().then(user => {
    let orderedColumns = {};
    _.each(user.respondentBaseColumns, column => orderedColumns[column.id] = column);
    user.respondentBaseColumns = orderedColumns;
    const normalized = normalize(user, schemaUser);
    const usr = normalized.entities.user[normalized.result];
    normalized.entities.widgets && store.dispatch(setWidgets(normalized.entities.widgets));
    normalized.entities.clients && store.dispatch(setClients(normalized.entities.clients));
    normalized.entities.smtps && store.dispatch(setSmtps(normalized.entities.smtps));

    store.dispatch(userLoggedIn(usr));
    const redirect = localStorage.getItem('redirectAfterLogin');
    if(redirect){
      localStorage.removeItem('redirectAfterLogin')
      document.location.href = redirect;
    }
  }).catch(() => {
    store.dispatch(userLoggedIn());
  })
}

if(!localStorage.language) {
  api.language.get().then((res) => {
    let lang = res.language.split('-');
    localStorage.language = (lang[0] in messagesLangs) ? lang[0] : 'en';
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <IntlProvider locale={i18Config.locale} messages={i18Config.messages}>
    <Router history={history}>
      <Provider store={store}>
        <Route children={<App />}/>
      </Provider>
    </Router>
  </IntlProvider>
);
