import {structureToElementsConverter} from "./structureToElementsConverter";
import _ from "lodash";
import {cityProvinces} from "../../scenes/SurveyCollector/components/CollectorPanel/Tabs/CollectorPanelForm";

export default class TextToPipingsConverter{
	constructor(structure, text) {
		this.text = text;
		const elements = structureToElementsConverter(structure);
		this.sets = elements.sets;
		this.pages = elements.pages;
		this.blocks = elements.blocks;
		this.questions = elements.questions;
	}

	convert = () => {
		let pipings = this.text.split("\n").filter(piping => piping);

		pipings = _.uniqBy(pipings, function (piping) {
			return piping;
		});

		pipings = pipings.map(s => {
			let piping = {};

			if(s.match(/^(\[(\d+)\])?/g)){
				let match = [
					...s.matchAll(/^(\[(\d+)\])?/g),
				][0]
				piping.id = match[2];
				s = s.replace(match[0], '')
			}

			if(s.match(/^(\{Q\})/g)){
				piping = {
					...piping,
					...this.parseQuestionPiping(s.replace('{Q}', '')),
				}
			}else if(s.match(/^(\{T\})/g)){
				piping = {
					...piping,
					...this.parseTokenPiping(s.replace('{T}', '')),
				}
			}

			return piping;
		});

		return pipings || [];
	}

	parseQuestionPiping = t => {
		let piping = {
			type: 'question',
			replacableText: '',
			questionFrom: {
				question: null,
				fetchType: "content",
				type: ""
			}
		};

		const splitName = t.split(';');
		if (splitName.length > 1) {
			piping.replacableText = splitName[0];
			const questionSplit = splitName[1].split(":");
			if (questionSplit.length > 1) {
				console.log(questionSplit)
				const qPosition = questionSplit[0].substring(1);
				console.log(qPosition);
				const question = this.questions[qPosition-1];
				piping.questionFrom.question = question.id;
				const typeSplit = questionSplit[1].split("=");
				if (typeSplit.length > 1) {
					piping.questionFrom.type = typeSplit[0];
					piping.questionFrom.fetchType = typeSplit[1];
				}

				if (questionSplit.length > 2) {
					for (let i = 2; i < questionSplit.length; i++) {
						if (questionSplit[i].includes('places')) {
							let placesSplit = questionSplit[i].split('=');
							placesSplit = placesSplit[1].split(',');
							piping.questionFrom.places = [];
							placesSplit.forEach(entry => {
								const number = Number(entry);
								if (number) {
									piping.questionFrom.places.push(number);
								}
							})
						} else {
							const answerSplit = questionSplit[i].split(',');
							piping.questionFrom.answers = [];
							answerSplit.forEach(entry => {
								const number = Number(entry);
								if (number) {
									const answer = question.answers[Number(entry) - 1];
									piping.questionFrom.answers.push(answer);
								}
							})
						}
					}
				}
			}
		}

		return piping
	}

	parseTokenPiping = t => {
		let piping = {
			type: 'token',
			replacableText: '',
			tokenField: ''
		};

		const split = t.split("=");
		if (split.length > 0) {
			piping.replacableText = split[0];
		}

		if (split.length > 1) {
			piping.tokenField = split[1];
		}

		return piping
	}
}