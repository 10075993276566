import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import api from "api";
import ReactTable from "react-table";
import Pagination from "components/Pagination";
import PropTypes from 'prop-types';
import HelpModeIconTooltip from "components/HelpModeIconTooltip";

class TabSentHistory extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      sentHistory: [],
    }
  }

  componentDidMount() {
    this.setState({
      loading: true
    });

    api.surveyCollector.email.get.sent(this.props.collector).then(data => {
      this.setState({
        loading: false,
        sentHistory: data,
      })
    })
  }

  render() {
    const {loading, sentHistory} = this.state;

    const columns = [
      {
        id: 'address',
        Header: <FormattedMessage id="surveyCollector.typeEmail.sentHistory.table.email"/>,
        accessor: (history) => {
          if (history && history.address && history.address.respondentAddress) {
            return history.address.respondentAddress.email
          }

          return "";
        }
      },
      {
        Header: <FormattedMessage id="surveyCollector.typeEmail.sentHistory.table.createdAt"/>,
        accessor: 'createdAt'
      },
      {
        Header: 'Liczba wyświetleń',
        accessor: 'displayCount'
      },
    ];

    return (
      <React.Fragment>
        <HelpModeIconTooltip className="mb-2">Historia wysłanych zaproszeń do respondentów</HelpModeIconTooltip>
        <ReactTable
          minRows={0}
          className={'table-wrap'}
          data={sentHistory}
          columns={columns}
          showPagination={!_.isEmpty(sentHistory)}
          PaginationComponent={Pagination}
          noDataText={<FormattedMessage id="surveyCollector.typeEmail.addresses.table.noData" />}
          loading={loading}
          defaultPageSize={10}
        />
      </React.Fragment>
    )
  }
}

TabSentHistory.propTypes = {
  collector: PropTypes.object.isRequired
};

export default TabSentHistory;