import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {reverse, sortBy} from "lodash";
import api from "../../api";
import SurveysCategoryAdd from "./SurveysCategoryAdd";
import {ReactSortable} from "react-sortablejs";
import SurveysCategory from "./SurveysCategory";
import LoadingSpinner from "../../components/LoadingSpinner";
import {connect} from "react-redux";
import {fetchGroups} from "../../scenes/Group/actions";
import SurveysCategoriesFilters from "./SurveysCategoriesFilters";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {isMobile} from "react-device-detect";
import classnames from 'classnames';
import Button from "../../components/Button";
import Tooltip from "../../components/Tooltip";
import {updateProfile} from "../../reducers/user/actions";
import {FormattedMessage} from "react-intl";
import ModalSimple from "../../componentsReusable/ModalSimple";

const SortIcon = ({order}) => {
  return <React.Fragment>
    {order === 'asc' && <i className="fas fa-sort-up" />}
    {order === 'desc' && <i className="fas fa-sort-down" />}
  </React.Fragment>
}

const SurveysCategories = props => {
  let fetchSurveysTimeout = React.useRef();
  const [loadingSurveys, setLoadingSurveys] = React.useState(false);
  const [showSurveysCategories, setShowSurveysCategories] = React.useState(props.user.surveysCategoriesVisible);
  const [showEmpty, setShowEmpty] = React.useState(false);
  const [collapseAllCategories, setCollapseAllCategories] = React.useState(false);
  const [surveysCategoriesLoading, setSurveysCategoriesLoading] = React.useState(false);
  const [surveysCategories, setSurveysCategories] = React.useState([]);
  const [surveys, setSurveys] = React.useState([]);
  const [filters, setFilters] = React.useState({});
  const [sort, setSort] = React.useState(false);
  const [checkedIds, setCheckedIds] = React.useState([]);
  const [bulkDeleteModal, setBulkDeleteModal] = React.useState(false)
  const [bulkEditAvailable, setBulkEditAvailable] = React.useState(false)

  React.useEffect(() => {
    fetchSurveysCategories();
    // fetchSurveys();
    props.fetchGroups();
  }, []);

  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      clearTimeout(fetchSurveysTimeout.current);
      fetchSurveysTimeout.current = setTimeout(() => {
        fetchSurveys();
      }, 500);
    }
  }, [filters]);

  const toggleSort = (field) => {
    if(sort === false || sort.field !== field){
      setSort({
        field,
        order: 'asc',
      });
    }else{
      if(sort.order === 'desc'){
        setSort(false);
      }else{
        setSort({
          ...sort,
          order: 'desc',
        });
      }
    }
  }

  const fetchSurveysCategories = () => {
    setSurveysCategoriesLoading(true);
    api.surveysCategories.list()
      .then(data => {
        if(data.length !== surveysCategories.length){
          setSurveysCategories(data);
        }
        setSurveysCategoriesLoading(false);
      })
  }

  const fetchSurveys = () => {
    setLoadingSurveys(true)

    api.surveysCategories.surveys(filters)
      .then(data => {
        setSurveys(data.map(survey => {
          if(survey.surveyShares){
            let unique = [];
            survey.surveyShares = survey.surveyShares.split(',').map(surveyShare => {
              surveyShare = surveyShare.split(';');
              if(unique.includes(surveyShare[0])){
                return false;
              }
              unique.push(surveyShare[0])
              return {
                user: {
                  email: surveyShare[0],
                  firstname: surveyShare[1],
                  lastname: surveyShare[2],
                  avatar: surveyShare[3] !== '0' ? surveyShare[3] : null,
                },
              }
            }).filter(v => v);
          }else{
            survey.surveyShares = [];
          }

          if(survey.swPanelStatus){
            survey.swPanel = {
              status: survey.swPanelStatus,
              msg: survey.swPanelMsg,
            }
          }else{
            survey.swPanel = undefined;
          }

          survey.hasTopSurveysCollector = survey.hasTopSurveysCollector === 1;
          survey.hasActiveSurveyBase = survey.hasActiveSurveyBase === 1;

          return survey;
        }));
      })
      .finally(() => setLoadingSurveys(false))
  }

  const bulkDelete = () => {
    setLoadingSurveys(true)
    api.survey.post.bulkDelete(checkedIds).then(() => {
      fetchSurveys();
      setBulkDeleteModal(false)
      setCheckedIds([])
    })
  }

  const removeSurveysCategory = (surveysCategory) => {
    let removed = false;

    setSurveysCategories(surveysCategories.map(sc => {
      if(sc.id === surveysCategory.id){
        removed = true
        return false;
      }

      if(removed){
        sc.position--;
      }

      return sc;
    }).filter(sc => sc));

    setSurveys(surveys.map(survey => {
      if(survey.surveysCategoryId === surveysCategory.id){
        survey.surveysCategoryId = undefined;
        survey.surveysCategoryPosition = undefined;
      }

      return survey;
    }));
  }

  const onEnd = (evt) => {
    const {newIndex, oldIndex} = evt;
    const scId = parseInt(evt.item.attributes['data-id'].nodeValue);

    api.surveysCategories.patch({
      id: scId,
      position: newIndex,
    })

    let surveysCategoriesNew;

    if(newIndex > oldIndex){
      surveysCategoriesNew = surveysCategories.map(surveysCategory => {
        if(surveysCategory.position > oldIndex && surveysCategory.position <= newIndex){
          surveysCategory.position--;
        }
        if(surveysCategory.id === scId){
          surveysCategory.position = newIndex;
        }

        return surveysCategory;
      })
    }else{
      surveysCategoriesNew = surveysCategories.map(surveysCategory => {
        if(surveysCategory.position < oldIndex && surveysCategory.position >= newIndex){
          surveysCategory.position++;
        }

        if(surveysCategory.id === scId){
          surveysCategory.position = newIndex;
        }

        return surveysCategory;
      });
    }


    setSurveysCategories(surveysCategoriesNew);
  }

  const onEndSurveys = (evt) => {
    const {newIndex, oldIndex} = evt;
    const surveyId = parseInt(evt.item.attributes['data-id'].nodeValue);
    const surveyCategoryFromId = evt.from.attributes['id'] ? parseInt(evt.from.attributes['id'].nodeValue) : null;
    const surveyCategoryToId = evt.to.attributes['id'] ? parseInt(evt.to.attributes['id'].nodeValue) : null;
    const positionOld = getSurveysCategorySurveys(surveyCategoryFromId)[oldIndex].surveysCategoryPosition;
    const positionNew = newIndex in getSurveysCategorySurveys(surveyCategoryToId) ? getSurveysCategorySurveys(surveyCategoryToId)[newIndex].surveysCategoryPosition : newIndex;

    if(surveyCategoryFromId === null && surveyCategoryToId === null){
      return;
    }

    let surveysNew = surveys;

    if(surveyCategoryFromId !== surveyCategoryToId){
      if(surveyCategoryToId === null){
        surveysNew = surveysNew.map(survey => {
          if(survey.surveysCategoryId === surveyCategoryFromId){
            if(survey.surveysCategoryPosition >= positionOld){
              survey.surveysCategoryPosition--;
            }
          }


          if(survey.id === surveyId){
            survey.surveysCategoryId = undefined;
            survey.surveysCategoryPosition = undefined;
          }

          return survey;
        });
      }else{
        surveysNew = surveysNew.map(survey => {
          if(survey.surveysCategoryId === surveyCategoryToId){
            if(survey.surveysCategoryPosition >= positionNew){
              survey.surveysCategoryPosition++;
            }
          }


          if(survey.id === surveyId){
            survey.surveysCategoryId = surveyCategoryToId;
            survey.surveysCategoryPosition = positionNew;
          }

          return survey;
        });
      }
    }else{
      if(positionNew > positionOld){
        surveysNew = surveysNew.map(survey => {
          if(survey.surveysCategoryPosition > positionOld && survey.surveysCategoryPosition <= positionNew){
            survey.surveysCategoryPosition--;
          }
          if(survey.id === surveyId){
            survey.surveysCategoryPosition = positionNew;
          }

          return survey;
        })
      }else{
        surveysNew = surveysNew.map(survey => {
          if(survey.surveysCategoryPosition < positionOld && survey.surveysCategoryPosition >= positionNew){
            survey.surveysCategoryPosition++;
          }

          if(survey.id === surveyId){
            survey.surveysCategoryPosition = positionNew;
          }

          return survey;
        });
      }
    }

    api.surveysCategories.surveyPatch({
      surveyId,
      surveysCategoryId: surveyCategoryToId,
      surveysCategoryPosition: positionNew,
    });

    setSurveys(surveysNew);
  }

  const getSurveysCategories = () => {
    return sortBy(surveysCategories, surveysCategory => surveysCategory.position);
  }

  const getAllSurveys = () => {
    const s = sortBy(surveys, survey => {
      if(sort !== false && sort.field === 'name'){
        return survey.name;
      }
      if(sort !== false && sort.field === 'endAt'){
        return survey.endAt;
      }
      if(sort !== false && sort.field === 'paused'){
        return survey.paused;
      }
      if(sort !== false && sort.field === 'endResponsesCount'){
        return survey.endResponsesCount;
      }

      return parseInt(survey.id);
    });

    if(sort === false || (sort !== false && sort.order === 'desc')){
      return reverse(s);
    }

    return s;
  }

  const checkSurvey = (surveyId) => {
    if (checkedIds.includes(surveyId)) {
      const index = checkedIds.indexOf(surveyId);
      checkedIds.splice(index, 1);
    } else {
      checkedIds.push(surveyId);
    }
    setCheckedIds(checkedIds);
  }

  const getSurveysCategorySurveys = (surveysCategoryId) => {
    const s = sortBy(surveys.filter(survey => survey.surveysCategoryId === surveysCategoryId), survey => {
      if(sort !== false && sort.field === 'name'){
        return survey.name;
      }
      if(sort !== false && sort.field === 'endAt'){
        return survey.endAt;
      }
      if(sort !== false && sort.field === 'paused'){
        return survey.paused;
      }
      if(sort !== false && sort.field === 'endResponsesCount'){
        return survey.endResponsesCount;
      }

      if(surveysCategoryId === undefined){
        return survey.id;
      }

      return parseInt(survey.surveysCategoryPosition);
    });

    if(surveysCategoryId === undefined || (sort !== false && sort.order === 'desc')){
      return reverse(s);
    }

    return s;
  }

  const getUnassignedCategory = () => {
    return <div>
      <div className="list-group-item">
        <SurveysCategory
          unassignedCategory
          collapsed={collapseAllCategories}
          sortableDisabled={sort !== false}
          surveys={getSurveysCategorySurveys(undefined)}
          onEndSurveys={evt => onEndSurveys(evt)}
          onChange={() => fetchSurveys()}
          onChecked={checkSurvey}
        />
      </div>
    </div>
  }

  if(surveysCategoriesLoading){
    return <LoadingSpinner />
  }

  return (
    <Card id="surveys-categories" className="mb-lg-0" style={{height: props.styleHeight}}>
      <SurveysCategoriesFilters
        onBulkEditChange={setBulkEditAvailable}
        showHeaderTitle={props.showHeaderTitle}
        onChange={filters => setFilters(filters)}
        onChangeShowEmpty={showEmpty => setShowEmpty(showEmpty)}
        onChangeShowSurveysCategories={showSurveysCategories => setShowSurveysCategories(showSurveysCategories)}
      />

      <CardBody className="d-flex h-100 w-100 pt-2">
        <div className="w-100 d-flex flex-column h-100 position-relative">
          <div className={classnames({
            'surveys-loading-layer align-items-center justify-content-center': true,
            'd-flex': loadingSurveys,
            'd-none': !loadingSurveys,
          })} style={{
            opacity: loadingSurveys ? 1 : 0
          }}>
            <LoadingSpinner />
          </div>

          <div className="ReactTable table-wrap pt-3" style={{display: window.innerWidth <= 800 ? 'none' : 'block'}}>
            <div className="tr-tbody" style={{color: '#7b8089', fontSize: '0.8em'}}>
              <div className="rt-tr-group mr-4 pr-4">
                <div className="rt-tr text-center">
                  <div className="rt-td td-first text-left pl-4">
                    {props.plan.surveysCategories && <React.Fragment>
                      <i id={'show-surveys-categories-tooltip'} onClick={() => {
                        setShowSurveysCategories(!showSurveysCategories);

                        const formData = new FormData();
                        formData.append('surveysCategoriesVisible', !showSurveysCategories);
                        props.updateProfile(formData);
                      }} className={classnames({
                        'fas fa-folder-open p-1 pointer ml-2': true,
                        'text-primary': showSurveysCategories,
                      })} />
                      <Tooltip id={'show-surveys-categories-tooltip'} msg={'Pokaż/Ukryj sekcje'} />
                    </React.Fragment>}
                    {showSurveysCategories && <React.Fragment>
                      <i id={'show-empty-tooltip'} onClick={() => setShowEmpty(!showEmpty)} className={classnames({
                        'far fa-folder-open p-1 pointer': true,
                        'text-primary': showEmpty,
                      })} />
                      <Tooltip id={'show-empty-tooltip'} msg={'Pokaż/Ukryj puste sekcje'} />
                    </React.Fragment>}
                    {showSurveysCategories && <React.Fragment>
                      <i id={'toggle-all-tooltip'} onClick={() => setCollapseAllCategories(!collapseAllCategories)} className={classnames({
                        'fas fa-caret-square-down p-1 pointer': true,
                        'text-primary': collapseAllCategories,
                      })} />
                      <Tooltip id={'toggle-all-tooltip'} msg={'Rozwiń/Zwiń wszystkie sekcje'} />
                    </React.Fragment>}
                  </div>
                  <div className="rt-td td-name pointer ml-4 pl-4" onClick={() => toggleSort('name')}>
                    <span>Projekt</span>
                    {sort && sort.field === 'name' && <SortIcon order={sort.order}/>}
                  </div>
                  <div className="rt-td td-end pointer" onClick={() => toggleSort('endAt')}>
                    <span>Koniec badania</span>
                    {sort && sort.field === 'endAt' && <SortIcon order={sort.order}/>}
                  </div>
                  <div className="rt-td td-status pointer" onClick={() => toggleSort('paused')}>
                    <span>Status</span>
                    {sort && sort.field === 'paused' && <SortIcon order={sort.order}/>}
                  </div>
                  <div className="rt-td td-responsesCount pointer" onClick={() => toggleSort('endResponsesCount')}>
                    <span>Pełne wywiady</span>
                    {sort && sort.field === 'endResponsesCount' && <SortIcon order={sort.order}/>}
                  </div>
                  <div className="rt-td td-progressbar">
                    Postęp
                  </div>
                  {props.isUserRespondent && <div className="rt-td td-progressbar">
                    Średnia ocena
                  </div>}
                  <div className="rt-td td-actions">
                    Akcje
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id={'surveys-categories-list'} style={{minHeight: "180px"}} className="flex-grow-1 overflow-auto">
            {!showSurveysCategories && <React.Fragment>
              <SurveysCategory
                collapsed
                sortableDisabled
                surveysCollapsed
                hideHeader
                surveys={getAllSurveys()}
                onEndSurveys={evt => {}}
                onChange={surveyId => {
                  fetchSurveys()
                }}
                showCheckboxes={bulkEditAvailable}
                onChecked={checkSurvey}
              />
            </React.Fragment>}

            {showSurveysCategories && <React.Fragment>
              {props.user.surveysCategoriesUnassignedPlacement === 'top' && getUnassignedCategory()}

              <ReactSortable
                group={{
                  name: 'surveysCategories',
                }}
                list={surveysCategories}
                setList={() => {}}
                onEnd={onEnd}
                handle={".handler-sortable"}
              >
                {getSurveysCategories().map(surveysCategory => {
                  const surveys = getSurveysCategorySurveys(surveysCategory.id);

                  return <div className={classnames({
                    'list-group-item visibilitiable': true,
                    'd-flex flex-column': showEmpty || surveys.length > 0,
                    'd-none': surveys.length === 0 && !showEmpty,
                  })} key={surveysCategory.id}>
                    <SurveysCategory
                      collapsed={collapseAllCategories}
                      sortableDisabled={sort !== false}
                      surveysCategory={surveysCategory}
                      surveys={surveys}
                      onRemoved={() => {
                        removeSurveysCategory(surveysCategory);
                      }}
                      showCheckboxes={bulkEditAvailable}
                      onChecked={checkSurvey}
                      onEndSurveys={evt => onEndSurveys(evt)}
                      onChangeName={name => setSurveysCategories(surveysCategories.map(s => {
                        if(s.id === surveysCategory.id){
                          s.name = name;
                        }

                        return s;
                      }))}
                      onChange={surveyId => fetchSurveys()}
                    />
                  </div>
                })}
              </ReactSortable>

              {props.user.surveysCategoriesUnassignedPlacement === 'bottom' && getUnassignedCategory()}

              <SurveysCategoryAdd
                onAdded={surveysCategory => {
                  setSurveysCategories([
                    ...surveysCategories,
                    surveysCategory,
                  ]);
                }}
              />
            </React.Fragment>}
          </div>
        </div>
      </CardBody>
      {bulkEditAvailable && <div class={"ml-lg-4 pl-2"}>
        <Row>
          <Col lg={4}>
            <Button color="primary" className="btn btn-primary" onClick={() => {
              if (checkedIds.length > 0) {
                setBulkDeleteModal(true)
              }
            }}>Usuń zaznaczone</Button>
          </Col>
        </Row>
      </div>}
      <ModalSimple onAccept={() => bulkDelete()}
          onCancel={() => setBulkDeleteModal(false)}
          modalOpen={bulkDeleteModal}
          headerText={"Usuwanie ankiet"}
          acceptText={"Skasuj (" + checkedIds.length + ")"}
          bodyText={"Czy na pewno chcesz skasować ("+checkedIds.length+") ankiety"}
          cancelText={"Anuluj"}
      />
    </Card>
  );
};

SurveysCategories.defaultProps = {
  showHeaderTitle: true,
  styleHeight: '',
};

SurveysCategories.propTypes = {
  showHeaderTitle: PropTypes.bool,
  styleHeight: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    user: state.user,
    plan: state.user.userPlan.plan,
    isUserRespondent: state.user.type === 'Respondent',
  }
}

export default connect(mapStateToProps, {fetchGroups, updateProfile})(SurveysCategories);
