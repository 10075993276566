import React, {Component} from 'react';
import {CardBody, Col, Row} from 'reactstrap';
import {FormattedMessage} from "react-intl";
import moment from "moment/moment";
import "react-input-range/lib/css/index.css";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import 'react-datepicker/dist/react-datepicker.css';
import PropTypes from 'prop-types';
import api from 'api';
import Switch from "../../../../components/Switch";
import FormDefaultProperty from "../FormDefaultProperty";
import FormGroup from "reactstrap/es/FormGroup";
import Label from "reactstrap/es/Label";
import Tooltip from "../../../../components/Tooltip";

class CollectorTest extends Component {

  constructor(props) {
    super(props);

    this.state = {
      data: {
        guid: props.collector.guid,
        startAt: moment(props.collector.startAt).format('YYYY-MM-DD'),
        endAt: moment(props.collector.endAt).format('YYYY-MM-DD'),
        isSubdomain: props.collector.isSubdomain,
        invitationScreen: props.collector.invitationScreen,
        collectorResponses: props.collector.collectorResponses,
        disableCookieBlockade: props.collector.disableCookieBlockade,
        disableIpBlockade: props.collector.disableIpBlockade,
        enabledSurveyFilters: props.collector.enabledSurveyFilters,
        enabledValidations: props.collector.enabledValidations,
        showInvitationText: props.collector.showInvitationText,
      },
      saving: false,
      errors: {}
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit() {
    this.setState({...this.state, saving: true, errors: {}});
    const {data} = this.state;

    api.surveyCollector.patch({
      id: this.props.collector.id,
      surveyCollector: data,
    })
      .then(collector => {
        this.setState({...this.state, saving: false});
        this.props.handleUpdate(collector);
      })
      .catch(e => this.setState({
        errors: e.response.data.form.errors,
        saving: false
      }));
  }

  render() {
    const {data, errors} = this.state;

    return (
      <CardBody>
        <FormDefaultProperty
          errors={errors}
          copyLinkWithQuestion={true}
          collector={data}
          subdomainAllowed={true}
          onPropertyChanged={(name, value) => {
            this.setState({
              data: {
                ...data,
                [name]: value,
              }
            })
          }}
        />

        <Row>
          <Col lg={6}>
            <FormGroup row>
              <Label lg={3}><FormattedMessage id="collectorTest.label.disableCookieBlockade" /></Label>
              <Col lg={9} className="pt-2">
                <Switch checked={data.disableCookieBlockade} onChange={(disableCookieBlockade) => this.setState({data: {...data, disableCookieBlockade}})} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label lg={3}><FormattedMessage id="collectorTest.label.disableIpBlockade" /></Label>
              <Col lg={9} className="pt-2">
                <Switch checked={data.disableIpBlockade} onChange={(disableIpBlockade) => this.setState({data: {...data, disableIpBlockade}})} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label lg={3}><FormattedMessage id="collectorTest.label.enabledSurveyFilters" /></Label>
              <Col lg={9} className="pt-2">
                <Switch checked={data.enabledSurveyFilters} onChange={(enabledSurveyFilters) => this.setState({data: {...data, enabledSurveyFilters}})} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label lg={3}><FormattedMessage id="collectorTest.label.enabledValidations" /></Label>
              <Col lg={9} className="pt-2">
                <Switch checked={data.enabledValidations} onChange={(enabledValidations) => this.setState({data: {...data, enabledValidations}})} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label id={'showInvitationText-tooltip'} lg={3}><FormattedMessage id="collectorTest.label.showInvitationText" /><Tooltip id={'showInvitationText-tooltip'} style={{maxWidth: '500px'}} msg={<div className="text-white">
                <p className="text-white">Uwaga dotycząca widoczności przycisku cofnij na 1 stronie ankiety (który pozwala cofnąć się do ekranu startowego).</p>
                <p className="text-white">Jeśli blok zawiera przycisk cofnij i chcesz aby osoba testująca nie miała dostępu do treści zaproszenia - ukryj przycisk cofnij w bloku, korzystając z opcji ustawień bloku.</p>
                <p className="text-white">Pamiętaj, że pierwsze pytanie możesz także umieścić w jednostronicowym bloku. To pozwoli na nieinwazyjną zmianę w ankiecie.</p>
              </div>} /></Label>
              <Col lg={9} className="pt-2">
                <Switch checked={data.showInvitationText} onChange={(showInvitationText) => this.setState({data: {...data, showInvitationText}})} />
              </Col>
            </FormGroup>
          </Col>
        </Row>

        <FormattedMessage id="_.button.save">{msg => <LaddaButton
          className="btn btn-primary btn-ladda mt-4 float-right"
          loading={this.state.saving}
          onClick={this.onSubmit}
          data-style={ZOOM_OUT}
        >
          {msg}
        </LaddaButton>}</FormattedMessage>
      </CardBody>
    )
  }
}

CollectorTest.propTypes = {
  collector: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired
};

export default CollectorTest;
