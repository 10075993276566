import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import { connect } from 'react-redux';
import ShareForm from "./ShareForm";
import {updateShare} from "../../../reducer/shares/actions";
import PropTypes from "prop-types";

class ShareEditButton extends Component{
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      saving: false,
      errors: {}
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.shareForm = React.createRef();
  }

  toggleModal() {
    this.setState({modal: !this.state.modal});
  }

  onSubmit(e, data) {
    this.setState({saving: true});

    this.props.updateShare({
      id: this.props.share.id,
      data: {
        name: data.name,
        password: data.password,
        isSocialShare: data.isSocialShare
      }
    }).then(res => {
      this.setState({modal: false, saving: false})
    }).catch(res => {
      this.setState({
        errors: res.response.data.form.errors,
        saving: false
      })
    });
  }

  render() {
    const modal = <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
      <ModalHeader toggle={this.toggleModal} tag="h2"><FormattedMessage id="view.shareEditButton.modalHeader" /></ModalHeader>
      <hr className="m-0" />
      <ModalBody>
       <ShareForm onSubmit={this.onSubmit} errors={this.state.errors} share={this.props.share} ref={this.shareForm}/>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={this.toggleModal}><FormattedMessage id="_.button.cancel" /></Button>
          <LaddaButton
            className="btn btn-primary btn-ladda"
            loading={this.state.saving}
            data-style={ZOOM_OUT}
            onClick={(e) => this.onSubmit(e, this.shareForm.current.state.data)}
          >
            <FormattedMessage id="_.button.save" />
          </LaddaButton>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Button color="" className="fa
        mr-2 p-0" onClick={this.toggleModal}><i className="fas fa-edit"/></Button>
        {modal}
      </React.Fragment>
    )
  }
}

ShareEditButton.propTypes = {
  share: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    guid: PropTypes.string.isRequired,
  }).isRequired
};


export default connect(null, {updateShare})(ShareEditButton);
