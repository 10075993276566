import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import Question from "../../../Modals/Question";
import {injectIntl} from "react-intl";
import _ from "lodash";
import Single from "./Single";
import Multi from "./Multi";
import Ranked from "./Ranked";
import Conjoint from "./Conjoint";
import Open from "./Open";
import Matrix from "./Matrix";
import Numeric from "./Numeric";
import NumericMulti from "./NumericMulti";
import Basket from "./Basket";
import Differential from "./Differential";
import MatrixDropdown from "./MatrixDropdown";
import Nps from "./Nps";

class CondDetail extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.toggleIsOpen = this.toggleIsOpen.bind(this);
    this.renderCondDetailByQuestionType = this.renderCondDetailByQuestionType.bind(this);
  }

  toggleIsOpen(){
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  renderCondDetailByQuestionType(){
    const {condQuestion, questions, changeable} = this.props;

    const question = questions.find(q => q.id === condQuestion.question);

    if(!question){
      return null;
    }

    switch (question.type) {
      case 'single':
        return <Single condQuestion={condQuestion} handleCondChanged={condQuestion => this.props.handleCondChanged(condQuestion)} changeable={changeable}/>;
      case 'multi':
        return <Multi condQuestion={condQuestion} handleCondChanged={condQuestion => this.props.handleCondChanged(condQuestion)} changeable={changeable}/>;
      case 'ranked':
        return <Ranked condQuestion={condQuestion} handleCondChanged={condQuestion => this.props.handleCondChanged(condQuestion)} changeable={changeable}/>;
      case 'conjoint':
        return <Conjoint condQuestion={condQuestion} handleCondChanged={condQuestion => this.props.handleCondChanged(condQuestion)} changeable={changeable}/>;
      case 'open':
        return <Open condQuestion={condQuestion} handleCondChanged={condQuestion => this.props.handleCondChanged(condQuestion)} changeable={changeable}/>;
      case 'matrix':
      case 'matrixMulti':
        return <Matrix condQuestion={condQuestion} handleCondChanged={condQuestion => this.props.handleCondChanged(condQuestion)} changeable={changeable}/>;
      case 'numeric':
        return <Numeric condQuestion={condQuestion} handleCondChanged={condQuestion => this.props.handleCondChanged(condQuestion)} changeable={changeable}/>;
      case 'numericMulti':
        return <NumericMulti condQuestion={condQuestion} handleCondChanged={condQuestion => this.props.handleCondChanged(condQuestion)} changeable={changeable}/>;
      case 'basket':
        return <Basket condQuestion={condQuestion} handleCondChanged={condQuestion => this.props.handleCondChanged(condQuestion)} changeable={changeable}/>;
      case 'differential':
        return <Differential condQuestion={condQuestion} handleCondChanged={condQuestion => this.props.handleCondChanged(condQuestion)} changeable={changeable}/>;
      case 'matrixDropdown':
        return <MatrixDropdown condQuestion={condQuestion} handleCondChanged={condQuestion => this.props.handleCondChanged(condQuestion)} changeable={changeable}/>;
      case 'nps':
        return <Nps condQuestion={condQuestion} handleCondChanged={condQuestion => this.props.handleCondChanged(condQuestion)} changeable={changeable}/>;
    }
  }

  render() {
    const {condQuestion, changeable} = this.props;

    if (condQuestion.collector) {
      return <span>Jeżeli respondent pochodzi z kolektora {condQuestion.collectorName}</span>
    }

    return (
      <React.Fragment>
        <span>Jeżeli w pytaniu </span>
        <Question question={condQuestion.question} handleQuestionChanged={question => {
          let condUpdated = {
            ...condQuestion,
            question,
            answers: [],
            rows: [],
            columns: [],
            baskets: [],
          };

          this.props.handleCondChanged(condUpdated)
        }} changeable={changeable} />
        {condQuestion.question && <span className="mr-1">respondent </span>}

        {condQuestion.question && this.renderCondDetailByQuestionType()}
      </React.Fragment>
    );
  }
}

CondDetail.defaultProps = {
  changeable: true
};

CondDetail.propTypes = {
  condQuestion: PropTypes.shape({
    question: PropTypes.number,
  }).isRequired,
  handleCondChanged: PropTypes.func,
  changeable: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  let questions = [];
  let positionInSurvey = 0;

  _.each(state.survey.structure.data.survey.setBlocks, setBlockId => {
    _.each(state.survey.structure.data.sets[setBlockId].blockPages, blockId => {
      _.each(state.survey.structure.data.blocks[blockId].pages, pageId => {
        _.each(state.survey.structure.data.pages[pageId].questions, questionId => {
          positionInSurvey++;

          let q = state.survey.structure.data.questions[questionId];
          q.positionInSurvey = positionInSurvey;
          questions.push(q)
        })
      });
    });
  });

  return {
    questions
  }
}

export default connect(mapStateToProps)(injectIntl(CondDetail));
