import React, {Component} from 'react';
import {connect} from "react-redux";
import {ReactSortable} from "react-sortablejs";
import {FormattedMessage} from "react-intl";
import {addQuestion} from "scenes/Survey/Structure/data/questions/actions";
import QuestionInfo from "components/QuestionInfo";
import i18Config from "i18Config";
import PropTypes from "prop-types";
import classnames from "classnames";
import {questionTypes} from "services/questionTypes";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import QuestionAvailable from "components/PlanRestrictionsModals/modals/QuestionAvailable";
import HelpModeIconTooltip from "components/HelpModeIconTooltip";
import OnMoving from "../../../OnMoving";
import OnAdding from "../../../OnAdding";
import {toggleQuestionCard} from "scenes/Survey/Structure/actions"
import {updateFavoriteQuestions} from "reducers/user/actions";

class QuestionElements extends Component {

  constructor(props) {
    super(props);

    this.state = {
      adding: false,
      isClosed: [],
      favoriteTypes: props.user.favoriteQuestionTypes
    };

    this.onEndQuestions = this.onEndQuestions.bind(this);
    this.shouldRenderTemplate = this.shouldRenderTemplate.bind(this);
  }

  onEndQuestions(evt) {
    if (evt.from === evt.to) {
      return false;
    }
    evt.to.insertBefore(evt.item, evt.to.children[evt.newIndex]);

    this.setState({
      adding: true,
    });

    const {pages} = this.props;
    const page = pages[parseInt(evt.to.parentElement.firstChild.attributes['data-id'].nodeValue)];

    const type = evt.item.attributes['data-question-type'].nodeValue;
    const templateIndex = evt.item.attributes['data-template-index'].nodeValue;
    const options = questionTypes[type][templateIndex].options;

    this.props.addQuestion({
      page: page.id,
      questions: [
        {
          type: questionTypes[type][templateIndex]['type'],
          position: evt.newIndex,
          ...options
        }

      ]
    }).then(questions => {
      if (this.props.hideInStructure === 'questions' || this.props.hideInStructure === 'structure' ) {
        questions.map(question => {
          this.props.toggleQuestionCard(question.id);
        });
      }
      evt.item.remove();
    }).catch(() => {
      evt.item.remove();
    })
  }

  shouldRenderTemplate(questionType, templateType) {
    if (typeof this.props.searchText !== 'string')
      return true;

    const searchingText = this.props.searchText.toLowerCase();
    const questionName = i18Config.messages['_.question.' + questionType + '.typeName'].toLowerCase();
    const templateName = i18Config.messages['_.question.' + templateType + '.typeName'].toLowerCase();

    return questionName.includes(searchingText) || questionType.includes(searchingText) || templateName.includes(searchingText) || templateType.includes(searchingText);
  }

  toggleFavorite(template) {
    const {favoriteTypes} = this.state;

    let type = favoriteTypes.find(type => type.name === template.name);

    if (type) {
      favoriteTypes.splice(favoriteTypes.indexOf(type), 1);
    } else {
      favoriteTypes.push(template)
    }

    this.setState({favoriteTypes});
    this.props.updateFavoriteQuestions({data: {favoriteQuestionTypes: favoriteTypes}})
  }

  render() {
    const {surveyPlan} = this.props;
    const {adding, isClosed} = this.state;

    if(adding){
      this.setState({
        adding: false,
      });

      return null;
    }

    return (
      <ReactSortable
        list={Object.keys(questionTypes)}
        setList={() => {}}
        group={{
          name: 'questions',
          pull: 'clone',
          put: false,
          revertClone: true,
        }}
        sort={false}
        onEnd={this.onEndQuestions}
        filter=".sortable-ignore"
      >
        {_.map(Object.keys(questionTypes), type => {
          const label = <div className="line-wrap small text-center p-1 sortable-ignore" style={{backgroundColor: '#b2ccf4'}}>
            {isClosed.includes(type) === false && <i className="fas fa-angle-down float-left p-1" onClick={() => {
              isClosed.push(type);
              this.setState({
                isClosed: isClosed
              })
            }}/>}
            {isClosed.includes(type) === true && <i className="fas fa-angle-up float-left p-1" onClick={() => {
              const array = isClosed.filter( function(value) {
                return value !== type
              });
              this.setState({
                isClosed: array
              })
            }}/>}
            <FormattedMessage id={'_.question.' + type + '.typeName'} />
          </div>;

          const templates = _.map(questionTypes[type], (template, key) => {
            const templateAvailableInPlan =
              surveyPlan.questionsAvailable.indexOf(template.type) >= 0
              && (
                !_.has(template, 'options.presentationType')
                || (
                  _.has(template, 'options.presentationType')
                  && (
                    surveyPlan.questionSettings[template.type].presentationTypes.length === 0
                    || surveyPlan.questionSettings[template.type].presentationTypes.indexOf(template.options.presentationType) >= 0
                  )
                )
              )
            ;

            return this.shouldRenderTemplate(type, template.name) && template.hidden !== true && <div data-question-type={type} data-template-index={key} key={key} role="question" className={classnames({
              'line-wrap ml-0 d-flex align-items-center py-1': true,
              'not-active sortable-ignore': !templateAvailableInPlan,
            })} onClick={() => !templateAvailableInPlan && this.props.setPlanRestrictionsModals(QuestionAvailable.getName())}>
              <a className="fas fa-grip-vertical dragg float-left mr-2 mt-0"/>
              <a className={classnames({
                "mr-2": true,
                "far fa-star": !this.state.favoriteTypes.find(type => type.name === template.name),
                "fas fa-star text-warning": this.state.favoriteTypes.find(type => type.name === template.name),
              })} onClick={() => templateAvailableInPlan && this.toggleFavorite(template)}/>
              <ul className="mb-0 pl-0 d-flex align-items-center w-100">
                <li className="mr-2">
                  <QuestionInfo question={{...template, ...template.options}}/>
                </li>
                <li className="overflow-hidden w-100 d-flex align-items-center">
                  <h5 className="mb-0"><FormattedMessage id={'_.question.' + template.name + '.typeName'} /></h5>
                  <HelpModeIconTooltip className="float-right ml-auto pl-2"><FormattedMessage
                    id={'elements.questionElements.' + template.type + '.helpTooltip'} /></HelpModeIconTooltip>
                </li>
              </ul>
              <OnMoving/>
              <OnAdding/>
            </div>
          });

          return <React.Fragment key={type}>
            {label}
            {isClosed.includes(type) === false && templates}
          </React.Fragment>;
        })}
      </ReactSortable>
    )
  }
}

QuestionElements.propTypes = {
  searchText: PropTypes.string,
  surveyPlan: PropTypes.shape({
    questionsAvailable: PropTypes.array.isRequired,
  }),
};

function mapStateToProps(state) {
  return {
    pages: state.survey.structure.data.pages,
    surveyPlan: state.user.userPlan.plan.survey,
    hideInStructure: state.survey.structure.hideInStructure,
    user: state.user
  }
}

export default connect(mapStateToProps, {addQuestion, setPlanRestrictionsModals, toggleQuestionCard, updateFavoriteQuestions})(QuestionElements);