import _ from "lodash";

export const questionTypes = {
  'closed': [
    {
      type: 'single',
      name: 'single'
    },
    {
      type: 'single',
      name: 'single-scale',
      options: {
        presentationType: 'scale'
      }
    },
    {
      type: 'single',
      name: 'single-dropdown',
      hidden: true,
      options: {
        presentationType: 'dropdown'
      }
    },
    {
      type: 'single',
      name: 'single-dropdownFilter',
      hidden: true,
      options: {
        presentationType: 'dropdownFilter'
      }
    },
    {
      type: 'multi',
      name: 'multi',
    },
    {
      type: 'ranked',
      name: 'ranked',
    },
    {
      type: 'numeric',
      name: 'numeric-slider',
      options: {
        presentationType: 'slider'
      }
    },
    {
      type: 'numericMulti',
      name: 'numericMulti-slider',
      options: {
        presentationType: 'slider'
      }
    },
  ],
  'open': [
    {
      type: 'open',
      name: 'open'
    },
    {
      type: 'open',
      name: 'open-textarea',
      hidden: true,
      options: {
        presentationType: 'textarea'
      }
    },
    {
      type: 'open',
      name: 'open-email',
      hidden: true,
      options: {
        presentationType: 'email'
      }
    },
    {
      type: 'open',
      name: 'open-phone',
      hidden: true,
      options: {
        presentationType: 'phone'
      }
    },
    {
      type: 'open',
      name: 'open-library',
      hidden: true,
      options: {
        presentationType: 'library'
      }
    },
    {
      type: 'multiOpen',
      name: 'multiOpen',
    },
    {
      type: 'multiOpen',
      name: 'multiOpen-textarea',
      hidden: true,
      options: {
        presentationType: 'textarea'
      }
    },
    {
      type: 'multiOpen',
      name: 'multiOpen-datetime',
      hidden: true,
      options: {
        presentationType: 'datetime'
      }
    },
    {
      type: 'numeric',
      name: 'numeric-input',
      options: {
        presentationType: 'input'
      }
    },
    {
      type: 'numericMulti',
      name: 'numericMulti-input',
      options: {
        presentationType: 'input'
      }
    }
  ],
  'matrices-and-carousels': [
    {
      type: 'matrix',
      name: 'matrix-table',
      options: {
        presentationType: 'table'
      }
    },
    {
      type: 'matrixMulti',
      name: 'matrixMulti-table',
      options: {
        presentationType: 'table'
      }
    },
    {
      type: 'matrix',
      name: 'matrix-carousel',
      options: {
        presentationType: 'carousel'
      }
    },
    {
      type: 'matrixMulti',
      name: 'matrixMulti-carousel',
      options: {
        presentationType: 'carousel'
      }
    },
    {
      type: 'matrixDropdown',
      name: 'matrixDropdown',
    },
  ],
  'closed-special': [
    {
      type: 'differential',
      name: 'differential',
    },
    {
      type: 'nps',
      name: 'nps',
    },
    {
      type: 'basket',
      name: 'basket',
    },
    {
      type: 'maxDiff',
      name: 'maxDiff',
    },
    {
      type: 'gMap',
      name: 'gMap',
    },
    {
      type: 'bpto',
      name: 'bpto',
    },
    {
      type: 'cbc',
      name: 'cbc',
    },
    {
      type: 'conjoint',
      name: 'conjoint',
    },
    {
      type: 'numericMulti',
      name: 'numericMulti-dragAndDrop',
      options: {
        presentationType: 'dragAndDrop',
        valueMax: 100,
      }
    },
    {
      type: 'multi',
      name: 'multi-heatMap',
      options: {
        presentationType: 'heatMap'
      }
    },
    {
      type: 'numericMulti',
      name: 'numericMulti-psm',
      options: {
        presentationType: 'psm'
      }
    },
  ],
  'additives': [
    {
      type: 'video',
      name: 'video',
    },
    {
      type: 'comment',
      name: 'comment',
    },
    {
      type: 'open',
      name: 'open-email',
      options: {
        presentationType: 'email'
      }
    },
    {
      type: 'open',
      name: 'open-phone',
      options: {
        presentationType: 'phone'
      }
    },
    {
      type: 'open',
      name: 'open-library',
      options: {
        presentationType: 'library'
      }
    },
    {
      type: 'multiOpen',
      name: 'multiOpen-datetime',
      options: {
        presentationType: 'datetime'
      }
    },
  ],
};

export function getQuestionTemplate(name) {
  let qType;

  _.each(questionTypes, type => {
    _.each(type, template => {
      if (template.name === name) {
        qType = template
      }
    })
  });

  return qType
}

export function getQuestionTypes() {
  const types = _.map(questionTypes, type => type);
  return [].concat.apply([], types);
}

export function getQuestionTemplateName(question) {
  let questionTemplateName;

  _.each(questionTypes, templates => {
    _.each(templates, template => {
      if (template.type === question.type) {
        if (template.hasOwnProperty('options')) {
          let options = 0;

          _.each(Object.keys(template.options), option => {
            if (question[option] === template.options[option]) {
              options++
            }
          });

          if (options === Object.keys(template.options).length) {
            questionTemplateName = template.name
          }
        } else {
          questionTemplateName = template.name
        }
      }
    })
  });
  return questionTemplateName;
}
