import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, UncontrolledTooltip} from 'reactstrap';
import SurveysModalCopy from "./Modals/SurveysModalCopy";
import SurveysModalDelete from "./Modals/SurveysModalDelete";
import SurveysModalClear from "./Modals/SurveysModalClear";
import SurveyShareButton from "scenes/SurveyShare/components/SurveyShareButton";
import SurveysModalSettings from './Modals/SurveysModalSettings';
import SurveysModalShare from './Modals/SurveysModalShare';
import {FormattedMessage} from "react-intl";
import appRoutes from "appRoutes";
import {Link} from "react-router-dom";
import { connect } from 'react-redux';
import SurveysModalAnalysis from "./Modals/SurveysModalAnalysis";
import WarningIcon from "./WarningIcon";
import SurveysBaseStatusIcon from "./Icons/SurveysBaseStatusIcon";
import SurveysTopSurveysStatusIcon from "./Icons/SurveysTopSurveysStatusIcon";
import moment from "moment";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import SurveyAccess from "components/PlanRestrictionsModals/modals/SurveyAccess";
import {isMobile} from "react-device-detect";
import checkIsGroupRole from "../../../utils/checkIsGroupRole";
import PrintStructure from "../../Survey/Structure/components/PrintStructure";
import SurveyScriptModal from "../../Survey/Structure/components/SurveyScriptModal";

class SurveysActions extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
      analysisModal: false,
      copyModal: false,
      clearModal: false,
      deleteModal: false,
      settingsModal: false,
      shareModal: false,
      surveyExportModal: false
    };

    this.toggle = this.toggle.bind(this);
    this.onLinkClick = this.onLinkClick.bind(this);
  }

  toggle(property) {
    this.setState({
      [property]: !this.state[property]
    }, () => {
      if(property === 'settingsModal' && !this.state[property]){
        this.props.handleChange();
      }
    });
  }

  onLinkClick(e) {
    if (!this.props.hasAccess) {
      e.preventDefault();
      this.props.setPlanRestrictionsModals(SurveyAccess.getName());
    }
  }

  checkIsGroupRole(roles = []) {
    const {user, userGroup} = this.props;

    if (!userGroup || userGroup.length === 0) {
      return true;
    }

    const groupUser = userGroup.find((entry) => entry.email === user.email)
    if (groupUser) {
      const userRoleName = groupUser.privilageSurveyShareDefault.name;
      return roles.includes(userRoleName);
    }

    return true;
  }

  render() {
    const {survey, isUserRespondent, user, userGroup} = this.props;

    return (
      <React.Fragment>
        <ul className="mb-0 p-0 d-flex flex-row flex-nowrap justify-content-start w-100">
          <li className="mr-2 d-none d-md-inline-block">
            <WarningIcon survey={survey} targetId={'mobile'} />
          </li>

          {(!survey.hasActiveSurveyBase || this.props.swPanelDefaultAccepted) && checkIsGroupRole('accessEdit', user, userGroup) && <li className="d-inline-block mr-2">
            <Button color="" id={'survey-edit-' + survey.id} className="m-0 p-0"><Link onClick={this.onLinkClick} to={appRoutes.survey.structure(survey.id)}><i className="fas fa-edit text-dark" /></Link></Button>
            <UncontrolledTooltip placement="right" target={'survey-edit-' + survey.id} delay={0}>Edycja ankiety</UncontrolledTooltip>
          </li>}
          {checkIsGroupRole('accessPreviewAnalysisData', user, userGroup) && <li className="d-inline-block mr-2">
            <Button color="" id={'survey-analysis-' + survey.id} className="m-0 p-0"><Link onClick={this.onLinkClick} target={isMobile ? '_blank' : ''} to={appRoutes.survey.analysis.main(survey.id)}><i className="fas fa-chart-pie text-dark" /></Link></Button>
            <UncontrolledTooltip placement="right" target={'survey-analysis-' + survey.id} delay={0}>Analiza wyników</UncontrolledTooltip>
          </li>}
          {(!survey.hasActiveSurveyBase || this.props.swPanelDefaultAccepted) && checkIsGroupRole('accessCollectors', user, userGroup) && <li className="d-inline-block mr-2">
            <Button color="" id={'survey-collectors-' + survey.id} className="m-0 p-0"><Link onClick={this.onLinkClick} to={appRoutes.survey.collectors.list(survey.id)}><i className="fas fa-server text-dark" /></Link></Button>
            <UncontrolledTooltip placement="right" target={'survey-collectors-' + survey.id} delay={0}>Kolektory</UncontrolledTooltip>
          </li>}
          {(!survey.hasActiveSurveyBase || this.props.swPanelDefaultAccepted) && checkIsGroupRole('accessEdit', user, userGroup) && <li className="d-inline-block mr-2">
            <Button color="" id={'survey-settings-' + survey.id} className="m-0 p-0" onClick={() => this.toggle('settingsModal')}><i className="fas fa-cog"/></Button>
            <UncontrolledTooltip placement="right" target={'survey-settings-' + survey.id} delay={0}>Ustawienia ankiety</UncontrolledTooltip>
          </li>}
          <li className=" mr-2 d-none d-md-inline">
            <SurveyShareButton
              surveyId={survey.id}
              surveyShares={survey.surveyShares}
              toggleModal={() => this.toggle('shareModal')}
            />
          </li>
          {checkIsGroupRole('accessExportAnalysis', user, userGroup) && <li className="d-inline-block ml-auto">
            <Dropdown isOpen={this.state.dropdownOpen} toggle={() => this.toggle('dropdownOpen')} className={'dd-' + survey.id}>
              <DropdownToggle className="m-0 p-0 d-none d-md-inline-block" color="" data-toggle={'dd-' + survey.id}>
                <span className="fas fa-ellipsis-v" />
              </DropdownToggle>
              <DropdownMenu>
                {checkIsGroupRole('accessExportAnalysis', user, userGroup) && <DropdownItem onClick={() => this.props.hasAccess ? this.toggle('analysisModal') : this.props.setPlanRestrictionsModals(SurveyAccess.getName())} tag={"a"}>
                  <p><i className="fas fa-download" /></p> <h5>Eksportuj dane</h5>
                </DropdownItem>}
                {this.props.plan.name === "SW Research" && <DropdownItem onClick={() => this.props.hasAccess ? this.toggle('surveyExportModal') : this.props.setPlanRestrictionsModals(SurveyAccess.getName())} tag={"a"}>
                  <p><i className="fas fa-download" /></p> <h5>Eksportuj ankietę</h5>
                </DropdownItem>}
                {checkIsGroupRole('accessReport', user, userGroup) && <DropdownItem className="d-md-none" onClick={() => this.toggle('shareModal')} tag={"a"}>
                  <p><i className="fas fa-share-alt" /></p> <FormattedMessage tagName="h5" id="_.button.share" />
                </DropdownItem>}
                {checkIsGroupRole('accessReport', user, userGroup) && <DropdownItem onClick={() => this.props.hasAccess ? this.toggle('copyModal') : this.props.setPlanRestrictionsModals(SurveyAccess.getName())} tag={"a"}>
                  <p><i className="fas fa-copy"/></p> <FormattedMessage tagName="h5" id="_.button.copy"/>
                </DropdownItem>}
                {!survey.clearResponsesBlockade && checkIsGroupRole('accessReport', user, userGroup) && <DropdownItem onClick={() => this.props.hasAccess ? this.toggle('clearModal') : this.props.setPlanRestrictionsModals(SurveyAccess.getName())} tag={"a"}>
                  <p><i className="fa-solid fa-eraser" /></p> <FormattedMessage tagName="h5" id="_.button.clear" />
                </DropdownItem>}
                {checkIsGroupRole('accessReport', user, userGroup) && <DropdownItem onClick={() => this.toggle('deleteModal')} tag={"a"}>
                  <p><i className="fas fa-trash" /></p> <FormattedMessage tagName="h5" id="_.button.delete" />
                </DropdownItem>}
              </DropdownMenu>
            </Dropdown>
          </li>}
          {this.state.settingsModal && <SurveysModalSettings survey={survey} isOpen={true} toggleModal={() => this.toggle('settingsModal')}/>}
          {this.state.analysisModal && <SurveysModalAnalysis survey={survey} isOpen toggleModal={() => this.toggle('analysisModal')}/>}
          {this.state.copyModal && <SurveysModalCopy survey={survey} isOpen toggleModal={() => this.toggle('copyModal')}/>}
          {!survey.clearResponsesBlockade && <SurveysModalClear survey={survey} isOpen={this.state.clearModal} toggleModal={() => this.toggle('clearModal')} handleResponsesClear={this.props.handleChange}/>}
          {this.state.deleteModal && <SurveysModalDelete survey={survey} isOpen toggleModal={() => this.toggle('deleteModal')} handleDelete={this.props.handleChange}/>}
          {this.state.shareModal && <SurveysModalShare survey={survey} isOpen toggleModal={() => this.toggle('shareModal')} handleDelete={this.props.handleChange} onChange={this.props.handleChange}/>}
          {this.state.surveyExportModal && <SurveyScriptModal survey={survey} isOpen toggleModal={() => this.toggle('surveyExportModal')}/>}
        </ul>
      </React.Fragment>
    )
  }
}

SurveysActions.propTypes = {
  survey: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  swPanelDefaultAccepted: PropTypes.bool.isRequired,
};

function mapStateToProps(state, props) {
  return {
    userGroup: state.user.userGroups,
    user: state.user,
    plan: state.user.userPlan.plan,
    isUserRespondent: state.user.type === 'Respondent',
    noGroup: !Object.keys(state.groups.data.groups).length > 0 && !state.user.userGroup,
    hasAccess: state.user.userPlan.plan.survey.accessDaysAfterCreation === 0 || moment().diff(moment(props.survey.createdAt), 'days') <= state.user.userPlan.plan.survey.accessDaysAfterCreation,
    swPanelDefaultAccepted: 'userPlan' in state.user ? state.user.userPlan.plan.survey.swPanelDefaultAccepted : false,
  }
}


export default connect(mapStateToProps, {setPlanRestrictionsModals})(SurveysActions);
