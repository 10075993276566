import * as React from 'react';
import PropTypes from "prop-types";
import Tooltip from "../../../../components/Tooltip";
import {Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import {FormattedMessage} from "react-intl";
import Button from "../../../../components/Button";
import surveyStructureToDocxConverter from "../../../../utils/converters/surveyStructureToDocxConverter";
import {connect} from "react-redux";
import SurveyScriptButton from "./SurveyScriptButton";
import SurveyScriptRenderer from "./SurveyScriptRenderer";
import {fetchSurvey, setSurvey} from "../data/survey/actions";
import {useEffect} from "react";

function SurveyScriptModal(props) {
  const [script, setScript] = React.useState("");

  const toggleModalOpen = () => {
    props.toggleModal();
  }

  useEffect(() => {
    if (props.survey) {
      props.fetchSurvey({id: props.survey.id});
    }
  }, [])

  return (
      <React.Fragment>
        {(props.isOpen) && <Modal isOpen={true} toggle={toggleModalOpen} size="lg">
          <ModalHeader tag="h2" toggle={toggleModalOpen}>Pobierz strukturę ankiety</ModalHeader>
          <hr className="my-0"/>
          <ModalBody>
            <SurveyScriptRenderer survey={props.surveyObject} script={script}/>
          </ModalBody>
          <ModalFooter>
            <FormattedMessage id="_.button.cancel">{
              (message) =>
                  <Button color="secondary" className="mb-0" onClick={toggleModalOpen}>{message}</Button>
            }</FormattedMessage>
            {props.surveyId && <SurveyScriptButton onScriptLoaded={setScript}/>}
          </ModalFooter>
        </Modal>}
      </React.Fragment>
  );

}

SurveyScriptModal.propTypes = {};

function mapStateToProps(state) {
  return {
    plan: state.user.userPlan.plan,
    structure: state.survey.structure,
    surveyId: state.survey.structure.data.survey.id,
    surveyObject: state.survey.structure.data.survey,
  }
}

export default connect(mapStateToProps, {fetchSurvey})(SurveyScriptModal);