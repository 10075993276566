import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import { connect } from 'react-redux';
import ShareForm from "./ShareForm";
import {addShare} from "../../../reducer/shares/actions";
import PropTypes from "prop-types";

class ShareCreateButton extends Component{
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      saving: false,
      errors: {}
    };

    this.form = React.createRef();
    this.toggleModal = this.toggleModal.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  toggleModal() {
    this.setState({modal: !this.state.modal});
  }

  onSubmit(e, data) {
    this.setState({saving: true});

    this.props.addShare({
      analysis: this.props.analysisId,
      name: data.name,
      password: data.password,
      isSocialShare: data.isSocialShare
    }).then(res => {
      this.setState({modal: false, saving: false})
    }).catch(res => {
      this.setState({
        errors: res.response.data.form.errors,
        saving: false
      })
    });
  }

  render() {
    const modal = <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
      <ModalHeader toggle={this.toggleModal} tag="h2"><FormattedMessage id="view.shareCreateButton.modalHeader" /></ModalHeader>
      <hr className="m-0" />
      <ModalBody>
       <ShareForm onSubmit={this.onSubmit} errors={this.state.errors} ref={this.form}/>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={this.toggleModal}><FormattedMessage id="_.button.cancel" /></Button>
          <LaddaButton
            className="btn btn-primary btn-ladda"
            loading={this.state.saving}
            data-style={ZOOM_OUT}
            onClick={(e) => this.onSubmit(e, this.form.current.state.data)}
          >
            <FormattedMessage id="_.button.save" />
          </LaddaButton>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Button onClick={this.toggleModal} color="primary" className="ml-2"><FormattedMessage id="_.button.add"/></Button>
        {modal}
      </React.Fragment>
    )
  }
}

ShareCreateButton.propTypes = {
  analysisId: PropTypes.number.isRequired
};


export default connect(null, {addShare})(ShareCreateButton);
