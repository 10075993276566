import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {Input} from "reactstrap";
import _ from "lodash";
import {connect} from "react-redux";
import {cityProvinces} from "../../../../SurveyCollector/components/CollectorPanel/Tabs/CollectorPanelForm";
import TextToFiltersConverter from "../../../../../utils/converters/textToFiltersConverter";

const SurveyFilterTextarea = props => {
  const [text, setText] = React.useState(props.filtersText);
  const timerRef = useRef(null);

  React.useEffect(() => {
    setText(props.filtersText);
  }, [props.filtersText]);

  React.useEffect(() => {
    clearTimeout(timerRef.current);

    timerRef.current = setTimeout(() => {
      const converter = new TextToFiltersConverter(props.structure, text);
      const filters = converter.convert();

      props.onChange(filters, text);
    }, 100);
  }, [text]);

  return (
    <Input
      readOnly={props.blocked}F
      type="textarea"
      rows={5}
      value={text}
      onChange={e => setText(e.target.value)}
    />
  );
};

SurveyFilterTextarea.defaultProps = {
  filtersText: '',
  blocked: false,
};

SurveyFilterTextarea.propTypes = {
  onChange: PropTypes.func.isRequired,
  filtersText: PropTypes.string,
  blocked: PropTypes.bool,
};

function mapStateToProps(state) {

  return {
    structure: state.survey.structure
  }
}

export default connect(mapStateToProps)(SurveyFilterTextarea);
