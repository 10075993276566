import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Button} from "reactstrap";
import {FormattedMessage} from "react-intl";
import QuestionListModal from "./components/QuestionListModal";

class ButtonAddQuestionList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal(){
    this.setState({ modal: !this.state.modal })
  }

  render() {
    const {classes, buttonColor, text, ai} = this.props;

    return (
      <React.Fragment>
        <Button className={classes} color={buttonColor} onClick={() => this.toggleModal()}>
          {text && <span>{text}</span>}
          {!text && <FormattedMessage id="questions.buttonAddQuestionList.button"/>}
        </Button>
        {this.state.modal && <QuestionListModal isOpen={this.state.modal} ai={ai} toggle={this.toggleModal} pageId={this.props.pageId} position={this.props.position}/>}
      </React.Fragment>
    )
  }
}

ButtonAddQuestionList.defaultProps = {
  buttonColor: 'primary',
  text: '',
  ai: false
};

ButtonAddQuestionList.propTypes = {
  pageId: PropTypes.number.isRequired,
  position: PropTypes.number.isRequired,
  classes: PropTypes.string,
  buttonColor: PropTypes.string
};

export default ButtonAddQuestionList;