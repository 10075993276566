import React, {Component} from 'react';
import {connect} from "react-redux";

import Sidebar from "./components/Sidebar";
import Topbar from "./components/Topbar";
import Sets from "./components/Sets";

import {fetchTemplates} from 'reducers/globalTemplates/actions';
import {fetchSurvey, reReportSurvey} from "scenes/Survey/Structure/data/survey/actions";
import LoadingSpinner from "components/LoadingSpinner";
import InvitationText from "./components/InvitationText";
import EndingText from "./components/EndingText";
import Redirects from "./components/Redirects";
import {Button, Card, CardBody, CardFooter, CardHeader, Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import ButtonAddQuestionList from "./components/Sets/components/Set/components/Blocks/components/Block/components/Pages/components/Page/components/Questions/components/ButtonAddQuestionList";
import {FormattedMessage} from "react-intl";
import Settings from "../components/Settings";
import SearchInStructure from "./components/Topbar/components/SearchInStructure";
import HideInStructure from "./components/Topbar/components/HideInStructure";
import LanguageEdit from "./components/Topbar/components/LanguageEdit";
import HelpModeIconModal from "../../../components/HelpModeIconModal";
import CreateElementButton from './components/CreateElementButton';
import setTranslatableLangHeader from "utils/setTranslatableLangHeader";
import {toast} from "react-toastify";
import appRoutes from 'appRoutes';
import axios from "axios";
import SurveyTexts from "./components/SurveyTexts";
import InvitationScreensList from "./components/InvitationText/InvitationScreen/invitationScreensList";

class SurveyStructure extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showStructure: false,
      showSideBar: true,
      settingsModal: false,
      showLangsOnMobile: false,
      showFiltersOnMobile: false
    };

    this.main = React.createRef();
    this.handleSideBarRendering = this.handleSideBarRendering.bind(this);
    this.toggle = this.toggle.bind(this);
    this.isAIEnabled = this.isAIEnabled.bind(this);
  }

  componentDidMount() {
    const {survey} = this.props;

    if(!_.isEmpty(survey)){
      if(!localStorage.getItem('translatableLang') || survey.languages.indexOf(axios.defaults.headers.common.translatableLang) < 0){
        localStorage.setItem('translatableLang', survey.defaultLang);
        setTranslatableLangHeader(survey.defaultLang);
      }
    }

    if (localStorage.translatableLang) {
      setTranslatableLangHeader(localStorage.translatableLang);
    }

    this.props.fetchSurvey({
      id: this.props.match.params.survey
    }).then((survey) => {
      if (this.props.hasActiveSurveyBase && !this.props.swPanelDefaultAccepted) {
        this.props.reReportSurvey(this.props.surveyId)
      }

      if(!localStorage.getItem('translatableLang') || survey.languages.indexOf(axios.defaults.headers.common.translatableLang) < 0){
        localStorage.setItem('translatableLang', survey.defaultLang);
        setTranslatableLangHeader(survey.defaultLang);
      }
    }).catch(e => {
      toast.error(e.response.data.errors[0].message);
      this.props.history.push(appRoutes.surveys.list);
    });

    if (this.props.hasActiveSurveyBase && !this.props.swPanelDefaultAccepted) {
      this.props.reReportSurvey(this.props.surveyId)
    }

    this.props.fetchTemplates();

    if (localStorage.structureScrollTop) {
      if (parseInt(localStorage.scrollSurveyId) === parseInt(this.props.match.params.survey)) {
        this.main.current.scrollTo(0, localStorage.structureScrollTop);
      } else {
        localStorage.removeItem('structureScrollTop');
        localStorage.removeItem('scrollSurveyId');
      }
    }
  }

  isAIEnabled() {
    return this.props.plan.survey.aiFunctions;
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (!nextProps.loading && !nextProps.isLoaded) {
      nextProps.fetchSurvey({
        id: nextProps.match.params.survey
      });
    }
  }

  componentWillUnmount() {
    localStorage.scrollSurveyId = this.props.surveyId ;
    localStorage.structureScrollTop = this.main.current.scrollTop;
    localStorage.setItem('translatableLang', 'pl');
    setTranslatableLangHeader('pl');

  }

  handleSideBarRendering() {
    this.setState({
      showSideBar: !this.state.showSideBar
    });
  }

  toggle(property) {
    this.setState({
      [property]: !this.state[property]
    });
  }

  render() {
    const {questions, pages, defaultLang, languages} = this.props;
    const {showStructure, showSideBar, settingsModal, showLangsOnMobile, showFiltersOnMobile} = this.state;

    const modal =
      <Modal size="xl" isOpen={settingsModal} toggle={() => this.toggle('settingsModal')}>
        <ModalHeader toggle={() => this.toggle('settingsModal')}><FormattedMessage tagName="h2" id="survey.settings.modal.header"/></ModalHeader>
        <hr className="my-0" />
        <ModalBody className="px-0 pb-0">
          <Settings />
        </ModalBody>
      </Modal>
    ;

    return (
      <React.Fragment>
        {(!_.isEmpty(questions) || showStructure) && showSideBar && <Sidebar/>}

        <main ref={this.main} id="structure">
          {(!this.props.loading && (!_.isEmpty(questions) || showStructure)) && <Topbar showSideBar={showSideBar} mainRef={this.main.current} handleSideBarRendering={this.handleSideBarRendering}/>}
          {this.props.loading ?
            <LoadingSpinner/>
            :
            <React.Fragment>
              {(_.isEmpty(questions) && !showStructure) && <Card>
                <CardHeader><h2 className="mb-0">Twoja struktura ankiety jest pusta</h2></CardHeader>
                <hr className="m-0" />
                <CardBody>
                  <Row>
                    <Col sm={12} md={this.isAIEnabled() ? 4 : 6} className="text-center">
                      <Card className="border-l orange">
                        <CardHeader>
                          <h2>
                            Nie mam gotowej listy pytań
                            <HelpModeIconModal className="ml-2" modalHeader="Struktura ankiety">
                              <p>Wybierz, jeśli tworzysz ankietę od początku</p>
                            </HelpModeIconModal>
                          </h2>
                        </CardHeader>
                        <hr className="m-0" />
                        <CardBody>
                          <span>Chcę dodać nowe pytania pojedynczo</span>
                        </CardBody>
                        <CardFooter>
                          <Button color="primary" className="w-100 mb-0 py-2" onClick={() => this.setState({showStructure: true})}>Pokaż strukturę ankiety</Button>
                        </CardFooter>
                      </Card>
                    </Col>
                    <Col sm={12} md={this.isAIEnabled() ? 4 : 6} className="text-center">
                      <Card className="border-l green">
                        <CardHeader>
                          <h2>
                            Mam gotową listę pytań
                            <HelpModeIconModal className="ml-2" modalHeader="Lista pytań">
                              <p>Wybierz, jeśli masz zapisane pytania i możesz je szybko skopiować</p>
                            </HelpModeIconModal>
                          </h2>
                        </CardHeader>
                        <hr className="m-0" />
                        <CardBody>
                          <span>Chcę dodać wiele pytań wklejając lub wpisując je do formularza</span>
                        </CardBody>
                        <CardFooter>
                          <ButtonAddQuestionList classes="w-100 py-2 mb-0" pageId={pages[0] ? pages[0].id : null} position={0}/>
                        </CardFooter>
                      </Card>
                    </Col>
                    {this.isAIEnabled() && <Col sm={12} md={4} className="text-center">
                      <Card className="border-l black">
                        <CardHeader>
                          <h2>
                            Mam gotowy kwestionariusz
                            <HelpModeIconModal className="ml-2" modalHeader="Lista pytań">
                              <p>Pamiętaj by plik był w formacie txt. Pamiętaj by ocenić pracę AI po stworzeniu ankiety</p>
                            </HelpModeIconModal>
                          </h2>
                        </CardHeader>
                        <hr className="m-0" />
                        <CardBody>
                          <span>Chcę się napić kawy, a resztą niech zrobi AI.</span>
                        </CardBody>
                        <CardFooter>
                          <ButtonAddQuestionList classes="w-100 py-2 mb-0" buttonColor={'dark'} ai={true} text={'Załącz plik i wygeneruj za pomocą narzędzi AI'} pageId={pages[0] ? pages[0].id : null} position={0}/>
                        </CardFooter>
                      </Card>
                    </Col>}
                  </Row>
                </CardBody>
                </Card>}

              {(!_.isEmpty(questions) || showStructure) && <React.Fragment>
                <InvitationText surveyId={this.props.match.params.survey}/>
                <Sets/>
                <SurveyTexts surveyId={this.props.match.params.survey}/>
                <EndingText surveyId={this.props.match.params.survey}/>
                <Redirects surveyId={this.props.match.params.survey}/>
              </React.Fragment>}
            </React.Fragment>
          }

          <div className="d-lg-none mobile-bottom-nav d-print-none">
            {showFiltersOnMobile && <div className="position-absolute float-right w-100 mb-0" style={{bottom: showLangsOnMobile ? 82 : 44}}>
              <SearchInStructure showOnMobile={true}/>
              <HideInStructure placement="top" mainRef={this.main.current}/>
            </div>}
            {showLangsOnMobile && <div className="position-absolute float-right w-100" style={{bottom: 44}}><LanguageEdit defaultLang={defaultLang} languages={languages} placement="top"/></div>}

            {modal}
            <Button color="" className="mobile-bottom-nav-btn mb-0" onClick={_ => this.main.current.scrollTo(0, 0)}><i className="fas fa-home" /></Button>
            <Button color="" className={"mobile-bottom-nav-btn mb-0" + (showFiltersOnMobile ? " text-primary" : "")} onClick={_ => this.toggle('showFiltersOnMobile')}><i className="fas fa-filter" /></Button>
            <CreateElementButton />
            <Button color="" className="mobile-bottom-nav-btn mb-0" onClick={_ => this.toggle('settingsModal')}><i className="fas fa-cog" /></Button>
            <Button color="" className="mobile-bottom-nav-btn mb-0" onClick={_ => this.toggle('showLangsOnMobile')}><img src={'/dashboard/img/icon/' + (localStorage.translatableLang || localStorage.language) + '.png'} height="20" /></Button>
          </div>
        </main>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    loading: state.survey.structure.loading,
    isLoaded: state.survey.structure.data.survey.id === parseInt(props.match.params.survey),
    survey: state.survey.structure.data.survey,
    surveyId: state.survey.structure.data.survey.id,
    hasActiveSurveyBase: state.survey.structure.data.survey.hasActiveSurveyBase,
    questions: state.survey.structure.data.questions,
    pages: Object.values(state.survey.structure.data.pages),
    defaultLang: state.survey.structure.data.survey.defaultLang,
    languages: state.survey.structure.data.survey.languages,
    swPanelDefaultAccepted: 'userPlan' in state.user ? state.user.userPlan.plan.survey.swPanelDefaultAccepted : false,
    plan: state.user.userPlan.plan,
  }
}

export default connect(mapStateToProps, {fetchSurvey, fetchTemplates, reReportSurvey})(SurveyStructure);