import React from 'react';
import PropTypes from 'prop-types';
import {Alert, Button, Col, FormGroup, Input, Label, Row} from "reactstrap";
import useAfterUpdateEffect from "../../../../../../../utils/useAfterUpdateEffect";
import CollectorsSelect from "../../../../../../../components/Survey/Analysis/CollectorsSelect";
import {connect} from "react-redux";

const CondCollector = props => {

  const [cond, setCond] = React.useState(props.condQuestion);
  const [t, setT] = React.useState('');

  useAfterUpdateEffect(() => {
    props.onChange(cond)
  }, [cond])

  const updateField = (value) => {
    setCond({
      ...cond,
      collector: value
    })
  }

  if(!props.changeable){
    return <span>{t}</span>
  }

  return (
    <div className="w-100">
      <Alert color={'info'}>
        <span>Jeżeli respondent pochodzi z kolektora:</span>
      </Alert>
      <FormGroup row>
        <Col lg={12} className="">
          <CollectorsSelect value={cond.collector ? [cond.collector] : []} isSingle={true} placeholder={"Wybierz kolektor"} survey={props.survey} onChange={updateField} />
        </Col>
      </FormGroup>
    </div>
  );
};

CondCollector.defaultProps = {
  changeable: true,
};

CondCollector.propTypes = {
  changeable: PropTypes.bool,
  onChange: PropTypes.func,
  condQuestion: PropTypes.shape({

  }).isRequired,
};

function mapStateToProps(state) {
  return {
    survey: state.survey.structure.data.survey
  }
}

export default connect(mapStateToProps, {})(CondCollector);
