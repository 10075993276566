import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {Row, Label, Input, Col, Form} from 'reactstrap';
import PropTypes from 'prop-types';
import appRoutes from 'appRoutes';
import Checkbox from "../../../../../../components/Checkbox";

class ShareForm extends Component{

  constructor(props) {
    super(props);
    this.state = {
      data: {
        name : props.share ? props.share.name : '',
        password : props.share ? props.share.password : '',
        guid : props.share ? props.share.guid : null,
        isSocialShare : props.share ? props.share.isSocialShare : false
      }
    };

    this.onChange = this.onChange.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    return props.share && props.share.guid ? {data: {...state.data, guid: props.share.guid}} : null;
  }

  onChange(e) {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value
      }
    });
  }

  render() {
    return (
      <Form onSubmit={() => this.props.onSubmit(e, this.state.data)}>
        <Row>
          <Label for="name" lg={3}><FormattedMessage id="view.shareForm.label.name" /></Label>
          <Col lg={9}>
            <Input type="text" id="name" name="name" value={this.state.data.name} invalid={!!this.props.errors.name} onChange={this.onChange}/>
            {this.props.errors.name && <p className="help-block text-danger">{this.props.errors.name}</p>}
          </Col>
        </Row>
        <Row>
          <Label for="password" lg={3}><FormattedMessage id="view.shareForm.label.password" /></Label>
          <Col lg={9}>
            <Input type="text" id="password" name="password" value={this.state.data.password} invalid={!!this.props.errors.password} onChange={this.onChange}/>
            {this.props.errors.password && <p className="help-block text-danger">{this.props.errors.password}</p>}
          </Col>
        </Row>
        <Row>
          <Label for="link" lg={3}>Social share</Label>
          <Col lg={9}>
            <Checkbox id="copyOptions-filters" tooltip={false} checked={this.state.data.isSocialShare} onClick={() => {
              this.setState({
                ...this.state,
                data: {
                  ...this.state.data,
                  isSocialShare: !this.state.data.isSocialShare
                }
              });
            }} />
          </Col>
        </Row>
        {this.state.data.guid &&
        <Row>
          <Label for="link" lg={3}><FormattedMessage id="view.shareForm.label.link" /></Label>
          <Col lg={9}>
            <Input type="text" id="link" name="link" value={document.location.origin + appRoutes.analysis.shared(this.state.data.guid)} disabled={true}/>
          </Col>
        </Row>}
      </Form>
    )
  }
}

ShareForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  share: PropTypes.object
};

export default ShareForm;
