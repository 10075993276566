import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Col, FormGroup, Input, Label} from "reactstrap";
import Languages from "./components/Languages";
import DefaultLang from "./components/DefaultLang";
import Switch from "components/Switch";
import HelpModeIconTooltip from "components/HelpModeIconTooltip";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import SurveySettingAvailable from "components/PlanRestrictionsModals/modals/SurveySettingAvailable";
import Devices from "./components/Devices";
import {Link} from "react-router-dom";
import Select from "../../../../../componentsReusable/Select";
import InputNumber from "../../../../../components/InputNumber";

class Advanced extends Component {
  constructor(props) {
    super(props);

    this.state = {
      survey: props.survey,
      ipWhiteList: props.survey.ipWhiteList.join(", "),
      ipBlackList: props.survey.ipBlackList.join(", "),
    };

    this.onIpListBlur = this.onIpListBlur.bind(this);
    this.showPlanRestrictionsModalIfNeeded = this.showPlanRestrictionsModalIfNeeded.bind(this);
  }

  onChangeProperty(name, value){
    this.setState({
      survey: {
        ...this.state.survey,
        [name]: value
      }
    });

    this.props.onPropertyChange({
      [name]: value
    });
  }

  onIpListBlur(e) {
    let ips = e.target.value.split(/[ ,;]+/);
    ips = ips.filter(ip => ip.trim().length > 0);

    this.setState({[e.target.name]: ips.join(", ")});
    this.onChangeProperty(e.target.name, ips);
  }

  showPlanRestrictionsModalIfNeeded(property) {
    if (!this.props.surveyPlan[property]) {
      this.props.setPlanRestrictionsModals(SurveySettingAvailable.getName());
    }
  }

  render() {
    const {survey} = this.state;
    const {errors, surveyPlan, plan} = this.props;

    return (
      <React.Fragment>
        <FormGroup row>
          <Label lg={3} className="text-lg-right">
            Pasek postępu
            <HelpModeIconTooltip className="ml-2">Podczas wypełniania ankiety widoczny jest pasek postępu</HelpModeIconTooltip>
          </Label>
          <Col lg={9} className="pt-2">
            <Switch checked={survey.progressBar.visible} onChange={(visible) => {
              this.onChangeProperty('progressBar', {visible})
            }} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label lg={3} className="text-lg-right">
            Tryb ciemny / Dark mode
            <HelpModeIconTooltip className="ml-2">Podczas wypełniania ankiety respondent może włączyć wyświetlanie jej w trybie ciemnym</HelpModeIconTooltip>
          </Label>
          <Col lg={9} className="pt-2">
            <Switch checked={survey.darkModeAvailable} onChange={darkModeAvailable => {
              this.onChangeProperty('darkModeAvailable', darkModeAvailable)
            }} />
          </Col>
        </FormGroup>
        <FormGroup row onClick={() => this.showPlanRestrictionsModalIfNeeded('languages')}>
          <Label lg={3} className={"text-lg-right" + (!surveyPlan.languages ? ' text-black-50' : '') }>
            Wybierz języki
            <Link className="ml-2" to="//ankieteo.pl/instrukcja/wielojezycznosc-ankiet" target="_blank"><i className="fas fa-external-link-alt" /></Link>
            <HelpModeIconTooltip className="ml-2">Wybór języka ankiety</HelpModeIconTooltip>
          </Label>
          <Col lg={9}>
            <Languages disabled={!surveyPlan.languages} defaultLang={survey.defaultLang} onChange={(languages) => this.onChangeProperty('languages', languages)} />
          </Col>
        </FormGroup>
        <FormGroup row onClick={() => this.showPlanRestrictionsModalIfNeeded('languages')}>
          <Label lg={3} className={"text-lg-right" + (!surveyPlan.languages ? ' text-black-50' : '') }>
            Domyślny język
            <HelpModeIconTooltip className="ml-2">Domyślny język w jakim wypełniana jest ankieta</HelpModeIconTooltip>
          </Label>
          <Col lg={9}>
            <DefaultLang disabled={!surveyPlan.languages} availableLanguages={survey.languages} onChange={(defaultLang) => this.onChangeProperty('defaultLang', defaultLang)} />
          </Col>
        </FormGroup>
        {surveyPlan.googleAdsense && <FormGroup row>
          <Label lg={3} className={"text-lg-right"}>
            Reklamy Google Adsense
            <HelpModeIconTooltip className="ml-2">Włącza reklamy Google Adsense w ankiecie</HelpModeIconTooltip>
          </Label>
          <Col lg={9} className="pt-2">
            <Switch stickToProp checked={survey.googleAdsense} onChange={(googleAdsense) => this.onChangeProperty('googleAdsense', googleAdsense)}/>
          </Col>
        </FormGroup>}
        <FormGroup row>
          <Label lg={3} className={"text-lg-right" + (!surveyPlan.reportEnable ? ' text-black-50' : '') }>
            Zgłaszanie błędów
            <HelpModeIconTooltip className="ml-2">Daje możliwość zgłoszenia błędu do ankiety</HelpModeIconTooltip>
          </Label>
          <Col lg={9} className="pt-2">
            <Switch stickToProp checked={survey.reportEnable} onChange={(reportEnable) => {
              surveyPlan.reportEnable ? this.onChangeProperty('reportEnable', reportEnable) : this.props.setPlanRestrictionsModals(SurveySettingAvailable.getName());
            }} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label lg={3} className="text-lg-right">
            Zapis danych twardych
            <HelpModeIconTooltip className="ml-2">Wraz z odpowiedziami zapisywane są twarde dane jak np. IP, urządzenie, przeglądarka itp.</HelpModeIconTooltip>
          </Label>
          <Col lg={9} className="pt-2">
            <Switch checked={survey.hardData} onChange={(hardData) => {
              this.onChangeProperty('hardData', hardData)
            }} />
          </Col>
        </FormGroup>
        {surveyPlan.rememberUnknownRespondent && <FormGroup row>
          <Label lg={3} className="text-lg-right">
            Możliwość kontynuacji ankiety bez tokenu
            <HelpModeIconTooltip className="ml-2">Umożliwia kontynuację ankiety przez zapisanie pliku sesyjnego w przeglądarce</HelpModeIconTooltip>
          </Label>
          <Col lg={9} className="pt-2">
            <Switch checked={survey.rememberUnknownRespondent} onChange={(rememberUnknownRespondent) => {
              this.onChangeProperty('rememberUnknownRespondent', rememberUnknownRespondent)
            }}/>
          </Col>
        </FormGroup>}
        {(surveyPlan.maxResponsesFromSameIp && survey.hardData) && <FormGroup row>
          <Label lg={3} className="text-lg-right">
            Maksymalna liczba wypełnień z jednego IP
            <HelpModeIconTooltip className="ml-2">Z tego samego adresu IP nie będzie możliwości wypełnienia ankiety większej ilości razy niż podana wartość</HelpModeIconTooltip>
          </Label>
          <Col lg={9} className="pt-2">
            <InputNumber min={0} value={survey.maxResponsesFromSameIp} onChange={(value) => this.onChangeProperty('maxResponsesFromSameIp', value)}/>
          </Col>
        </FormGroup>}
        {surveyPlan.insidePanel && <FormGroup row>
          <Label lg={3} className="text-lg-right">
            Inside panel
            <HelpModeIconTooltip className="ml-2">Opis...</HelpModeIconTooltip>
          </Label>
          <Col lg={9} className="pt-2">
            <Switch checked={survey.inside} onChange={(inside) => {
              this.onChangeProperty('inside', inside)
            }}/>
          </Col>
        </FormGroup>}
        {surveyPlan.weights && <FormGroup row>
          <Label lg={3} className="text-lg-right">
            Wagi dla odpowiedzi
            <HelpModeIconTooltip className="ml-2">Możliwość przypisania wagi dla odpowiedzi w pytaniach</HelpModeIconTooltip>
          </Label>
          <Col lg={9} className="pt-2">
            <Switch checked={survey.weights} onChange={(weights) => {
              this.onChangeProperty('weights', weights)
            }}/>
          </Col>
        </FormGroup>}
        {surveyPlan.panelPromoteAvailable && <FormGroup row>
          <Label lg={3} className="text-lg-right">
            Promuj w bazie ankiet i profilu respondenta
            <HelpModeIconTooltip className="ml-2">Ankieta będzie widoczna na początku listy ankiet do wypełnienia</HelpModeIconTooltip>
          </Label>
          <Col lg={9} className="pt-2">
            <Switch checked={survey.panelPromote} onChange={(panelPromote) => {
              this.onChangeProperty('panelPromote', panelPromote)
            }}/>
          </Col>
        </FormGroup>}
        {surveyPlan.quotas && <React.Fragment>
          <FormGroup row>
            <Label lg={3} className="text-lg-right">
              Naliczanie kwot - status ankiety
              <HelpModeIconTooltip className="ml-2">Kwota może być naliczona w momencie wybrania odpowiedzi w pytaniu lub dopiero po wypełnieniu całej ankiety. Pozwala to kwalifikować do kwot tylko pełne wypełnienia lub każdy inny status ankiety.</HelpModeIconTooltip>
            </Label>
            <Col lg={9} className="pt-2">
              <Select
                options={[
                  {
                    label: 'Naliczaj podczas wypełniania wywiadu',
                    value: 1,
                  },
                  {
                    label: 'Naliczaj po zakończeniu wywiadu',
                    value: 2,
                  },
                ]}
                value={survey.quotaNewsQualifiedStatusType}
                onChange={quotaNewsQualifiedStatusType => this.onChangeProperty('quotaNewsQualifiedStatusType', quotaNewsQualifiedStatusType)}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label lg={3} className="text-lg-right">
              Naliczanie kwot - kolejność
              <HelpModeIconTooltip className="ml-2">W przypadku podobnych lub nakładających się na siebie kwot pozwala określić kolejność ich zapełniania. Pomocne zwłaszcza przy skomplikowanych kombinacjach warunków.</HelpModeIconTooltip>
            </Label>
            <Col lg={9} className="pt-2">
              <Select
                options={[
                  {
                    label: 'Naliczaj najpierw kwoty z mniejszym % wypełnienia',
                    value: 1,
                  },
                  {
                    label: 'Naliczaj najpierw kwoty z największą liczbą warunków',
                    value: 2,
                  },
                  {
                    label: 'Naliczaj wszystkie kwoty do których respondent się kwalifikuje',
                    value: 3,
                  },
                ]}
                value={survey.quotaNewsQualifiedQuotasType}
                onChange={quotaNewsQualifiedQuotasType => this.onChangeProperty('quotaNewsQualifiedQuotasType', quotaNewsQualifiedQuotasType)}
              />
            </Col>
          </FormGroup>
        </React.Fragment>}
        {/*<FormGroup row>*/}
        {/*  <Label lg={3} className="text-lg-right">Dostęp tylko z domeny</Label>*/}
        {/*  <Col lg={9} className="d-inline-flex">*/}
        {/*    <Switch className="mt-2 mr-3" checked={survey.domainBlockade} onChange={(domainBlockade) => {*/}
        {/*      this.onChangeProperty('domainBlockade', domainBlockade)*/}
        {/*    }} />*/}
        {/*    {survey.domainBlockade && <React.Fragment>*/}
        {/*      <Input invalid={!!errors.domain} onChange={(e) => this.onChangeProperty('domain', e.target.value)} value={survey.domain || ''} placeholder="domena np. swpanel.pl"/>*/}
        {/*      {errors.domain && <div className="invalid-feedback">{errors.domain.join('; ')}</div>}*/}
        {/*    </React.Fragment>}*/}
        {/*  </Col>*/}
        {/*</FormGroup>*/}
        <FormGroup row className="mb-1">
          <Label lg={3} className="text-lg-right">
            Urządzenia
            <HelpModeIconTooltip className="ml-2">Z jakich urządzeń można wypełniać ankietę</HelpModeIconTooltip>
          </Label>
          <Col lg={9} className="pt-1">
            <Devices desktop={survey.desktop} phone={survey.phone} tablet={survey.tablet} android={survey.android} ios={survey.ios} onChange={(property, value) => {
              this.onChangeProperty(property, value)
            }} />
          </Col>
        </FormGroup>
        {plan.name === 'SW Research' && <FormGroup row className="mb-1">
          <Label lg={3} className="text-lg-right">
            Wymuś uruchomienie w przeglądarce
            <HelpModeIconTooltip className="ml-2">Dotyczy tylko aplikacji mobilnej swpanel. Włącz tę opcję, jeśli chcesz by panelista, korzystający z aplikacji wypełniał ankietę na przeglądarce mobile a nie wersji WebViiew. Ta opcja nie dotyczy osób wypełniających ankiety przez przeglądarki (w wersji web lub mobile).</HelpModeIconTooltip>
          </Label>
          <Col lg={9} className="d-flex align-items-center">
            <Switch onChange={forceOpen => this.onChangeProperty('isForceOpenInBrowser', forceOpen)} checked={this.state.survey.isForceOpenInBrowser}/>
          </Col>
        </FormGroup>}
        {plan.survey.emailCompletedNotification && <FormGroup row className="mb-1">
          <Label lg={3} className="text-lg-right">
            Wysłanie email po wypełnieniu
            <HelpModeIconTooltip className="ml-2">Jeżeli użytkownik pomyślnie zakończy ankiete to na poniżej wskazany adres email</HelpModeIconTooltip>
          </Label>
          <Col lg={9} className="d-flex align-items-center">
            <Switch onChange={emailCompletedNotificationEnabled => this.onChangeProperty('emailCompletedNotificationEnabled', emailCompletedNotificationEnabled)} checked={this.state.survey.emailCompletedNotificationEnabled}/>
          </Col>
        </FormGroup>}
        {plan.survey.emailCompletedNotification && <FormGroup row className="mb-1">
          <Label lg={3} className="text-lg-right">
            Adres email po wypełnieniu
            <HelpModeIconTooltip className="ml-2">Adres email na który pojawią się notyfikacje po </HelpModeIconTooltip>
          </Label>
          <Col lg={9} className="d-flex align-items-center">
            <Input type="text" className="disabled" placeholder="Adres email po wypełnieniu" name="emailCompletedNotificationAddress" value={this.state.survey.emailCompletedNotificationAddress} invalid={!!errors.emailCompletedNotificationAddress} onChange={e => this.onChangeProperty('emailCompletedNotificationAddress', e.target.value)}/>
          </Col>
        </FormGroup>}
        <FormGroup row>
          <Label lg={3} className="text-lg-right">
            Dostęp tylko dla wybranych IP
            <HelpModeIconTooltip className="ml-2">Określa IP z jakich można wypełnić ankietę</HelpModeIconTooltip>
          </Label>
          <Col lg={9}>
            <Input type="textarea" name="ipWhiteList" value={this.state.ipWhiteList} onChange={e => this.setState({ipWhiteList: e.target.value})} onBlur={this.onIpListBlur}/>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label lg={3} className="text-lg-right">
            Zablokuj wybrane IP
            <HelpModeIconTooltip className="ml-2">Określa IP z jakich nie można wypełnić ankiety</HelpModeIconTooltip>
          </Label>
          <Col lg={9}>
            <Input type="textarea" name="ipBlackList" value={this.state.ipBlackList} onChange={e => this.setState({ipBlackList: e.target.value})} onBlur={this.onIpListBlur}/>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label lg={3} className={"text-lg-right" + (!surveyPlan.invitationRedirect ? ' text-black-50' : '') }>
            Przekieruj po rozpoczęciu ankiety
            <HelpModeIconTooltip className="ml-2">Respondent zostanie przekierowany na wskazany adres po naciśnięciu przycisku rozpoczynającego ankietę na ekranie zaproszenia</HelpModeIconTooltip>
          </Label>
          <Col lg={9} className={surveyPlan.invitationRedirect ? "" : "not-active"} onClick={(e) => {
            if (!surveyPlan.invitationRedirect) {
             this.props.setPlanRestrictionsModals(SurveySettingAvailable.getName());
             e.target.blur();
            }
          }}>
            <Input type="text" className="disabled" name="invitationRedirect" value={this.state.survey.invitationRedirect} invalid={!!errors.invitationRedirect} onChange={e => this.onChangeProperty('invitationRedirect', e.target.value)}/>
            {errors.invitationRedirect && <div className="invalid-feedback">{errors.invitationRedirect.join('; ')}</div>}
          </Col>
        </FormGroup>
        {surveyPlan.parameterActions && <FormGroup row>
          <Label lg={3} className={"text-lg-right" + (!surveyPlan.invitationRedirect ? ' text-black-50' : '') }>
            Wymagany parametr przy ponownym wejściu
            <HelpModeIconTooltip className="ml-2">Jeżeli respondent nie będzie miał wypisanego wyżej parametru w linku do ankiety to nie będzie mógł wrócić do ankiety</HelpModeIconTooltip>
          </Label>
          <Col lg={9}>
            <Input type="text" className="disabled" placeholder="" name="reenterParameterNeeded" value={this.state.survey.reenterParameterNeeded} invalid={!!errors.reenterParameterNeeded} onChange={e => this.onChangeProperty('reenterParameterNeeded', e.target.value)}/>
          </Col>
        </FormGroup>}
        {surveyPlan.switcherGuid && <React.Fragment>
          <FormGroup row>
            <Label lg={3} className={"text-lg-right pt-4 mt-1"}>
              Identyfikator switchera
            </Label>
            <Col lg={9}>
              <Input type="text" className="disabled" placeholder="Complete" name="switcherGuid" value={this.state.survey.switcherGuid} invalid={!!errors.switcherGuid} onChange={e => this.onChangeProperty('switcherGuid', e.target.value)}/>
              {errors.switcherGuid && <div className="invalid-feedback">{errors.switcherGuid.join('; ')}</div>}
              <Input type="text" className="disabled" placeholder="Screenout" name="switcherGuidScreenout" value={this.state.survey.switcherGuidScreenout} invalid={!!errors.switcherGuidScreenout} onChange={e => this.onChangeProperty('switcherGuidScreenout', e.target.value)}/>
              {errors.switcherGuidScreenout && <div className="invalid-feedback">{errors.switcherGuidScreenout.join('; ')}</div>}
              <Input type="text" className="disabled" placeholder="Quota" name="switcherGuidQuota" value={this.state.survey.switcherGuidQuota} invalid={!!errors.switcherGuidQuota} onChange={e => this.onChangeProperty('switcherGuidQuota', e.target.value)}/>
              {errors.switcherGuidQuota && <div className="invalid-feedback">{errors.switcherGuidQuota.join('; ')}</div>}
              {(this.state.survey.switcherGuid || this.state.survey.switcherGuidScreenout || this.state.survey.switcherGuidQuota) && <ul className="pl-0">
                <li>Linki dla klienta: </li>
                {this.state.survey.switcherGuid && <li><span>Complete: </span><span>https://swpanel.pl/survey-switcher/{this.state.survey.switcherGuid}</span></li>}
                {this.state.survey.switcherGuidScreenout && <li><span>Screenout: </span><span>https://swpanel.pl/survey-switcher/{this.state.survey.switcherGuidScreenout}</span></li>}
                {this.state.survey.switcherGuidQuota && <li><span>Quota: </span><span>https://swpanel.pl/survey-switcher/{this.state.survey.switcherGuidQuota}</span></li>}
              </ul>}
            </Col>
          </FormGroup>
          {(this.state.survey.switcherGuid || this.state.survey.switcherGuidScreenout || this.state.survey.switcherGuidQuota) && <FormGroup row>
            <Label lg={3} className={"text-lg-right"}>Switcher Cint</Label>
            <Col lg={9} className="d-flex align-items-center">
              <Switch onChange={switcherCint => this.onChangeProperty('switcherCint', switcherCint)} checked={this.state.survey.switcherCint}/>
            </Col>
          </FormGroup>}
        </React.Fragment>}

        <div className="mt-3 text-dark"><small>Do adresu ankiety istnieje możliwość przekazania dowolnego parametru wg schematu:  <i>*adres ankiety*?params[abc]=xyz</i></small></div>
      </React.Fragment>
    );
  }
}

Advanced.propTypes = {
  onPropertyChange: PropTypes.func.isRequired,
  setPlanRestrictionsModals: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  survey: PropTypes.shape({
    languages: PropTypes.array.isRequired,
    progressBar: PropTypes.shape({
      visible: PropTypes.bool.isRequired,
    }).isRequired,
    darkModeAvailable: PropTypes.bool.isRequired,
    reportEnable: PropTypes.bool.isRequired,
    googleAdsense: PropTypes.bool.isRequired,
    hardData: PropTypes.bool.isRequired,
    rememberUnknownRespondent: PropTypes.bool.isRequired,
    maxResponsesFromSameIp: PropTypes.number.isRequired,
    inside: PropTypes.bool.isRequired,
    weights: PropTypes.bool.isRequired,
    domainBlockade: PropTypes.bool.isRequired,
    ipWhiteList: PropTypes.array.isRequired,
    ipBlackList: PropTypes.array.isRequired,
    domain: PropTypes.string,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    surveyPlan: state.user.userPlan.plan.survey,
    plan: state.user.userPlan.plan
  }
}

export default connect(mapStateToProps, {setPlanRestrictionsModals})(Advanced);
