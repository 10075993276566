import React, {Component} from 'react';
import PropTypes from "prop-types";
import PresentationType from "../../../PresentationType";
import SettingBool from "../../../SettingBool";
import RandRows from "../../../RandRows";
import RandColumns from "../../../RandColumns";
import RowsImage from "../../../RowsImage";
import ColumnsImage from "../../../ColumnsImage";
import SettingNumber from "../../../SettingNumber";
import FetchCafeteryPositions from "../../../FetchCafeteryPositions";
import SettingSlider from "../../../SettingSlider";
import {FormattedMessage} from "react-intl";
import { connect } from 'react-redux';
import AnswersColumns from "../../../AnswersColumns";

class MatrixMultiSettings extends Component {

  render() {
    const {question, questionSettingsPlan} = this.props;

    return (
      <React.Fragment>
        <SettingBool question={question} name={'requiredRows'} value={question.requiredRows} icon={<i className="fa-solid fa-grip" />} handleUpdate={this.props.handleUpdate}/>
        <SettingBool question={question} name={'requiredColumns'} value={question.requiredColumns} icon={<i className="fa-solid fa-grip-vertical" />} handleUpdate={this.props.handleUpdate}/>
        <SettingBool question={question} name={'rotateRows'} value={question.rotateRows} icon={<i className="fas fa-random" />} handleUpdate={this.props.handleUpdate}/>
        <SettingBool question={question} name={'rotateColumns'} value={question.rotateColumns} icon={<i className="fak fa-shuffle-vertical" />} handleUpdate={this.props.handleUpdate}/>
          {question.presentationType === 'table' && <SettingBool question={question} name={'transposed'} value={question.transposed} icon={<i className="fa-solid fa-table-pivot" />} handleUpdate={this.props.handleUpdate}/>}
        <RowsImage question={question} handleUpdate={this.props.handleUpdate}/>
        <ColumnsImage question={question} handleUpdate={this.props.handleUpdate}/>
        <SettingBool question={question} name={'reverseColumns'} value={question.reverseColumns} icon={<i className="fa-solid fa-retweet" />} handleUpdate={this.props.handleUpdate}/>
        <SettingNumber question={question} name={'rowsMin'} disabled={!questionSettingsPlan.minMaxCafetery} value={question.rowsMin} max={question.columns.length} icon={<i className="fa-solid fa-circle-chevron-left" />} handleUpdate={this.props.handleUpdate}/>
        <SettingNumber question={question} name={'rowsMax'} disabled={!questionSettingsPlan.minMaxCafetery} value={question.rowsMax} max={question.columns.length} icon={<i className="fa-solid fa-circle-chevron-right" />} handleUpdate={this.props.handleUpdate}/>
        <SettingNumber question={question} name={'columnsMin'} disabled={!questionSettingsPlan.minMaxCafetery} value={question.columnsMin} max={question.rows.length} icon={<i className="fa-solid fa-circle-chevron-down" />} handleUpdate={this.props.handleUpdate}/>
        <SettingNumber question={question} name={'columnsMax'} disabled={!questionSettingsPlan.minMaxCafetery} value={question.columnsMax} max={question.rows.length} icon={<i className="fa-solid fa-circle-chevron-up" />} handleUpdate={this.props.handleUpdate}/>
        <RandRows question={question} disabled={!questionSettingsPlan.randCafetery} handleUpdate={this.props.handleUpdate}/>
        <RandColumns question={question} disabled={!questionSettingsPlan.randCafetery} handleUpdate={this.props.handleUpdate}/>
        {question.presentationType === 'table' && <SettingSlider value={question.firstColumnWidth} question={question} name={'firstColumnWidth'} tooltipText={<FormattedMessage id="_.question.matrix.firstColumnWidth"/>} handleUpdate={this.props.handleUpdate} />}
        <PresentationType question={question} handleUpdate={this.props.handleUpdate}/>
        {question.presentationType === 'carousel' && <AnswersColumns question={question}/>}
        {question.presentationType === 'table' && <SettingBool question={question} name={'equalColumns'} value={question.equalColumns} icon={<i className="fa-solid fa-columns-3" />} handleUpdate={this.props.handleUpdate}/>}
        <SettingBool question={question} name={'labelsWhiteSpaceNoWrap'} value={question.labelsWhiteSpaceNoWrap} icon={<i className="fa-solid fa-minus" />} handleUpdate={this.props.handleUpdate}/>
        <FetchCafeteryPositions question={question} handleUpdate={this.props.handleUpdate}/>
      </React.Fragment>
    )
  }
}

MatrixMultiSettings.propTypes = {
  question: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    questionSettingsPlan: state.user.userPlan.plan.survey.questionSettings
  }
}

export default connect(mapStateToProps)(MatrixMultiSettings);
