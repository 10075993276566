import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {ReactSortable} from "react-sortablejs";
import _ from 'lodash';
import classnames from "classnames";

import {Alert, Col, Row} from "reactstrap";

import QuestionColumn from "./components/Column";
import QuestionColumnNew from "./components/ColumnNew";

import {updateColumn, columnUpdated} from "scenes/Survey/Structure/data/columns/actions";
import {setColumns} from "scenes/Survey/Structure/data/questions/actions";

import './style.scss'
import ColumnsActions from "./components/Actions";

import QuestionFrom from "../FilterSerial/QuestionFrom";
import QuestionTo from "../FilterSerial/QuestionTo";
import ActionAdd from "./components/Actions/components/Add";
import QuestionIterativeTo from "../FilterIterative/QuestionTo";
import ActionCopy
  from "scenes/Survey/Structure/components/Sets/components/Set/components/Blocks/components/Block/components/Pages/components/Page/components/Questions/components/Question/components/Columns/components/Actions/components/Copy";
import ForceCafeteryCenterTextAlign
  from "../../../../../../../../../../../../../../../../../../../../../componentsNew/Survey/Components/Cafetery/forceCafeteryCenterTextAlign";

class Columns extends Component {

  constructor(props) {
    super(props);

    this.state = {
      hideColumns: props.hideInStructure !== 'none' || props.columns.length >= 5,
      checkedColumns: []
    };

    this.onUpdate = this.onUpdate.bind(this);
  }

  static getDerivedStateFromProps(props) {
    if(props.searchInStructure.length > 0) {
      return {hideColumns: false};
    }

    return {};
  }

  onUpdate(evt){
    let {columns} = this.props.question;

    const id = parseInt(evt.item.attributes['data-id'].nodeValue);
    columns.splice(evt.oldIndex, 1);
    columns.splice(evt.newIndex, 0, id);
    this.props.setColumns(this.props.question.id, columns);

    let position = 0;
    this.props.columns.map(column => {
      column.position = position;
      this.props.columnUpdated(column);
      position++;
    });

    this.props.updateColumn({
      id,
      column: {
        position: evt.newIndex
      }
    });
  }

  toggleChecked(columnId, checked){
    let {checkedColumns} = this.state;

    if(checked){
      checkedColumns.push(columnId);
    }else{
      checkedColumns = checkedColumns.filter(id => id !== columnId)
    }

    this.setState({
      checkedColumns
    });
  }

  toggleColumns(){
    this.setState({
      hideColumns: !this.state.hideColumns
    })
  }

  static isFilterSerialEnable(question) {
    const enableTypes = ['matrix', 'matrixMulti', 'matrixDropdown'];

    return enableTypes.includes(question.type);
  }

  render() {
    const {question, searchInStructure, filterSerialFrom, filterSerialTo, filterIterativeTo} = this.props;
    const {checkedColumns} = this.state;
    const columns = _.sortBy(this.props.columns, column => column.position);

    return (
      <Row className="mt-3" role="columns">
        <Col>
          <h4 className="cafetery-header">
            <span onClick={()=> this.toggleColumns()}><FormattedMessage id="question.columns.listGroup.header" /> ({columns.length})</span>
            <div className='d-inline mx-2' key={Math.random()} onClick={()=> this.toggleColumns()}>
              <i className={this.state.hideColumns ? 'fas fa-angle-right' : 'fas fa-angle-down'}/>
            </div>
            {Columns.isFilterSerialEnable(question) &&
            <React.Fragment>
              <div className='d-inline mx-2'>
                <QuestionFrom question={question} filterSerial={filterSerialFrom} filterBy={'columns'}/>
              </div>
              <div className='d-inline mx-2'>
                <QuestionTo question={question} filterSerial={filterSerialTo} filterBy={'columns'}/>
              </div>
            </React.Fragment>}
            <div className='d-inline mx-2'>
              <QuestionIterativeTo question={question} filterIterative={filterIterativeTo} filterBy="columns"/>
            </div>
            {(question.type === 'matrix' || question.type === 'matrixMulti') && <div className='d-inline mx-2'>
              <ForceCafeteryCenterTextAlign question={question} cafeteryType={"columns"} />
            </div>}
          </h4>

          {((!this.state.hideColumns || this.props.hideInStructure === 'print') && columns.length > 0) && <div className="ml-lg-4">
            <ReactSortable
              list={columns}
              setList={() => {}}
              group={{
                name: 'columns-' + question.id,
              }}
              tag="ul"
              onUpdate={this.onUpdate}
              animation={100}
              filter={".sortable-ignore"}
              draggable={".sortable-draggable"}
              handle={".handler-drag-position"}
              className={classnames({
                'columns-list d-print-block list-group': true,
                'd-none': this.state.hideColumns,
              })}
            >
              {columns.map((column, position) => {
                return (
                  <QuestionColumn
                    checked={checkedColumns.indexOf(column.id) >= 0}
                    position={column.position}
                    column={column}
                    question={question}
                    handlerChecked={(checked) => this.toggleChecked(column.id, checked)}
                    searchInStructure={searchInStructure}
                  />
                )
              })}
            </ReactSortable>
            <QuestionColumnNew autoFocus={false} position={columns.length} question={question} />
          </div>}

          {columns.length === 0 && <Alert color="warning">
            <p>To pytanie nie zostanie wyświetlone, ponieważ nie ma żadnej kolumny</p>
            <ActionAdd question={question} />
            <span className="pl-2"><ActionCopy question={question} /></span>
          </Alert>}

          {(columns.length > 0 && !this.state.hideColumns) && <ColumnsActions checkedColumns={checkedColumns} columns={columns} question={question} onCheckAll={() => {
            this.setState({
              checkedColumns: checkedColumns.length >= columns.length ? [] : columns.map(column => column.id)
            })
          }}/>}

        </Col>
      </Row>
    )
  }
}

Columns.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    columns: PropTypes.array.isRequired,
  }).isRequired
};

function mapStateToProps(state, props) {
  return {
    columns: state.survey.structure.data.columns ? _.map(props.question.columns, id => state.survey.structure.data.columns[id]) : [],
    searchInStructure: state.survey.structure.searchInStructure,
    hideInStructure: state.survey.structure.hideInStructure,
    filterSerialFrom: _.filter(state.survey.structure.data.filterSerial, filter => filter.questionFrom && filter.questionFrom.question === props.question.id && filter.questionFrom.filterBy === 'columns'),
    filterSerialTo: _.filter(state.survey.structure.data.filterSerial, filter => filter.questionTo && filter.questionTo.question === props.question.id && filter.questionTo.filterBy === 'columns'),
    filterIterativeTo: _.filter(state.survey.structure.data.filterIterative, filter => _.filter(filter.questionsTo, questionTo => questionTo.question === props.question.id && questionTo.filterBy === 'columns').length > 0)
  }
}

export default connect(mapStateToProps, {setColumns, updateColumn, columnUpdated})(Columns);