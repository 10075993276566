import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import CreateForm from "components/Survey/Analysis/CreateForm";
import ExportForm from "components/Survey/Analysis/ExportForm";

class SurveysModalAnalysis extends Component {

	constructor(props) {
		super(props);

		this.state = {
			creating: false,
			analysis: undefined,
			script: ""
		};

		this.analysisForm = React.createRef();
		this.exportForm = React.createRef();
		this.setScript = this.setScript.bind(this);
	}

	setScript(script) {
		this.setState({script})
	}

	render() {
		const {survey} = this.props;

		return (
			<Modal isOpen={this.props.isOpen} toggle={this.props.toggleModal} size="xl">
				<ModalHeader toggle={this.props.toggleModal} tag="h2"><FormattedMessage
					id="surveys.listItems.modalAnalysis.modalHeader"
				/></ModalHeader>
				<hr className="my-0"/>
				<ModalBody>
					<CreateForm
						survey={survey}
						ref={this.analysisForm}
						onCreating={() => this.setState({creating: true})}
						onCreated={(analysis) => {
							this.setState({
								creating: false,
								analysis,
							}, () => {
								this.exportForm.current.onSubmit();
							});

						}}
						onError={() => this.setState({creating: false})}
					/>

					<hr/>

					<ExportForm
						analysis={this.state.analysis} ref={this.exportForm} deleteAfterExport={true}
						setLoading={creating => this.setState({creating})} onEnd={() => this.props.toggleModal()}
					/>
				</ModalBody>
				<ModalFooter>
					<FormattedMessage id="_.button.cancel">{
						(message) =>
							<Button color="secondary" onClick={this.props.toggleModal}>{message}</Button>
					}</FormattedMessage>
					<FormattedMessage id="_.button.download">{
						(message) => <LaddaButton
							className="btn btn-primary btn-ladda"
							loading={this.state.creating}
							data-style={ZOOM_OUT}
							onClick={(e) => this.analysisForm.current.onSubmit(e)}
						>
							{message}
						</LaddaButton>
					}</FormattedMessage>
				</ModalFooter>
			</Modal>
		)
	}
}

SurveysModalAnalysis.propTypes = {
	survey: PropTypes.shape({
		id: PropTypes.number.isRequired,
	}).isRequired,
	isOpen: PropTypes.bool.isRequired,
	toggleModal: PropTypes.func.isRequired
};

function mapStateToProps(state) {
	return {
		exportTypes: state.user.userPlan.plan.analysis.exportTypes,
	}
}

export default connect(mapStateToProps, null, null, {forwardRef: true})(SurveysModalAnalysis);
