import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SurveysCategoryRemove from "./SurveysCategoryRemove";
import SurveysCategorySurveysList from "./SurveysCategorySurveysList";
import {Input} from "reactstrap";
import api from "../../api";
import {isMobile} from "react-device-detect";
import SurveyUnassignedCategoryMove from "./SurveyUnassignedCategoryMove";

const SurveysCategoryName = ({surveysCategory, onChanged}) => {
  const [editing, setEditing] = React.useState(false);
  const [name, setName] = React.useState(surveysCategory ? surveysCategory.name : '');
  const [saving, setSaving] = React.useState(false);

  const save = () => {
    setSaving(true);

    api.surveysCategories.patch({
      id: surveysCategory.id,
      name,
    })
      .then(data => {
        setEditing(false);
        onChanged(name);
      })
      .finally(() => {
        setSaving(false);
      })
  }

  if(editing){
    return <Input
        autoFocus
        size="sm"
        readOnly={saving}
        className="mb-0 p-0"
        placeholder="Wpisz nazwę..."
        onChange={e => setName(e.target.value)}
        onBlur={() => {
          setName(surveysCategory ? surveysCategory.name : '');
          setEditing(false);
        }}
        onKeyDown={e => {
          switch(e.key){
            case 'Enter': {
              save();
              break;
            }
            case 'Escape': {
              setName(surveysCategory ? surveysCategory.name : '');
              setEditing(false);
              break;
            }
          }
        }}
        value={name}
      />
  }

  return <span onDoubleClick={() => surveysCategory && setEditing(true)}>{surveysCategory ? surveysCategory.name : '-- Nieprzypisane --'}</span>
}

const SurveysCategory = props => {
  const {surveysCategory} = props;
  const [surveysCollapsed, setSurveysCollapsed] = React.useState(props.surveysCollapsed);

  React.useEffect(() => {
    setSurveysCollapsed(props.collapsed);
  }, [props.collapsed]);

  return (
    <React.Fragment>
      {!props.hideHeader && <div className="d-flex flex-grow-1 flex-row flex-nowrap justify-content-between align-items-center">
        <div className="d-flex flex-row flex-nowrap align-items-center pointer">
          {(!props.sortableDisabled && surveysCategory) ? <i className={classnames({
            'handler-sortable fas fa-grip-vertical mr-2 visible-visibilitiable': true,
            'd-none': isMobile,
          })} style={{cursor: 'move'}}/> : <div className={classnames({'d-none': isMobile})} style={{width: '18px'}} />}

          <i className={classnames({
            'fas pr-2 pointer': true,
            'fa-chevron-right': !surveysCollapsed,
            'fa-chevron-down': surveysCollapsed,
          })} onClick={() => setSurveysCollapsed(!surveysCollapsed)} />

          <div className="d-flex flex-row flex-nowrap" onClick={() => setSurveysCollapsed(!surveysCollapsed)}>
            <SurveysCategoryName
              surveysCategory={surveysCategory}
              onChanged={name => props.onChangeName(name)}
            />
            <span className="ml-2">({props.surveys.length})</span>
          </div>
        </div>

        {props.onRemoved && <SurveysCategoryRemove
          surveysCategory={surveysCategory}
          onRemoved={props.onRemoved}
        />}
        {props.unassignedCategory && <SurveyUnassignedCategoryMove />}
      </div>}

      {surveysCollapsed && <SurveysCategorySurveysList
        sortableDisabled={props.sortableDisabled}
        surveysCategory={surveysCategory}
        surveys={props.surveys}
        showCheckboxes={props.showCheckboxes}
        onChecked={(surveyId) => props.onChecked(surveyId)}
        onEndSurveys={evt => props.onEndSurveys(evt)}
        onChange={surveyId => props.onChange(surveyId)}
      />}
    </React.Fragment>
  );
};

SurveysCategory.defaultProps = {
  surveysCollapsed: false,
  hideHeader: false,
  unassignedCategory: false,
};

SurveysCategory.propTypes = {
  unassignedCategory: PropTypes.bool,
  surveysCategory: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  surveys: PropTypes.array.isRequired,
  onChangeName: PropTypes.func,
  onRemoved: PropTypes.func,
  onEndSurveys: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  sortableDisabled: PropTypes.bool.isRequired,
  surveysCollapsed: PropTypes.bool,
  hideHeader: PropTypes.bool,
};

export default SurveysCategory;