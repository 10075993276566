import * as React from 'react';
import {Button, Card, CardBody, CardHeader, Col, Input, Row, Tooltip} from "reactstrap";
import {connect} from "react-redux";
import {useEffect} from "react";
import api from "../../../api";
import Select from "react-select";

function ChooseInvitationScreen(props) {
	const {survey, plan, collector, onChange} = props;
	let split = window.location.pathname.split('/');
	const allowToChange = plan.survey.invitationScreenNumber > 1;
	const [invitationScreens, setInvitationScreens] = React.useState([]);
	const [surveyId, setSurveyId] = React.useState(split[2])
	useEffect(() => {
		if (allowToChange) {
			api.survey.get.invitationScreens(surveyId).then((response) => {
				setInvitationScreens(response.data)
			})
		}
	}, [])

	const getOptions = () => {
		let opt = invitationScreens.map((screen) => {
			return {
				value: screen.id,
				label: screen.name
			}
		});
		opt.unshift({
			value: undefined,
			label: "Domyślny"
		})

		return opt;
	}

	const options = getOptions();

	return <React.Fragment>
		{allowToChange && options.length > 0 && <Row>
			<Col className="d-flex align-items-md-stretch" xs={12} lg={4}>
				<Card className="w-100">
					<CardHeader className="text-left">
						<h2>Ekran zaproszenia:</h2>
					</CardHeader>
					<CardBody>
						<Select
							value={options.find((entry) => entry.value === collector.invitationScreen)}
							clearable={false}
							options={options}
							onChange={(option) => {
								onChange('invitationScreen', option.value ? option.value : null)
							}}
						/>
					</CardBody>
				</Card>
			</Col>
		</Row>}
	</React.Fragment>
}

function mapStateToProps(state) {
	return {
		survey: state.survey,
		plan: state.user.userPlan.plan
	}
}

export default connect(mapStateToProps)(ChooseInvitationScreen);