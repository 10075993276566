import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {ReactSortable} from "react-sortablejs";
import _ from 'lodash';
import classnames from "classnames";

import {Alert, Col, Row} from "reactstrap";

import QuestionAnswer from "./components/Answer";
import QuestionAnswerNew from "./components/AnswerNew";



import {updateAnswer, answerUpdated} from "scenes/Survey/Structure/data/answers/actions";
import {setAnswers} from "scenes/Survey/Structure/data/questions/actions";

import './style.scss'
import AnswersActions from "./components/Actions";
import QuestionFrom from "../FilterSerial/QuestionFrom";
import QuestionTo from "../FilterSerial/QuestionTo";
import ActionAdd from "./components/Actions/components/Add";
import QuestionIterativeFrom from "../FilterIterative/QuestionFrom";
import QuestionIterativeTo from "../FilterIterative/QuestionTo";
import HeatMap from "./components/HeatMap";
import ActionCopy
  from "scenes/Survey/Structure/components/Sets/components/Set/components/Blocks/components/Block/components/Pages/components/Page/components/Questions/components/Question/components/Answers/components/Actions/components/Copy";
import ForceCafeteryCenterTextAlign
  from "../../../../../../../../../../../../../../../../../../../../../componentsNew/Survey/Components/Cafetery/forceCafeteryCenterTextAlign";

class Answers extends Component {

  constructor(props) {
    super(props);

    this.state = {
      hideAnswers: props.hideInStructure !== 'none' || props.answers.length >= 5,
      checkedAnswers: []
    };

    this.onUpdate = this.onUpdate.bind(this);
    this.toggleAnswers = this.toggleAnswers.bind(this);
  }

  static getDerivedStateFromProps(props) {
    if(props.searchInStructure.length > 0) {
      return {hideAnswers: false};
    }

    return {};
  }

  onUpdate(evt){
    let {answers} = this.props.question;

    const id = parseInt(evt.item.attributes['data-id'].nodeValue);
    answers.splice(evt.oldIndex, 1);
    answers.splice(evt.newIndex, 0, id);
    this.props.setAnswers(this.props.question.id, answers);

    let position = 0;
    this.props.answers.map(answer => {
      answer.position = position;
      this.props.answerUpdated(answer);
      position++;
    });

    this.props.updateAnswer({
      id,
      answer: {
        position: evt.newIndex
      }
    });
  }

  toggleChecked(answerId, checked){
    let {checkedAnswers} = this.state;

    if(checked){
      checkedAnswers.push(answerId);
    }else{
      checkedAnswers = checkedAnswers.filter(id => id !== answerId)
    }

    this.setState({
      checkedAnswers
    });
  }

  toggleAnswers(){
    this.setState({
      hideAnswers: !this.state.hideAnswers
    })
  }

  static isFilterIterativeFromEnable(question) {
    const enableTypes = ['single'];

    return enableTypes.includes(question.type);
  }

  static isFilterSerialFromEnable(question) {
    const enableTypes = ['multi', 'ranked', 'matrixDropdown'];

    return enableTypes.includes(question.type);
  }

  static isFilterSerialToEnable(question) {
    const enableTypes = ['single', 'multi', 'ranked', 'conjoint', 'matrixDropdown', 'numericMulti'];

    return enableTypes.includes(question.type);
  }

  render() {
    const {question, searchInStructure, filterSerialFrom, filterSerialTo, filterIterativeFrom, filterIterativeTo} = this.props;
    const {checkedAnswers} = this.state;
    let answers = _.sortBy(this.props.answers, answer => answer.position);

    return (
      <Row className="mt-3" role="answers">
        <Col>
          <h4 className="cafetery-header">
            <span onClick={this.toggleAnswers}><FormattedMessage id="question.answers.listGroup.header" /> ({answers.length})</span>
            <div className='d-inline mx-2' key={Math.random()} onClick={this.toggleAnswers}>
              <i className={this.state.hideAnswers ? 'fas fa-angle-right' : 'fas fa-angle-down'}/>
            </div>
            {Answers.isFilterSerialFromEnable(question) &&
              <div className='d-inline mx-2'>
                <QuestionFrom question={question} filterSerial={filterSerialFrom} filterBy="answers"/>
              </div>}
            {Answers.isFilterSerialToEnable(question) &&
              <div className='d-inline mx-2'>
                <QuestionTo question={question} filterSerial={filterSerialTo} filterBy="answers"/>
              </div>}
            {Answers.isFilterIterativeFromEnable(question) &&<div className='d-inline mx-2'>
              <QuestionIterativeFrom question={question} filterIterative={filterIterativeFrom}/>
            </div>}
            <div className='d-inline mx-2'>
              <QuestionIterativeTo question={question} filterIterative={filterIterativeTo}/>
            </div>
            {(question.type === 'single' || question.type === 'multi') && <div className='d-inline mx-2'>
              <ForceCafeteryCenterTextAlign question={question} cafeteryType={"answers"} />
            </div>}
          </h4>

          {((!this.state.hideAnswers || this.props.hideInStructure === 'print') && answers.length > 0) && <div className="ml-lg-4">
            <ReactSortable
              list={answers}
              setList={() => {}}
              group={{
                name: 'answers-' + question.id,
              }}
              tag="ul"
              onUpdate={this.onUpdate}
              animation={100}
              filter={".sortable-ignore"}
              draggable={".sortable-draggable"}
              handle={".handler-drag-position"}
              className={classnames({
                'answers-list d-print-block list-group': true,
                'd-none': this.state.hideAnswers,
              })}
            >
              {answers.map((answer, position) => {
                return (
                  <QuestionAnswer
                    checked={checkedAnswers.indexOf(answer.id) >= 0}
                    position={answer.position}
                    answer={answer}
                    question={question}
                    handlerChecked={(checked) => this.toggleChecked(answer.id, checked)}
                    searchInStructure={searchInStructure}
                  />
                )
              })}
            </ReactSortable>
            <QuestionAnswerNew autoFocus={false} position={answers.length} question={question} />
          </div>}

          {answers.length === 0 && <Alert color="warning">
            <p>To pytanie nie zostanie wyświetlone, ponieważ nie ma żadnej odpowiedzi</p>
            <ActionAdd question={question} />
            <span className="pl-2"><ActionCopy question={question}/></span>
          </Alert>}

          {(answers.length > 0 && !this.state.hideAnswers) && <AnswersActions checkedAnswers={checkedAnswers} answers={answers} question={question} onCheckAll={() => {
            this.setState({
              checkedAnswers: checkedAnswers.length >= answers.length ? [] : answers.map(answer => answer.id)
            })
          }}/>}

          {(question.type === 'multi' && question.presentationType === 'heatMap' && answers.length > 0) && <HeatMap question={question} answers={answers} />}

        </Col>
      </Row>
    )
  }
}

Answers.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    answers: PropTypes.array.isRequired,
    answersImage: PropTypes.bool,
  }).isRequired
};

function mapStateToProps(state, props) {
  return {
    answers: state.survey.structure.data.answers ? _.map(props.question.answers, id => state.survey.structure.data.answers[id]) : [],
    searchInStructure: state.survey.structure.searchInStructure,
    hideInStructure: state.survey.structure.hideInStructure,
    filterSerialFrom: _.filter(state.survey.structure.data.filterSerial, filter => filter.questionFrom && filter.questionFrom.question === props.question.id),
    filterSerialTo: _.filter(state.survey.structure.data.filterSerial, filter => filter.questionTo && filter.questionTo.question === props.question.id),
    filterIterativeFrom: _.filter(state.survey.structure.data.filterIterative, filter => filter.questionFrom ? (filter.questionFrom.question === props.question.id && filter.questionFrom.filterBy === 'answers') : false),
    filterIterativeTo: _.filter(state.survey.structure.data.filterIterative, filter => _.filter(filter.questionsTo, questionTo => questionTo.question === props.question.id && questionTo.filterBy === 'answers').length > 0)
  }
}

export default connect(mapStateToProps, { updateAnswer, setAnswers, answerUpdated })(Answers);