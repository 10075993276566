import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Link, Route, Switch} from "react-router-dom";
import SurveyWrap from "./components/SurveyWrap";
import appRoutes from "appRoutes";
import {Tooltip} from "reactstrap";
import {updateProfile} from "reducers/user/actions";
import moment from "moment";
import DarkMode from "../../../../componentsNew/DarkMode";
import PanelCurrentActiveUsers from "../../../PanelCurrentActiveUsers";
import classnames from "classnames";
import SurveyAIRateModal from "./components/surveyAIRateModal";

class NavSettings extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      helpModeTooltip: false,
      planTooltip: false
    };

    this.toggle = this.toggle.bind(this);
    this.onHelpModeChange = this.onHelpModeChange.bind(this);
  }

  toggle(property) {
    this.setState({[property]: !this.state[property]})
  }

  onHelpModeChange() {
    const formData = new FormData();
    formData.append('helpMode', !this.props.helpMode);

    this.props.updateProfile(formData)
  }

  render() {
    const {userPlan, helpMode, loaded, survey} = this.props;
    const warningExpirationDate = moment().add(30, 'd');
    const warningExpiration = warningExpirationDate.isSameOrAfter(moment(userPlan.expireDate))

    return (
      <div className="nav-settings">
        <Switch>
          <Route location={location} path={appRoutes.survey.main()} children={<SurveyWrap />} />
        </Switch>
        {this.props.userPlan.plan.survey.aiFunctions && survey.surveyAI && survey.surveyAI.isCreatedByAI && <span class={"ml-3"}>
          <SurveyAIRateModal />
        </span>}

        {(loaded && userPlan && userPlan.plan) && <div className="d-flex float-right justify-content-end">
          <ul className="float-right mb-0 sub-nav-settings">
            {(userPlan.plan && userPlan.plan.panelActiveUsers) && <li className="d-inline-block mr-4" style={{maxHeight: '20px'}}><PanelCurrentActiveUsers/></li>}
            <li className="d-inline-block" style={{maxHeight: '20px'}}><DarkMode /></li>
            <li className="overflow-hidden d-inline-block text-right"><a href="//ankieteo.pl/instrukcja" className="mr-5" target="_blank">Pomoc: Instrukcja</a></li>
            {(userPlan.plan && userPlan.plan.demo) && <li className="overflow-hidden d-inline-block text-right mr-3"><Link to={appRoutes.users.plan}>WYBIERZ PLAN</Link></li>}
            <li className="overflow-hidden d-inline-block text-right">Nazwa planu: <span id="planTooltip" className="px-1"><Link to={appRoutes.users.plan}>{(userPlan.plan && userPlan.plan.demo) && 'DEMO '}{userPlan.plan.name} <i className="fas fa-plus text-success" /> </Link></span></li>
            <li className={classnames({
              "overflow-hidden d-inline-block text-right": true,
              "text-red": warningExpiration
            })}>{userPlan.isSubscribed ? "Kolejna płatność" : "Ważny do"}: <span className={classnames({
              "pt-1": true,
              "text-red": warningExpiration
            })}>{moment(userPlan.expireDate).format('YYYY-MM-DD')}</span></li>
          </ul>

          <Tooltip
            delay={0}
            placement="bottom"
            isOpen={this.state.planTooltip}
            target={'planTooltip'}
            toggle={() => this.toggle('planTooltip')}
          >
            {(userPlan.plan && userPlan.plan.demo) ? 'Tryb Demo posiada ograniczenia funkcjonalności. Napisz do nas, jeśli chcesz przetestować jeden z planów płatnych.' : 'Kliknij aby zmienić swój plan'}
          </Tooltip>

          <i id="helpModeIcon" className={"fas fa-life-ring ml-2" + (helpMode ? " text-primary" : " text-secondary")} onClick={this.onHelpModeChange}/>
          <Tooltip
            delay={0}
            placement="bottom"
            isOpen={this.state.helpModeTooltip}
            target={'helpModeIcon'}
            toggle={() => this.toggle('helpModeTooltip')}
          >
            Tryb pomocy - rozszerza opisy i wyświetla dodatkowe ikony z podpowiedziami
          </Tooltip>
        </div>}
      </div>
    );
  }
}

NavSettings.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
  userPlan: PropTypes.shape({
    plan: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    expireDate: PropTypes.string,
  })
};

function mapStateToProps(state) {
  return {
    loaded: Number.isInteger(state.user.id),
    userPlan: state.user.userPlan,
    helpMode: state.user.helpMode,
    survey: state.survey.structure.data.survey,
  }
}

export default connect(mapStateToProps, {updateProfile})(NavSettings);
