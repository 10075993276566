import React, {Component} from 'react';
import {
	blockSettingsMapper, cafeterySettingsMapper,
	pageSettingsMapper,
	questionSettingsMapper,
	setBlockSettingsMapper
} from "../QuestionListModal";
import {FormattedMessage} from "react-intl";

class Instruction extends Component {
	render() {
		return (
			<div class={"row"}>
				<div class={"col-12 col-lg-4"}>
					<h2>{"PODSTAWOWE INFORMACJE"}</h2>

					<p>{"Znaczniku elementów umieszczamy w między znakami {$ $} np."}</p>
					<p>{"{$page$}"}</p>
					<p>{"{$blok$}"}</p>
					<p>{"{$zestaw$}"}</p>

					<p>{"Jeśli chcemy umieścić pytanie należy wstawić typ pytania wraz z ewentualnym typem prezentacji np. {single}{$single:checkboxes$}"}"</p>
					<p>{"{$matrix$}"}</p>
					<p>
						<h2
							class={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}
						>Uwaga:</h2>{" pytanie jednokrotnego wyboru oraz pytanie otwarte nie wymaga deklaracji {$single$} {$open$}. System rozpozna treść pytania i listę odpowiedzi."}"
					</p>
					<p class={"mb-0"}>{"{single} {$single:checkboxes$} P1"}</p>
					<p class={"mb-0"}>{"a"}</p>
					<p>{"b"}</p>
					<p class={"mb-0"}>{"daje taki sam efekt jak:P1"}</p>
					<p class={"mb-0"}>{"a"}</p>
					<p class={"mb-0"}>{"b"}</p>

					<p>{"Gdy chcemy dodać kolejne ustawienie pytania - należy dodać w tej samej linii kolejny znacznik np."}</p>
					<p>{"{$single:checkboxes$}{$rotateAnswers$}"}</p>

					<p>{"Jeśli chcemy podać wartość elementu np. własną treść instrukcji – wpisujemy tę wartość po dwukropku – przykład:"}</p>
					<p>{"{$ins:Moja instrukcja$}"}</p>


					<h2>{"Odpowiedzi, Wiersze i Kolumny, Dyferencjał"}</h2>
					<p>{"Aby utworzyć listę pytań – wpisujemy je pod treścią pytania jedno pod drugim"}</p>
					<p>{"Aby dodać wiersze (dot. Matryce, karuzele) - przed listą wierszy dodajemy {% rows %}"}</p>

					<p>{"Aby dodać kolumny (dot. Matryce, karuzele) - przed listą wierszy dodajemy {% rows %}"}</p>
					<p>{"Aby dodać opisy punktów dyferencjału – wpisujemy je jeden pod drugim oddzielając je znakiem |"}</p>

					<h2 class={"mb-0"}>{"PRZYKŁAD"}</h2>
					<p class={"mb-0"}>{"{$zestaw$}"}</p>
					<p class={"mb-0"}>{"{$blok$}"}</p>
					<p class={"mb-0"}>{"{$page$}{$rotate$}"}</p>
					<p class={"mb-0"}>{"{$single:checkboxes$}{$rotateAnswers$}{$columns:1$}Nazwa pytania"}</p>
					<p class={"mb-0"}>{"{$open$}{$openNotRequired$}Odpowiedź pierwsza"}</p>
					<p class={"mb-0"}>{"{$rotateExcluded$}Odpowiedź druga"}</p>
					<p class={"mb-0"}>{"{% excludings %}"}</p>
					<p class={"mb-0"}>{"Odpowiedź wykluczająca"}</p>
					<p class={"mb-0"}>{"{$matrix:table$}{$ins:Instrukcja$}{$insMob:Instrukcja mobilna$}{$rotateColumns$}Matryca"}</p>
					<p class={"mb-0"}>{"{% rows %}"}</p>
					<p class={"mb-0"}>{"Wiersz 1"}</p>
					<p class={"mb-0"}>{"{$excludeRequired$}Wiersz 2"}</p>
					<p class={"mb-0"}>{"{% columns %}"}</p>
					<p class={"mb-0"}>{"Kolumna 1"}</p>
					<p>{"{$open$}Kolumna 2"}</p>

					<h2>{"OPIS ZMIENNYCH"}</h2>

					<h2>{"Ustawienia zestawów, bloków, stron"}</h2>
					<p><h2
						class={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}
					>{"{$zestaw:Nazwa zestawu$}"}</h2>{" Rozpoczyna nowy zestaw"}</p>
					<p><h2
						class={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}
					>{"{$rotate$}"}</h2>{" Rotowanie zestawów, bloków, stron, pytań (ustawienie ogólne)"}</p>
					<p><h2
						class={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}
					>{"{$rand$}"}</h2>{" Losowanie zestawów, bloków, stron, pytań (ustawienie ogólne)"}</p>
					<p><h2
						class={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}
					>{"{$disabled$}"}</h2>{" Ukrywanie elementów ankiety (np. odpowiedzi, pytań)"}</p>
					<p><h2
						class={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}
					>{"{$randThis$}"}</h2>{" Losowanie elementu ankiety np. pytania (ustawienie elementu, który ma być losowany)"}
					</p>
					<p><h2
						class={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}
					>{"{$rotateThis$}"}</h2>{" Rotowanie elementu ankiety np. bloku (rotowanie elementu, który ma być rotowany)"}
					</p>

					<p><h2
						class={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}
					>{"{$randPriority$}"}</h2>{" Jaki jest priorytet losowania elementu - ustaw po znaku :"}</p>
					<p><h2
						class={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}
					>{"{$blok:Nazwa bloku$}"}</h2>{" Rozpoczyna nowy blok i nadaje nazwę Nazwa bloku"}</p>
					<p><h2
						class={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}
					>{"{$displayName$}"}</h2>{" Wyświetlanie nazwy bloku"}</p>
					<p><h2 class={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{$page$}"}</h2>{""}</p>
					<p><h2
						class={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}
					>{"{$timeMin$}"}</h2>{" Minimalny czas na stronie (w sek.) - ustaw po znaku :"}</p>
					<p><h2
						class={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}
					>{"{$timeMax$}"}</h2>{" Maksymalny czas na stronie (w sek.) - ustaw po znaku :"}</p>
				</div>
				<div class={"col-12 col-lg-4"}>

					<h2>{"Ustawienia pytań:"}</h2>
					<p><h2
						className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}
					>{"{%typPytania:typPrezentacji%}"}</h2>{" Typ prezentacji nie jest wymagany"}</p>
					<h2>Typy pytań:</h2>
					<p>{"{$single$} lub bez deklaracji, {$multi$}, {$ranked$}, {$numeric:slider$}, {$numeric$}, {$open$},{$multiOpen$}, {$numeric$}, {$numericMulti$}, {$matrix:table$}, {$matrixMulti:table$}, {$matrix:carousel$},{$matrixMulti:carousel$}, {$matrixDropdown:table$}, {$differential:buttons$}, {$basket$}, {$numericMulti:psm$},{$open:email$}, {$open:phone$}, {$multiOpen:datetime$}"}"</p>


					<h2>{"Typy prezentacji listy odpowedzi:"}</h2>
					<p class={"mb-0"}>{"radiobuttony/checkboxy: checkboxes"}</p>
					<p class={"mb-0"}>{"lista rozwijana: dropdown"}</p>
					<p class={"mb-0"}>{"input text: input"}</p>
					<p class={"mb-0"}>{"pole opisowe: textarea"}</p>
					<p>{"scale: skala w poziomie"}</p>


					<p><h2
						class={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}
					>{"{$rotateAnswers$}"}</h2>{" Rotowanie odpowiedzi"}</p>
					<p><h2
						className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}
					>{"{$rotateRows$}"}</h2>{" Rotowanie wierszy"}</p>
					<p><h2
						className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}
					>{"{$rotateColumns$}"}</h2>{" Rotowanie kolumn"}</p>
					<p><h2
						className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}
					>{"{$ins$}"}</h2>{" Czy jest włączona instrukcja (desktop). Zmiana domyślnej treści - po dwukropku"}
					</p>
					<p><h2
						className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}
					>{"{$insMob$}"}</h2>{" Czy jest włączona instrukcja (mobile). Zmiana domyślnej treści - po dwukropku"}
					</p>
					<p><h2
						className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}
					>{"{$val$}"}</h2>{" Zmiana treści walidacji (desktop). Uzupełnij treść walidacji po znaku :"}</p>
					<p><h2
						className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}
					>{"{$valMob$}"}</h2>{" Zmiana treści walidacji (mobile). Uzupełnij treść walidacji po znaku :"}</p>
					<p><h2
						className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}
					>{"{$disabled$}"}</h2>{" Ukrywanie pytania"}</p>
					<p><h2
						className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}
					>{"{$supplementMax$}"}</h2>{" Maksymalna liczba odpowiedzi po znaku :"}</p>
					<p><h2
						className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}
					>{"{$cafeteryMax$}"}</h2>{" Maksymalna liczba wybranych odpowiedzi po znaku :"}</p>
					<p><h2
						className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}
					>{"{$columns$}"}</h2>{" W ilu kolumnach się pojawi lista odpowiedzi. Sprecyzuj po znaku :"}</p>
					<p><h2
						className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}
					>{"{$min$}"}</h2>{" Minimalna wartość wpisywana/zaznaczana (numeryczne/suwak). Sprecyzuj po znaku :"}
					</p>
					<p><h2
						className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}
					>{"{$max$}"}</h2>{" Maksymalna wartość (numeryczne/suwak). Sprecyzuj po znaku :"}</p>
					<p><h2
						className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}
					>{"{$dec$}"}</h2>{" Liczba miejsc po przecinku (numeryczne/suwak). Sprecyzuj po znaku :"}</p>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{$def$}"}</h2>{" Domyślna wartość (suwak/numeryczne). Sprecyzuj po znaku :"}</p>


					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{$reqColumns$}"}</h2>{" Wymagaj zaznaczenia w każdej kolumnie"}</p>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{$reverseColumns$}"}</h2>{" Odwróć kolumny"}</p>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{$transposed$}"}</h2>{" Transponowanie matrycy"}</p>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{$reverseAnswers$}"}</h2>{" Odwróć odpowiedzi"}</p>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{$answerOpenAlwaysShow$}"}</h2>{" Zawsze pokazuj odpowiedzi wpisywane"}</p>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{$answersMax$}"}</h2>{" Maksymalna liczba odpowiedzi sprecyzuj po znaku :"}</p>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{$answersCount$}"}</h2>{" Maksymalna liczba punktów zaznaczonych na mapie sprecyzuj po znaku :"}</p>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{$answersStart$}"}</h2>{" Liczba pól w pytaniu otwartym wieloodpowiedziowym po wyświetleniu pytania. Sprecyzuj po znaku :"}</p>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{$answersMin$}"}</h2>{" Minimalna wartość odpowiedzi. Sprecyzuj po znaku :"}</p>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{$answersAddButtonCount$}"}</h2>{" (tylko pytanie wielokrotnego wyboru) Po ilu odpowiedziach ma się pojawić przycisk do pokazania większej liczby odpowiedzi sprecyzuj po znaku :"}"</p>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{$requiredCount$}"}</h2>{" (tylko pytanie rangowane) Liczba odpowiedzi wymaganych do przeniesienia. Sprecyzuj po znaku :"}</p>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{$requiredAll$}"}</h2>{" (tylko pytanie rangowane) Wymagaj wszystkich odpowiedzi do przeniesienia"}</p>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{$places$}"}</h2>{" (tylko pytanie rangowane) Liczba pozycji rangowanych. Sprecyzuj po znaku :"}</p>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{$scale$}"}</h2>{" Liczba punktów na skali. Sprecyzuj po znaku :"}</p>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{$randRows$}"}</h2>{" Rotuj wiersze"}</p>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{$randColumns$}"}</h2>{" Rotuj kolumny"}</p>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{$columnsMin$}"}</h2>{" Minimalna liczba kolumn. Sprecyzuj po znaku :"}</p>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{$columnsMax$}"}</h2>{" Maksymalna liczba kolumn. Sprecyzuj po znaku :"}</p>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{$rowsMin$}"}</h2>{" Minimalna liczba wierszy. Sprecyzuj po znaku :"}</p>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{$rowsMax$}"}</h2>{" Maksymalna liczba wierszy. Sprecyzuj po znaku :"}</p>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{$videoUrl$}"}</h2>{" URL do filmu wideo"}</p>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{%answers%}"}</h2>{" (wymagane w pytaniu: matryca z listami rozwijanymi). Rozpoczyna dodawanie nowych odpowiedzi"}</p>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{%rows%}"}</h2>{" Rozpoczyna dodawanie nowych wierszy"}</p>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{%columns%}"}</h2>{" Rozpoczyna dodawanie nowych kolumn"}</p>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{$blocking$}"}</h2>{" Czy znacznk jest odpowiedzią blokującą"}</p>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{$open$}"}</h2>{" Czy znacznk jest otwarty (możliwość wpisania dowolnej odpowiedzi)"}</p>
				</div>
				<div class={"col-12 col-lg-4"}>
					<h2>{"Ustawienia odpowiedzi/wierszy/kolumn:"}</h2>

					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{$randThis$}"}</h2>{" Czy element jest losowany w pytaniu"}</p>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{$rotateThis$}"}</h2>{" Czy element jest rotowany w pytaniu"}</p>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{$randPriority$}"}</h2>{" Jaka jest wartość wylosowania tego elementu ustaw po znaku :"}</p>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{$open$}"}</h2>{" Odpowiedź wpisywana"}</p>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{$blocking$}"}</h2>{" Odpowiedź blokująca"}</p>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{$rotateExcluded$}"}</h2>{" Nie rotuj odpowiedzi/wiersza/kolumny"}</p>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{$min$}"}</h2>{" Minimalna liczba zaznaczeń odpowiedzi. Sprecyzuj po znaku :"}</p>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{$max$}"}</h2>{" Maksymalna liczba zaznaczeń odpowiedzi. Sprecyzuj po znaku :"}</p>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{$disabled$}"}</h2>{" Ukryj odpowiedź"}</p>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{$openNotRequired$}"}</h2>{" Nie wymuszaj uzupełniania odpowiedzi wpisywanej"}</p>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{$selectedCurrent$}"}</h2>{" Liczba wybranych odpowiedzi sprecyzuj po znaku :"}</p>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{$selectedMax$}"}</h2>{" Liczba maksymalnych odpowiedzi sprecyzuj po znaku :"}</p>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{$excludeRequired$}"}</h2>{" Nie wymagaj uzupełnienia tego elementu"}</p>

					<h2>{"Ustawienia dodatkowych elementów pytań:"}</h2>

					<h2>{"Punkty skali"}</h2>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{%scalePoints%}"}</h2>{" Dodaje punkty opisowe na skali"}</p>
					<p>{"Przykład:{% scalePoints %}"}</p>
					<p>{"1- zdecydowanie się nie zgadzam"}</p>
					<p>{"2- zdecydowanie się zgadzam"}</p>

					<h2>{"Odpowiedzi wykluczające:"}</h2>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{%excludings%}"}</h2>{" Rozpoczyna dodawanie nowych odpowiedzi wykluczających"}</p>

					<h2>{"Koszyki (pytanie koszykowe)"}</h2>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{%baskets%}"}</h2>{" Rozpoczyna dodawanie nowych koszyków"}</p>

					<h2>{"CSSy i JS"}</h2>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{$css$}"}</h2>{" Umieść kod css po znaczniku : który ma się pojawić w pytaniu"}</p>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{%js%}"}</h2>{" Rozpoczyna dodawanie nowych skryptów  JS"}</p>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{$action$}"}</h2>{" Jaki jest to typ akcji JS (componentDidMount, onAnswerSelected)"}</p>


					<h2>{"INNE ELEMENTY ANKIETY"}</h2>
					<h2>Treść zaproszenia</h2>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{$invitation$}"}</h2>{" MOJA treść zaproszenia"}</p>

					<h2>{"FILTRY"}</h2>
					<p><h2 className={"mb-0"} style={{fontSize: "12px", display: "inline-block"}}>{"{%filters%}"}</h2>{" Rozpoczyna dodawanie nowych filtrów"}</p>
					<p>{"Filtry dodajemy według poprzedniej instrukcji tworzenia filtrów. Każdy filtr umieszczamy w nowym wierszu."}</p>
					<p>{"Syntax obejmuje wszystkie rodzaje filtrów, w tym seryjne i iteracyjne"}</p>

					<p class={"mb-0"}>{"Przykład:"}</p>
					<p class={"mb-0"}>{"samochody nie widział reklamy -> ukryj;^Q93=2;Q!=95"}</p>
					<p class={"mb-0"}>{"samochod nie ma -> ukryj rocznik;^Q96E=1;Q!=97"}</p>
					<p class={"mb-0"}>{"{S}ad seria znajomosc->zakup;Q27=1,2,3,4,5,6,7,8,9;Q29"}</p>
					<p class={"mb-0"}>{"{S}al seria znajomosc -> zakup;{1}Q82=1,2,3,4,5,6,7,8,9,10,11,12,13;Q84"}</p>
					<p class={"mb-0"}>{"{S}aa seria znajomosc -> zakup;Q75=1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17;Q77"}</p>
					<p class={"mb-0"}>{"{S}bn seria znajomosc -> konto;Q115=1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18;Q116"}</p>
				</div>
			</div>
		)
	}
}

export default Instruction;