import React, {useEffect} from 'react';
import classnames from "classnames";
import {Tooltip} from "reactstrap";
import {connect} from "react-redux";
import {updateQuestion} from "../../../../scenes/Survey/Structure/data/questions/actions";
import api from "../../../../api";

const ForceCafeteryCenterTextAlign = props => {
	const {question, cafeteryType} = props;
	const getInitialState = () => {
		if (question.forceCafeteryStyles && question.forceCafeteryStyles[cafeteryType] && question.forceCafeteryStyles[cafeteryType].textAlign) {
			return true;
		}

		return false;
	}

	const [isActive, setIsActive] = React.useState(getInitialState());
	const [tooltip, setTooltip] = React.useState(false);

	const onClick = async () => {
		let forceCafeteryStyles = question.forceCafeteryStyles;
		if (!isActive) {
			forceCafeteryStyles = question.forceCafeteryStyles;
			forceCafeteryStyles[cafeteryType] = {
				textAlign: "center"
			}
		} else {
			if (forceCafeteryStyles[cafeteryType]) {
				delete forceCafeteryStyles[cafeteryType].textAlign;
			}
		}

		const response = await api.question.patch.forceCafeteryStyles({
			id: question.id,
			data: forceCafeteryStyles
		})
		setIsActive(!isActive);
	}

	return (
		<span id={'forceCafetery-' + cafeteryType + '-' + question.id} onClick={onClick} className="d-none d-lg-inline">
          <i
			  className={classnames({
				  'fas fa-align-center pointer': true,
				  'active': isActive
			  })}
		  />
		<Tooltip
			delay={0}
			placement="bottom"
			isOpen={tooltip}
			target={'forceCafetery-' + cafeteryType + '-' + question.id}
			toggle={() => {setTooltip(!tooltip)}}
		>Wycentruj wszystkie elementy kafeterii</Tooltip>
	  </span>
	);
};

function mapStateToProps(state) {
}

export default connect(mapStateToProps, {updateQuestion})(ForceCafeteryCenterTextAlign);
