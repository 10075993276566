import React from 'react';
import PropTypes from 'prop-types';
import "./survey.scss";
import SurveysQuestionsListIcon from "../../scenes/Surveys/components/Icons/SurveysQuestionsListIcon";
import appRoutes from "../../appRoutes";
import {Link} from "react-router-dom";
import moment from "moment";
import SurveyStatus from "./SurveysCategorySurvey/SurveyStatus";
import SurveyResponsesCount from "./SurveysCategorySurvey/SurveyResponsesCount";
import ProgressBar from "../../components/ProgressBar";
import SurveysActions from "../../scenes/Surveys/components/SurveysActions";
import SurveysBaseStatusIcon from "../../scenes/Surveys/components/Icons/SurveysBaseStatusIcon";
import SurveysFavoriteIcon from "../../scenes/Surveys/components/Icons/SurveysFavoriteIcon";
import SurveyFavorite from "./SurveysCategorySurvey/SurveyFavorited";
import {connect} from "react-redux";
import SurveyName from "./SurveysCategorySurvey/SurveyName";
import SurveysTopSurveysStatusIcon from "../../scenes/Surveys/components/Icons/SurveysTopSurveysStatusIcon";
import {isMobile} from "react-device-detect";
import classnames from 'classnames';
import SurveySortable from "./SurveysCategorySurvey/SurveySortable";
import Tooltip from "../../components/Tooltip";
import SurveyScoringIcon from "../../scenes/Surveys/components/SurveyScoringIcon";
import Checkbox from "../../components/Checkbox";

const SurveysCategorySurvey = props => {
  const {survey, plan} = props;
  const [checked, setChecked] = React.useState(false);

  return (
    <div
      className="rt-tr-group survey visibilitiable-1"
    >
      <div className="rt-tr flex-column flex-md-row">
        <div className="rt-td td-first">
          <div className="d-flex align-items-center">
            {props.showCheckboxes && <Checkbox id={"bulkAction"} checked={checked} onClick={() => {
              setChecked(!checked)
              props.onChecked(survey.id)
            }} tooltip={false} />}
            {!props.sortableDisabled && <SurveySortable
              survey={survey}
              onChange={props.onChange}
            />}

            <SurveyFavorite survey={survey} />
            <SurveysBaseStatusIcon onReReported={() => props.onChange()} className="d-inline-block mr-0 mr-md-2" survey={survey} type="desktop"/>
            <SurveysQuestionsListIcon survey={survey} />
            {props.isUserRespondent && <SurveysTopSurveysStatusIcon onReReported={() => () => props.onChange()} className="d-inline-block ml-0 ml-md-2" survey={survey} type="desktop"/>}

          </div>
        </div>
        <div className="rt-td td-name text-center">
          <SurveyName
            survey={survey}
          />
        </div>
        <div className="rt-td td-end text-center">
          {survey.endAt}
        </div>
        <div className="rt-td td-status text-center">
          <SurveyStatus survey={survey} />
        </div>
        <div className="rt-td td-responsesCount text-center">

          <div className="d-flex flex-row flex-nowrap justify-content-center">
            <SurveyResponsesCount survey={survey}/>
            {survey.hasCollectorPanelSendEmail > 0 && <div>
              <Link to={appRoutes.survey.collectorsPanelEmailStats(survey.id)}><i id={`s-panelSendEmail-${survey.id}`} className="fas fa-envelope ml-2"/></Link>
              <Tooltip id={`s-panelSendEmail-${survey.id}`} msg={'Wysyłka zaproszeń e-mail do kolektora Panel'} />
            </div>}
            {props.collectorPanelRealizationAutomate && <div className="d-md-none">
              <Link to={appRoutes.survey.collectors.listPanelRealizationAutomate(survey.id)}><i id={`s-panelRealizationAutomate-${survey.id}`} className={classnames({
                'fas fa-dog-leashed ml-2': true,
                'text-success': survey.hasCollectorPanelRealizationAutomate > 0,
              })}/></Link>
              <Tooltip id={`s-panelRealizationAutomate-${survey.id}`} msg={'Automatyczna realizacja'} />
            </div>}
            {survey.hasQuotaNew > 0 && <div>
              <i id={`s-quotaNew-${survey.id}`} className="fas fa-circle-q ml-2"/>
              <Tooltip id={`s-quotaNew-${survey.id}`} msg={'Kwoty'} />
            </div>}

          </div>
        </div>
        <div className="rt-td td-progressbar">
          {/*<span>{hasQuotaNew} jest/nie ma kwoty</span>*/}
          <div className="d-flex">
            <ProgressBar current={parseInt(survey.endResponsesCount)} max={parseInt(survey.responsesMax)} id={survey.id}/>
            {survey.latestScore > 0 && <SurveyScoringIcon scoring={survey.latestScore} allowModal={true} survey={survey} />}
            {props.collectorPanelRealizationAutomate && <div className="d-none d-md-block">
              <Link to={appRoutes.survey.collectors.listPanelRealizationAutomate(survey.id)}><i id={`s-panelRealizationAutomate-${survey.id}`} className={classnames({
                'fas fa-dog-leashed ml-2': true,
                'text-success': survey.hasCollectorPanelRealizationAutomate > 0,
              })}/></Link>
              <Tooltip id={`s-panelRealizationAutomate-${survey.id}`} msg={'Automatyczna realizacja'} />
            </div>}
            {plan.survey.isPanelMobilePushAllowed === true && survey.hasCollectorPanelPush === 1 && <React.Fragment>
              <i id={`s-panelPushMobile-${survey.id}`} className={classnames({
                'fas fa-bell ml-1': true,
              })}/>
              <Tooltip id={`s-panelPushMobile-${survey.id}`} msg={'Zawiera wysyłki push'} />
            </React.Fragment>}
          </div>
        </div>
        {props.isUserRespondent && <div className="rt-td td-progressbar">
          <div className="d-flex justify-content-center">
            <span>{survey.rateAvg} ({survey.rateCount})</span>
          </div>
        </div>}
        <div className="rt-td td-actions">
          <div className="d-flex">
            <SurveysActions
              survey={survey}
              handleChange={props.onChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

SurveysCategorySurvey.propTypes = {
  survey: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    endAt: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  sortableDisabled: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    isUserRespondent: state.user.type === 'Respondent',
    plan: state.user.userPlan.plan,
    collectorPanelRealizationAutomate: 'userPlan' in state.user ? state.user.userPlan.plan.survey.collectorPanelRealizationAutomate : false,
    swPanelDefaultAccepted: 'userPlan' in state.user ? state.user.userPlan.plan.survey.swPanelDefaultAccepted : false,
  }
}

export default connect(mapStateToProps)(SurveysCategorySurvey);
